import { useLayoutEffect, useState } from 'react'

import { Box, Message, Pill, RadioboxGroup, Text } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { SuccessorTask } from './task-action'

type TaskConditionalProgressionContentProps = {
  successorTasks: SuccessorTask[]
  setSuccessorTasks: (tasks: SuccessorTask[]) => void
  hasCustomFields: boolean
}

export const TaskConditionalProgressionContent = ({
  successorTasks,
  setSuccessorTasks,
  hasCustomFields
}: TaskConditionalProgressionContentProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'taskActionModal.conditionalProgression' })
  const [abandonState, setAbandonState] = useState({ allSelected: false, someSelected: false })

  const handleOptionChange = (value: string, changedTask: SuccessorTask) => {
    const updatedTasks = successorTasks.map((task: SuccessorTask) =>
      task.id === changedTask.id ? { ...task, selected: value === 'true' } : task
    )
    setSuccessorTasks(updatedTasks)
  }

  useLayoutEffect(() => {
    const abandonedCount = successorTasks.reduce((count, task) => count + (!task.selected ? 1 : 0), 0)
    const totalTasks = successorTasks.length
    setAbandonState({
      allSelected: abandonedCount === totalTasks,
      someSelected: abandonedCount > 0 && abandonedCount < totalTasks
    })
  }, [successorTasks])

  return (
    <Box gap="16px" margin={{ top: hasCustomFields ? '16px' : '0px' }}>
      {successorTasks.length === 0 ? (
        <Text>{t('noSuccessorsText')}</Text>
      ) : (
        <>
          <Text>{t('conditionalProgressionText')}</Text>
          <Text>{t('successorsSelect')}</Text>
          {successorTasks.map(task => (
            <Box key={task.id} direction="row" justify="between" align="center">
              <Box css="max-width: 60%;" direction="row">
                <Text color={!task.selected ? 'text-light' : 'text'}>
                  {!task.selected && <Pill label={t('abandonedPill')} />} {task.name}
                </Text>
              </Box>
              <Box
                css={`
                  label {
                    padding: 0px;
                  }
                `}
              >
                <RadioboxGroup
                  name={`${task.id}`}
                  direction="row"
                  plain
                  a11yTitle={t('radioBoxLabel', { taskName: task.name })}
                  options={[
                    { value: 'true', label: t('continueLabel') },
                    { value: 'false', label: t('abandonLabel') }
                  ]}
                  defaultValue={task.selected ? 'true' : 'false'}
                  onChange={e => handleOptionChange(e.target.value, task)}
                />
              </Box>
            </Box>
          ))}
          <Text>{t('abandonedExplanation')}</Text>
          {abandonState.allSelected && <Message type="warning" message={t('abandonAllWarning')} />}
          {abandonState.someSelected && <Message type="warning" message={t('abandonSomeWarning')} />}
        </>
      )}
    </Box>
  )
}
