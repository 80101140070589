import { useState } from 'react'
import styled from 'styled-components'

import { Button, Icon, IconButton, IconName, Text, themeColor } from '@cutover/react-ui'
import { useComponentProps } from '../apps-state'
import { AppComponentNodeProps } from '../apps-types'

type ButtonKind = 'primary' | 'secondary' | 'tertiary' | 'menu'
type ButtonSize = 'small' | 'medium' | 'large'

type ButtonNodeProps = AppComponentNodeProps & {
  value?: string
  kind?: ButtonKind
  size?: ButtonSize
  icon?: IconName
  expanded?: boolean
  fullWidth?: boolean
  loading?: boolean
  active?: boolean
  counter?: number
}

export const ButtonNode = ({ appId, resourceId, id, ...props }: ButtonNodeProps) => {
  const [loading, setLoading] = useState(false)
  const {
    value: label,
    kind,
    icon,
    expanded = false,
    size = 'large',
    fullWidth,
    active = false,
    counter
  } = useComponentProps(appId, resourceId, id, props) as ButtonNodeProps
  const iconButton = icon && !expanded
  const badgeProps = iconButton && counter ? { type: 'dark', label: counter } : null
  const buttonProps = {
    tipPlacement: 'bottom',
    icon,
    label,
    size,
    disableTooltip: !label,
    full: fullWidth,
    loading: !(icon && !expanded) && loading && kind !== 'menu',
    onClick: () => setLoading(true),
    active,
    badge: badgeProps
  }
  const ButtonComponent: any = iconButton ? IconButton : Button
  const ButtonComponentTertiary: any = iconButton ? IconButtonStyled : Button

  switch (kind) {
    case 'primary':
      return <ButtonComponent primary {...buttonProps} />
    case 'secondary':
      return <ButtonComponent secondary {...buttonProps} />
    case 'tertiary':
      return <ButtonComponentTertiary tertiary {...buttonProps} />
    case 'menu':
      return (
        <MenuButton
          tertiary
          active={active}
          label={
            <>
              {label && <Text>{label}</Text>}
              {icon && <Icon size={size} icon={icon as IconName} />}
            </>
          }
        />
      )
    default:
      return <ButtonComponentTertiary tertiary {...buttonProps} />
  }
}

const IconButtonStyled = styled(IconButton)`
  background: ${props => (props.active ? themeColor('bg-2') : 'inherit')};

  &:hover {
    background: ${props => (props.active ? themeColor('bg-3') : themeColor('bg-2'))};
  }
`

const MenuButton = styled(Button)`
  background: ${props => (props.active ? themeColor('bg-3') : themeColor('bg-1'))};
  color: ${props => (props.active ? themeColor('text') : themeColor('text-light'))};
  padding: 15px;
  font-size: 15px;

  &:hover {
    color: ${themeColor('text')};
  }
`
