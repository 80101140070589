import { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { format } from 'date-fns'
import { useMeasure } from 'react-use'
import { mergeRefs } from 'use-callback-ref'
import { ReactRef } from 'use-callback-ref/dist/es5/types'

import {
  Avatar,
  Box,
  Button,
  IconButton,
  Pill,
  SpinnerIcon,
  Text,
  TextArea,
  themeColor,
  Tooltip,
  useNotify
} from '@cutover/react-ui'
import { FormEditPanel } from 'main/components/shared/form'
import {
  getRunbookComments,
  toggleCommentFeatured,
  useRunbookCommentCreate
} from 'main/services/queries/use-runbook-comments'
import {
  useRunbookCommentsPermission,
  useRunbookId,
  useRunbookVersionId,
  useTaskListInternalIdLookupState
} from 'main/recoil/runbook'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'
import {
  useProcessRunbookCommentCreateResponse,
  useProcessRunbookCommentFeaturedResponse,
  useProcessRunbookCommentsGetResponse
} from 'main/recoil/runbook/updaters/runbook-comments-operations'
import { useRunbookCommentsState } from 'main/recoil/runbook/models/runbook/runbook-comments'
import { useLanguage } from 'main/services/hooks'
import { FavoriteStar } from 'main/components/shared/favorite-star'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'

type CommentsFormProps = {
  onClose: () => void
  taskId?: number
  taskInternalId?: number
}

export const CommentsForm = ({ onClose, taskId, taskInternalId }: CommentsFormProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'commentsPanel' })
  const [isLoading, setIsLoading] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const [commentsLength, setCommentsLength] = useState(0)
  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()
  const processRunbookCommentsGetResponse = useProcessRunbookCommentsGetResponse()
  const tasksByInternalIdLookup = useTaskListInternalIdLookupState()
  const canCreateComment = useRunbookCommentsPermission({ attribute: 'create' })
  const { openRightPanel } = useSetActiveRightPanelState()
  const processRunbookCommentCreateResponse = useProcessRunbookCommentCreateResponse()
  const comments = useRunbookCommentsState(taskInternalId) ?? []
  const createComment = useRunbookCommentCreate(runbookId, runbookVersionId, taskId)
  const commentsContainerRef = useRef<HTMLDivElement>(null)
  const [heightRef, { height: commentsContainerHeight }] = useMeasure()
  const [isInitialized, setIsInitialized] = useState(false)
  const notify = useNotify()

  useEffect(() => {
    setIsLoading(comments.length === 0)
    const initData = async () => {
      const response = await getRunbookComments({ runbookId, runbookVersionId })
      processRunbookCommentsGetResponse(response)
      setIsLoading(false)
    }

    initData()
  }, [])

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (newComment.trim().length > 0 && canCreateComment && !isSubmitting) {
        setSubmitting(true)
        try {
          const response = await createComment.mutateAsync(newComment.trim())
          if (response) {
            processRunbookCommentCreateResponse(response)
            setNewComment('')
            setSubmitting(false)
          }
        } catch (e) {
          setSubmitting(false)
          setNewComment('')
          notify.error(t('createError'))
        }
      }
    }
  }

  useLayoutEffect(() => {
    // only scroll if new comments are pushed to recoil state
    if (comments.length > commentsLength) {
      commentsContainerRef.current?.lastElementChild?.scrollIntoView({
        behavior: (commentsLength === 0 ? 'instant' : 'smooth') as ScrollBehavior
      })
    }
    setCommentsLength(comments.length)
  }, [comments])

  useLayoutEffect(() => {
    if (!isInitialized && commentsContainerHeight > 0) {
      commentsContainerRef.current?.lastElementChild?.scrollIntoView({ behavior: 'instant' as ScrollBehavior })
      setIsInitialized(true)
    }
  }, [commentsContainerHeight])

  return (
    <FormEditPanel
      title={t('title')}
      onClose={onClose}
      formElementWrapper={false}
      loading={isLoading}
      onBack={taskId ? () => openRightPanel({ type: 'task-edit', taskId }) : undefined}
      headerItems={
        taskId
          ? [
              <Pill
                color="text-light"
                label={t('taskPillLabel', { taskInternalId })}
                suffix={
                  <IconButton
                    label={t('clearTask')}
                    disableTooltip
                    data-testid={'task-comments-pill-clear'}
                    icon="close"
                    size="small"
                    onClick={() => openRightPanel({ type: 'runbook-comments' })}
                  />
                }
              />
            ]
          : undefined
      }
      footer={
        canCreateComment && (
          <Box css="position: relative;">
            <TextArea
              plain
              placeholder={taskId ? t('addTaskComment') : t('addRunbookComment')}
              alwaysShowPlaceholder
              onKeyDown={handleKeyDown}
              value={newComment}
              onInput={(event: ChangeEvent<HTMLTextAreaElement>) => {
                setNewComment(event.target.value)
              }}
              css={`
                background: ${themeColor('bg-1')} !important;
                border-radius: 16px;
                padding: 9px 16px 0 16px !important;
                min-height: 38px !important;
                margin-bottom: 8px !important;
              `}
            />
            {newComment.length > 0 && (
              <Text
                size="11px"
                color="text-light"
                css={`
                  text-align: right;
                  position: absolute;
                  bottom: -8px;
                  right: 4px;
                `}
              >
                {t('commentInputHint')}
              </Text>
            )}
            {isSubmitting && (
              <SpinnerIcon
                color="text-light"
                css="position: absolute; top: 20px; right: 10px; opacity: 0.5; z-index: 1"
              />
            )}
          </Box>
        )
      }
    >
      <Box ref={mergeRefs([commentsContainerRef, heightRef as ReactRef<HTMLElement>])}>
        {comments.map(comment => {
          const { task_internal_id } = comment
          const taskId = task_internal_id ? tasksByInternalIdLookup[task_internal_id]?.id : undefined

          return <CommentItem key={comment.id} comment={comment} taskId={taskId} />
        })}
      </Box>
    </FormEditPanel>
  )
}

const CommentItem = ({ comment, taskId }: { comment: RunbookComment; taskId?: number }) => {
  const { id, content, created_at: created, featured, author, task } = comment
  const { name, first_name, last_name, id: authorId, color } = author

  const { t } = useLanguage('runbook', { keyPrefix: 'commentsPanel' })
  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()
  const canCreateComment = useRunbookCommentsPermission({ attribute: 'create' })
  const processRunbookCommentFeaturedResponse = useProcessRunbookCommentFeaturedResponse()
  const { openRightPanel } = useSetActiveRightPanelState()

  const toggleFavorite = async () => {
    if (canCreateComment) {
      const response = await toggleCommentFeatured({ runbookId, runbookVersionId, commentId: id })
      if (response) {
        processRunbookCommentFeaturedResponse(response)
      }
    }
  }

  return (
    <Box data-testid="runbook-comment-item">
      <Box direction="row">
        <Avatar subject={{ id: authorId, first_name, last_name, color }} size="small" />
        <Box flex={{ grow: 1 }} css="padding-left: 6px; width: 150px;">
          <Text truncate="tip">{name}</Text>
        </Box>
        <Box direction="row" flex={false} align="center" css="position: relative; top: -5px;">
          <Box css="position: relative; top: -2px;">
            <FavoriteStar isFavorite={featured} toggleFavorite={toggleFavorite} />
          </Box>
          <Text color="text-light" size="small" css="padding-left: 4px;">
            {format(created * 1000, 'd MMM HH:mm')}
          </Text>
        </Box>
      </Box>
      <Text
        as="pre"
        css={`
          font-family: inherit;
          color: ${themeColor('text-light')};
          font-size: 13px;
          margin-top: -10px;
          padding-left: 30px;
        `}
      >
        {taskId && (
          <Tooltip content={task?.name} placement="top">
            <Button
              color="primary"
              size="small"
              onClick={() => openRightPanel({ type: 'task-edit', taskId })}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  openRightPanel({ type: 'task-edit', taskId })
                }
              }}
              plain
            >
              {t('taskLink', { taskInternalId: task?.internal_id })}
            </Button>
          </Tooltip>
        )}
        {content}
      </Text>
    </Box>
  )
}
