import { FieldOptionSummaryData } from '../../types'
import { CustomField, TaskListTask } from 'main/services/queries/types'

// COMMENTS FOR CODE REVIEW - To be edited / removed
// This builds a summaryData object
//       [key: string | number]: {  (key is either 'not-entered', 'pending' etc OR the field option id)
//                                name: string
//                                color: string
//                                count: number
//                               }
//  we loop through the tasks to check if they are associated with the custom field we are rendering
//  OR if they are not entered yet (there is no field value asociated with that task)
//  Then update the count accordingly
//  SummaryData is returned as an array for displaying in jsx

export const getCustomFieldSummaryDataArray = (
  tasks: TaskListTask[],
  cf: CustomField | undefined
): FieldOptionSummaryData[] => {
  const constraints = cf && cf.constraint && JSON.parse(cf.constraint)
  const tasksUnderConstraint: TaskListTask[] =
    constraints &&
    tasks.filter(task => constraints.task_type_id && constraints.task_type_id.includes(task.task_type_id))
  const tasksForDisplay =
    tasksUnderConstraint && tasksUnderConstraint.length
      ? tasksUnderConstraint
      : tasksUnderConstraint && tasksUnderConstraint.length === 0
      ? []
      : tasks

  const summaryData: { [key: string]: { name: string; color: string; count: number } } = {}

  if (
    cf &&
    (cf.apply_to.slug === 'task_start' || cf.apply_to.slug === 'task_end' || cf.apply_to.slug === 'task_edit') &&
    !cf.archived &&
    cf.field_options.length > 0
  ) {
    cf.field_options.forEach(option => {
      option.id &&
        (summaryData[option.id] = {
          name: option.name,
          color: option.color || '#CCCCCC',
          count: 0
        })
    })
    summaryData['not-entered'] = {
      name: 'Not entered',
      color: '#CCCCCC',
      count: 0
    }
    summaryData['not-entered-skipped'] = {
      name: 'Not entered (skipped)',
      color: '#999999',
      count: 0
    }
    summaryData['not-entered-abandoned'] = {
      name: 'Not entered (abandoned)',
      color: '#777777',
      count: 0
    }
    if (cf.apply_to.slug === 'task_start' || cf.apply_to.slug === 'task_end') {
      // custom fields assigned to task start/end can be 'pending'
      summaryData['pending'] = {
        name: 'Pending',
        color: '#DFDFDF',
        count: 0
      }
      // can't get mixed response if assigned to task edit
      summaryData['mixed'] = {
        name: 'Mixed response',
        color: '#555555',
        count: 0
      }
    }
    summaryData['total'] = {
      name: 'Total',
      color: '#555555',
      count: tasksForDisplay.length
    }

    const notEnteredTasks: number[] = []
    let mixed: Boolean = false

    tasksForDisplay.forEach(task => {
      // array to hold current fv's options chosen (to determine if it's a mixed response)
      let taskFieldValueOptionIds: number[] = []
      if (task.field_values.length === 0) {
        if (cf.apply_to.slug === 'task_start') {
          if (task.stage === 'default' || task.stage === 'startable') {
            summaryData['pending'].count++
          } else if (task.completion_type === 'complete_abandoned') {
            summaryData['not-entered-abandoned'].count++
          } else if (task.completion_type === 'complete_skipped') {
            summaryData['not-entered-skipped'].count++
          } else {
            if (
              (task.stage === 'complete' || task.stage === 'in-progress') &&
              task.completion_type === 'complete_normal'
            ) {
              summaryData['not-entered'].count++
            }
          }
        } else if (cf.apply_to.slug === 'task_end') {
          if (task.stage === 'default' || task.stage === 'startable' || task.stage === 'in-progress') {
            summaryData['pending'].count++
          } else if (task.completion_type === 'complete_abandoned') {
            summaryData['not-entered-abandoned'].count++
          } else if (task.completion_type === 'complete_skipped') {
            summaryData['not-entered-skipped'].count++
          } else {
            if (task.stage === 'complete' && task.completion_type === 'complete_normal') {
              summaryData['not-entered'].count++
            }
          }
        } else if (cf.apply_to.slug === 'task_edit') {
          summaryData['not-entered'].count++
        }
      } else {
        task.field_values.forEach(fieldVal => {
          if (cf.id === fieldVal.custom_field_id) {
            // if the field value is associated with the custom field
            // if we have already found a field option, check that this field option is the same
            // if it is the same,  continue looping through the field values
            // if it isn't, this is a mixed response
            if (taskFieldValueOptionIds.length > 0) {
              if (!taskFieldValueOptionIds.includes(Number(fieldVal.field_option_id))) {
                summaryData['mixed'].count++
                taskFieldValueOptionIds = []
                mixed = true
              }
              // if we haven't found a field option, add this to the taskFieldValueOptions array to check for mixed response or render
            } else {
              taskFieldValueOptionIds.push(Number(fieldVal.field_option_id))
            }
          }
        })
        if (taskFieldValueOptionIds.length === 0 && !mixed) {
          if (task.stage === 'complete') {
            notEnteredTasks.push(task.id)
          } else if (task.stage === 'default' || task.stage === 'startable') {
            if (cf.apply_to.slug !== 'task_edit') {
              summaryData['pending'].count++
            } else {
              summaryData['not-entered'].count++
            }
          } else if (task.stage === 'in-progress') {
            if (cf.apply_to.slug === 'task_start' || cf.apply_to.slug === 'task_edit') {
              notEnteredTasks.push(task.id)
            } else {
              summaryData['pending'].count++
            }
          }
        } else {
          // once done looping through field values, if there is a chosen field option, update the count
          summaryData[taskFieldValueOptionIds[0]] && summaryData[taskFieldValueOptionIds[0]].count++
        }
      }
      mixed = false
    })

    const notEnteredTasksSet = Array.from(new Set(notEnteredTasks))

    if (notEnteredTasksSet.length > 0) {
      summaryData['not-entered'].count += notEnteredTasksSet.length
    }
  }

  if (summaryData['not-entered']?.count === 0) delete summaryData['not-entered']
  if (summaryData['not-entered-abandoned']?.count === 0) delete summaryData['not-entered-abandoned']
  if (summaryData['not-entered-skipped']?.count === 0) delete summaryData['not-entered-skipped']
  if (summaryData['pending'] && summaryData['pending'].count === 0) delete summaryData['pending']
  if (summaryData['mixed'] && summaryData['mixed'].count === 0) delete summaryData['mixed']

  return Object.values(summaryData)
}
