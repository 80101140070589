import { useRecoilState, useRecoilValue } from 'recoil'

import { componentPropsWithContextAndId, fieldValuesWithContext } from 'main/recoil/apps/selectors'
import { FieldValues } from './apps-types'

export const useComponentProps = (appId: string, resourceId: string, id: string | undefined, props: object) => {
  const resolvedId = id || ''
  const context = `${resourceId}-${appId}`

  const componentProps = {
    ...props,
    ...(useRecoilValue(componentPropsWithContextAndId({ context, id: resolvedId })) || {})
  }

  return componentProps
}

export const useComponentPropsAndState = (appId: string, resourceId: string, id: string | undefined, props: object) => {
  const resolvedId = id || ''
  const context = `${resourceId}-${appId}`
  const [state, setState] = useRecoilState(fieldValuesWithContext(context))
  const componentProps = {
    ...props,
    ...(useRecoilValue(componentPropsWithContextAndId({ context, id: resolvedId })) || {})
  }

  return { componentProps, state: state as FieldValues, setState }
}
