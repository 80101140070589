// @ts-nocheck
import { injectable, inject } from 'inversify'
import { Types } from 'Gateways/Service/Types'
import { IServiceGateway } from 'Gateways/Service/IServiceGateway'
import { IIncidentPM } from 'app/Repositories/IncidentRepository'
import { mapRawIncidentToPM } from 'Shared/Helpers/IncidentMapper'

@injectable()
export class RunbookIncidentRepository {
  @inject(Types.IServiceGateway)
  private serviceGateway: IServiceGateway

  private loadedRunbookId: number

  private incident: IIncidentPM

  public getIncidentForRunbook = async (runbookId: number) => {
    if (runbookId === this.loadedRunbookId) return this.incident

    const rawData = await this.serviceGateway.get(`runbooks/${runbookId}/incident`)

    if (rawData.success) {
      this.loadedRunbookId = runbookId
      this.incident = mapRawIncidentToPM(rawData.body.incident)
      return this.incident
    }
  }
}
