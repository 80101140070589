import { useMutation, useQueryClient } from 'react-query'

import { SamlRoleMapping } from './types'
import { ApiError } from '../api/http-gateway-adapter'
import { apiClient } from 'main/services/api'
import { QueryKeys } from 'main/services/queries/query-keys'

export type SamlRoleMappingUpdateType = {
  saml_configuration_id: number
  formData: FormData
}

export function useUploadRoleMappingsMutation() {
  const queryClient = useQueryClient()

  return useMutation<SamlRoleMapping, ApiError, SamlRoleMappingUpdateType>(
    [QueryKeys.RoleMappings],
    async (args: SamlRoleMappingUpdateType): Promise<any> => {
      const response = await apiClient.post<FormData, SamlRoleMapping>({
        url: `saml_configurations/${args.saml_configuration_id}/csv_import`,
        data: args.formData,
        convertCase: false,
        headers: { 'Content-Type': 'multipart/formdata' }
      })

      return response?.data
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.RoleMappings])
      }
    }
  )
}

export function useDownloadRoleMappingsCSVTemplate() {
  return useMutation<any, ApiError, any>([QueryKeys.RoleMappings], async (): Promise<any> => {
    const { data } = await apiClient.get<string>({
      url: 'saml_role_mappings/csv_import_template'
    })

    return data
  })
}

export function useDownloadRoleMappings() {
  return useMutation<any, ApiError, any>([QueryKeys.RoleMappings], async (samlId: number): Promise<any> => {
    const { data } = await apiClient.get<string>({
      url: `saml_role_mappings.csv?saml_configuration_id=${samlId}`
    })

    return data
  })
}
