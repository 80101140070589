import { useForm } from 'react-hook-form'

import { Accordion, Box, Loader, Message } from '@cutover/react-ui'
import { IntegrationItem } from './integration-item'
import { RunbookIntegration } from 'main/services/queries/types'

type IntegrationsListProps = {
  disabled: boolean
  runbookIntegrations: RunbookIntegration[]
  runbookId: number
  runbookVersionId: number
}

export const IntegrationsList = ({
  disabled,
  runbookIntegrations,
  runbookId,
  runbookVersionId
}: IntegrationsListProps) => {
  const integrationLinkForm = useForm<RunbookIntegration>({
    defaultValues: {
      id: null,
      checked: false,
      integrationActionItemId: null,
      linkableId: runbookVersionId,
      linkableType: 'RunbookVersion',
      integrationOrder: 0
    }
  })
  const { formState } = integrationLinkForm

  return (
    <>
      {!runbookIntegrations ? (
        <Loader />
      ) : (
        <Accordion>
          {formState?.errors?.checked?.message && (
            <Box margin={{ bottom: '16px' }}>
              <Message type="error" message={formState?.errors?.checked?.message} />
            </Box>
          )}

          {runbookIntegrations.map(runbookIntegration => (
            <IntegrationItem
              key={runbookIntegration.integrationActionItemId}
              runbookIntegration={runbookIntegration}
              runbookId={runbookId}
              runbookVersionId={runbookVersionId}
              integrationLinkForm={integrationLinkForm}
              disabled={disabled}
            />
          ))}
        </Accordion>
      )}
    </>
  )
}
