import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil'

import { CurrentUser } from 'main/services/queries/use-get-validate-token'
import { FrontendUserSettingType } from 'main/services/queries/types'

export const currentUserState = atom<CurrentUser | null>({
  key: 'current-user',
  default: null
})

export const currentUserIdState = selector({
  key: 'current-user:id',
  get: ({ get }) => {
    const user = get(currentUserState)
    return user?.id
  }
})

export const useCurrentUser = () => {
  return useRecoilValue(currentUserState) as CurrentUser // if using this hook can assume we're authenticated
}

export const useUpdateFrontendUserSettingState = () => {
  const updateSettings = useSetRecoilState(currentUserState)
  return ({ payload }: { payload: FrontendUserSettingType['data'] }) =>
    updateSettings(prevUser => {
      if (prevUser) {
        const newSettings = { ...prevUser?.frontend_user_setting, data: payload }
        return { ...prevUser, frontend_user_setting: { ...newSettings } }
      } else {
        return null
      }
    })
}

export const useCurrentUserId = () => {
  return useRecoilValue(currentUserIdState)
}
