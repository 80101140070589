export class Routes {
  public routes = [
    {
      routeId: 'users',
      routeDef: {
        path: '/app/access/users/list',
        permissionId: 'users'
      }
    },
    {
      routeId: 'roleTypes',
      routeDef: {
        path: '/app/access/role_types/list',
        permissionId: 'role_types'
      }
    },
    {
      routeId: 'settingsAccounts',
      routeDef: {
        path: '/app/settings/accounts'
      }
    },
    {
      routeId: 'runbookTypes',
      routeDef: {
        path: '/app/settings/runbooktypes'
      }
    },
    {
      routeId: 'taskTypes',
      routeDef: {
        path: '/app/settings/tasktypes'
      }
    },
    {
      routeId: 'customFields',
      routeDef: {
        path: '/app/settings/customfields'
      }
    },
    {
      routeId: 'customFieldGroups',
      routeDef: {
        path: '/app/settings/customfieldgroups'
      }
    },
    {
      routeId: 'accounts',
      routeDef: {
        path: '/app/settings/accounts'
      }
    },
    {
      routeId: 'incidentSeverities',
      routeDef: {
        path: '/app/settings/incidentseverities'
      }
    },
    {
      routeId: 'userVerify',
      routeDef: {
        path: '/user-verify'
      }
    },
    {
      routeId: 'accountDashboard',
      routeDef: {
        path: '/app/:accountId/dashboards/:dashboardId'
      }
    },
    {
      routeId: 'runbookUsers',
      routeDef: {
        path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/users'
      }
    },
    {
      routeId: 'runbookActivity',
      routeDef: {
        path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/activity'
      }
    },
    // Note: commenting out for now, but may we want to define runbook task list routes here
    // {
    //   routeId: 'runbookTaskList',
    //   routeDef: {
    //     path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/list'
    //   }
    // },
    {
      routeId: 'runbooksList',
      routeDef: {
        path: '/app/:accountId/runbooks/list'
      }
    },
    {
      routeId: 'runbooksTable',
      routeDef: {
        path: '/app/:accountId/runbooks/table'
      }
    },
    {
      routeId: 'runbooksDashboard',
      routeDef: {
        path: '/app/:accountId/runbooks/dashboard/:dashboardId'
      }
    },
    {
      routeId: 'runbooksTimeline',
      routeDef: {
        path: '/app/:accountId/runbooks/timeline'
      }
    },
    {
      routeId: 'runbookContent',
      routeDef: {
        path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/page/content/:contentId'
      }
    },
    {
      routeId: 'runbookTaskList',
      routeDef: {
        path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/list'
      }
    },
    {
      routeId: 'runbookDashboard',
      routeDef: {
        path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/dashboard/:dashboardId'
      }
    },
    {
      routeId: 'runbookMap',
      routeDef: {
        path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/map'
      }
    },
    {
      routeId: 'runbookTable',
      routeDef: {
        path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/tasks/table'
      }
    },
    {
      routeId: 'events',
      routeDef: {
        path: '/app/:accountId/events/timeline'
      }
    },
    {
      routeId: 'runbook',
      routeDef: {
        path: '/app/:accountId/runbooks/:runbookId/:runbookVersionId/*'
      }
    },
    // Necessary to have an empty fallback. If Navigo does not match a new route and detect a change, it will
    // continue to render the previous content.
    {
      routeId: 'empty',
      routeDef: {
        path: ''
      }
    }
  ]
}
