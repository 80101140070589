// @ts-nocheck
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { withPresenter } from 'app/Config/withPresenter'
import { IncidentSeverityAddPresenter } from './IncidentSeverityAddPresenter'
import { IncidentSeverityFormComponent } from '../IncidentSeverityForm/IncidentSeverityFormComponent'
import { IncidentSeverityFormPresenter } from '../IncidentSeverityForm/IncidentSeverityFormPresenter'

const Wrapper = styled.div`
  overflow-y: auto;
`

interface IIncidentSeverityAddComponentProps {
  postSuccessCallback: () => void
  presenter: IncidentSeverityAddPresenter
  addFormPresenter: IncidentSeverityFormPresenter
}

const IncidentSeverityAddSection = observer<IIncidentSeverityAddComponentProps>(
  ({ presenter, postSuccessCallback, addFormPresenter }) => {
    const { saveFunction, formSubmitted } = presenter

    presenter.withPostSuccessCall(postSuccessCallback)
    presenter.withIncidentSeverityFormPresenter(addFormPresenter)

    useEffect(() => {
      saveFunction()
    }, [formSubmitted])

    return (
      <Wrapper>
        <IncidentSeverityFormComponent presenter={addFormPresenter} formType="add" />
      </Wrapper>
    )
  }
)

export default withPresenter(IncidentSeverityAddPresenter)(IncidentSeverityAddSection)
