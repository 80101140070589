import { flatMap } from 'lodash'
import { useQuery } from 'react-query'

import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'

export type Team = {
  id: number
  name: string
  users: Array<{
    id: number
    name: string
    avatar: string
  }>
}

export type TeamQuery = {
  id: number
  isEnabled?: boolean
}

type TeamApi = {
  id: number
  name: string
  avatar: string
  usersCount: number
  highlight: boolean
  color: string
  roleTypes: TeamRoleTypeApi[]
}

type TeamRoleTypeApi = {
  id: number
  name: string
  description?: string
  users: TeamRoleTypeUserApi[]
}

type TeamRoleTypeUserApi = {
  id: number
  name: string
  handle: string
  avatar: string
  color: string
  archived: boolean
  online: boolean | null
}

export function useTeamQuery({ id, isEnabled = false }: TeamQuery) {
  return useQuery<Team, ApiError>(
    ['people-panel', 'team', id],
    async () => {
      const { data } = await apiClient.get<TeamApi>({
        url: `teams/${id}?order="first_name"`,
        responseProperty: 'team',
        convertCase: true
      })

      return {
        id: data.id,
        name: data.name,
        users: flatMap(data.roleTypes, rt => rt.users)
          .map(u => ({
            id: u.id,
            name: u.name,
            avatar: u.avatar,
            color: u.color,
            online: u.online
          }))
          .sort((user1, user2) => user1.name.localeCompare(user2.name))
      }
    },
    {
      enabled: isEnabled
    }
  )
}
