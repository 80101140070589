// @ts-nocheck
import { observer } from 'mobx-react-lite'
import { EditPanelFormWrapper } from 'Components/Templates'
import { IncidentSeverityEditPresenter } from './IncidentSeverityEditPresenter'
import { IncidentSeverityFormPresenter } from '../IncidentSeverityForm/IncidentSeverityFormPresenter'
import { IncidentSeverityFormComponent } from '../IncidentSeverityForm/IncidentSeverityFormComponent'

interface IIncidentSeveritiesEditProps {
  presenter: IncidentSeverityEditPresenter
  editFormPresenter: IncidentSeverityFormPresenter
}

export const IncidentSeveritiesEditSection = observer<IIncidentSeveritiesEditProps>(
  ({ presenter, editFormPresenter }) => {
    const { title, mode, onClose, reset, save, confirmModalPresenter, isSaving } = presenter

    presenter.withIncidentSeverityFormPresenter(editFormPresenter)

    return (
      <EditPanelFormWrapper
        closeCallback={onClose}
        heading={title}
        mode={mode}
        resetCallback={reset}
        saveCallback={save}
        confirmModalPresenter={confirmModalPresenter}
        editPanelSaving={isSaving}
      >
        <IncidentSeverityFormComponent presenter={editFormPresenter} formType="edit" />
      </EditPanelFormWrapper>
    )
  }
)
