import { unescape } from 'lodash'
import { useInfiniteQuery, UseInfiniteQueryOptions, useMutation } from 'react-query'

import { Nullish } from '@cutover/utility-types'
import { Role } from './use-runbook-user'
import { getUserStatus } from './use-users-query'
import { queryClient } from 'main/query-client'
import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { UserStatus } from 'main/services/queries/types'
import { useSetPermissions } from 'main/services/queries/use-permissions'

export type RunbookUser = {
  id: number
  name: string
  color: string
  role: Role | string
  status: UserStatus
  online: boolean | null
  hasTeam: boolean
  streamIds?: number[]
}

export type RunbookUsersQuery = {
  runbookId: number
  runbookVersionId: number
  offset?: number
  limit?: number
  search?: Nullish<string>
}

export type RunbookUserApi = {
  id: number
  name: string
  color: string
  status: UserStatus
  notInAccount: boolean
  archived: boolean
  online: boolean | null
}

type PagedRunbookUsers = {
  meta: {
    runbookTeamsCount: number
    runbookUsersCount: number
    filteredRunbookUsersCount: number
    filteredRunbookTeamsCount: number
    adminIds: number[]
    streamUserIds: number[]
    runbookTeamUserIds: number[]
    canUpdate: boolean
  }
  users: RunbookUser[]
}

type PagedRunbookUsersApi = {
  meta: {
    runbookTeamsCount: number
    runbookUsersCount: number
    filteredRunbookUsersCount: number
    filteredRunbookTeamsCount: number
    adminIds: number[]
    streamUserIds: number[]
    runbookTeamUserIds: number[]
    permissions?: {
      update?: number[]
      manage_global?: number[]
    }
  }
  users: RunbookUserApi[]
}

function getHighestPrivilegeRoleFromMeta(userId: number, adminIds: number[], streamUserIds: number[]): Role {
  if (adminIds.includes(userId)) {
    return Role.RunbookAdmin
  }

  if (streamUserIds.includes(userId)) {
    return Role.StreamEditor
  }

  return Role.RunbookTeamMember
}

export function useRunbookUsersQuery(
  query: RunbookUsersQuery,
  currentUserId?: number,
  options?: UseInfiniteQueryOptions<PagedRunbookUsers, ApiError>
) {
  const { runbookId, runbookVersionId, offset, limit, search } = query
  const setPermissions = useSetPermissions('runbook-users')
  const getRunbookUsers = async (offset: number) => {
    const { data } = await apiClient.get<PagedRunbookUsersApi>({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/users`,
      params: {
        offset,
        limit,
        query: search
      },
      convertCase: true
    })

    // create usersCount and teamsCount cached data to use for frontend validation
    queryClient.setQueryData(['usersCount', runbookVersionId], data.meta.runbookUsersCount)
    queryClient.setQueryData(['teamsCount', runbookVersionId], data.meta.runbookTeamsCount)

    if (data?.meta?.permissions) {
      setPermissions(data.meta.permissions)
    }

    const {
      meta: { adminIds, streamUserIds, runbookTeamUserIds = [] },
      meta
    } = data
    const { permissions, ...restMeta } = meta
    return {
      meta: {
        ...restMeta,
        canUpdate: currentUserId && permissions?.update ? permissions.update.includes(currentUserId) : false
      },
      users: (data.users ?? []).map(user => ({
        ...user,
        status: getUserStatus({
          archived: user.archived,
          notInAccount: user.notInAccount,
          status: user.status
        }),
        name: unescape(user.name), // TODO: remove HTML entities from server side data store
        role: getHighestPrivilegeRoleFromMeta(user.id, adminIds, streamUserIds),
        hasTeam: runbookTeamUserIds.includes(user.id)
      }))
    }
  }

  // TODO: review staleTime option
  return useInfiniteQuery<PagedRunbookUsers, ApiError>(
    ['feature', 'runbookUsers', runbookId, runbookVersionId, offset, limit, search],
    ({ pageParam = 0 }) => getRunbookUsers(pageParam),
    {
      getNextPageParam: (lastGroup, allPages) => {
        return lastGroup?.meta?.filteredRunbookUsersCount > allPages.length * (limit ?? 0)
          ? allPages.length * (limit ?? 0)
          : undefined
      },
      ...(options ?? {})
    }
  )
}

type RunbookUsersUpdateProps = {
  runbookId: number
  runbookVersionId: number
}

export type Stream = {
  id: number
  name?: string
}

export type UserRole = { id: number; role: Role; streamIds?: number[] }

type RunbookUsersRequestType = { id: number; role: string; streamIds?: number[] }[]

export function useRunbookUsersUpdate({ runbookId, runbookVersionId }: RunbookUsersUpdateProps) {
  return useMutation<any, ApiError, UserRole[]>(['runbook-users-update'], async (userRoles: UserRole[]) => {
    const userRolesPayload: RunbookUsersRequestType = userRoles.map(userRole => {
      const userRolesData: { id: number; role: string; streamIds?: number[] } = {
        id: userRole.id,
        role: userRole.role.toLowerCase()
      }

      if (userRole.role === 'Stream Editor' && userRole.streamIds?.length) {
        userRolesData.streamIds = userRole.streamIds
      }

      return userRolesData
    })

    const { data } = await apiClient.patch<RunbookUsersRequestType, any>({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_people/bulk_update`,
      requestProperty: 'users',
      data: userRolesPayload,
      convertCase: true
    })

    return data
  })
}
