import { unescape } from 'lodash'
import { useMutation, useQuery } from 'react-query'

import { toSnakeCase } from '@cutover/api'
import { RunbookTeamUser } from './use-runbook-team'
import { getUserStatus } from './use-users-query'
import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { UserStatus } from 'main/services/queries/types'
import { RunbookPersonDeleteResponse } from 'main/services/api/data-providers/runbook-types'

export enum Role {
  RunbookAdmin = 'Admin',
  RunbookTeamMember = 'Team Member',
  StreamEditor = 'Stream Editor'
}

enum RoleApi {
  RunbookAdmin = 'runbook admin',
  StreamEditor = 'stream editor'
}

function getHighestPrivilegeRoleFromRoleTypes(roleTypes: RunbookUserRoleTypeApi[]): Role {
  const roleNames = roleTypes.map(({ name }) => name.toLowerCase())

  if (roleNames.includes(RoleApi.RunbookAdmin)) {
    return Role.RunbookAdmin
  }

  if (roleNames.includes(RoleApi.StreamEditor)) {
    return Role.StreamEditor
  }

  return Role.RunbookTeamMember
}

export type RunbookUserQuery = {
  currentUserId?: number
  runbookId: number
  runbookVersionId: number
  userId: number
}

export type RunbookUser = {
  id: number
  name: string
  color: string
  status: UserStatus
  role: Role
  online: boolean | null
  streamIds?: number[]
  teams: RunbookUserTeam[]
  canUpdate?: boolean
  isLastAdmin?: boolean
}

export type RunbookUserTeam = {
  id: number
  name: string
  color: string
  linked?: boolean
  usersCount: number
}

export type RunbookUserTeamApi = RunbookUserTeam

type RunbookUserRoleTypeApi = {
  id: number
  name: string
}

export type RunbookUserResponseType = {
  user: {
    id: number
    name: string
    color: string
    status: string
    notInAccount?: boolean
    archived?: boolean
    roleTypes: RunbookUserRoleTypeApi[]
    runbookTeams: RunbookUserTeamApi[]
    streamIds?: number[]
    online: boolean | null
    lastAdmin?: boolean
  }
  meta: {
    permissions?: {
      update?: number[]
    }
  }
}

const toRunbookUser = (data: RunbookUserResponseType, currentUserId?: number): RunbookUser => {
  const { user, meta } = data

  return {
    id: user.id,
    name: user.name,
    color: user.color,
    status: getUserStatus({
      archived: user.archived ?? false,
      notInAccount: user.notInAccount ?? false,
      status: user.status
    }),
    online: user.online,
    role: getHighestPrivilegeRoleFromRoleTypes(user.roleTypes),
    streamIds: user.streamIds,
    isLastAdmin: user.lastAdmin,
    teams: user.runbookTeams.map(runbookTeam => ({ ...runbookTeam, name: unescape(runbookTeam.name) })),
    canUpdate: currentUserId && meta?.permissions?.update ? meta.permissions.update.includes(currentUserId) : false
  }
}

export function useRunbookUser({ currentUserId, runbookId, runbookVersionId, userId }: RunbookUserQuery) {
  return useQuery<RunbookUser, ApiError>(
    ['feature', 'runbook-user', runbookId, runbookVersionId, userId],
    async () => {
      const { data } = await apiClient.get<RunbookUserResponseType>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_people/${userId}`,
        params: {
          type: 'user'
        },
        convertCase: true
      })

      return toRunbookUser(data, currentUserId)
    },
    {
      cacheTime: 0,
      useErrorBoundary: true
    }
  )
}

export type RunbookUserUpdateProps = {
  runbookId: number
  runbookVersionId: number
  userId: number
}

type RunbookUserUpdateRequestType = {
  role: string
  streamIds: number[]
  removedRunbookTeamIds: number[]
}

type RunbookUserDeleteRequestType = {
  reassign?:
    | {
        runbookTeamId: number
        selected: string
        userId: number
        usersToReassign: number[]
      }
    | undefined
    | {}
    | { selected: 'none' }
    | {
        userId: number
        selected: 'user'
      }
    | {
        runbookTeamId?: number
        selected: 'user'
      }
  data?: { usersToReassign: RunbookTeamUser[] } | undefined
}

export function useRunbookUserUpdate({ runbookId, runbookVersionId, userId }: RunbookUserUpdateProps) {
  return useMutation<unknown, ApiError, RunbookUserUpdateRequestType>(['runbook-update'], async payload => {
    const { data } = await apiClient.patch<RunbookUserUpdateRequestType, unknown>({
      url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_people/${userId}`,
      data: payload,
      convertCase: true
    })

    return data
  })
}

export function useRunbookUserDelete({ runbookId, runbookVersionId, userId }: RunbookUserUpdateProps) {
  return useMutation<RunbookPersonDeleteResponse, ApiError, RunbookUserDeleteRequestType>(
    ['runbook-update'],
    async payload => {
      const { data } = await apiClient.patch<RunbookUserDeleteRequestType, unknown>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_people/${userId}/delete`,
        data: toSnakeCase(payload) as RunbookUserDeleteRequestType
      })

      return data as RunbookPersonDeleteResponse
    }
  )
}
