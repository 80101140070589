// @ts-nocheck
import { observable, action, computed, reaction } from 'mobx'
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { IncidentSeveritiesViewPresenter } from './IncidentSeveritiesViewPresenter'
import { IncidentSeveritiesListPresenter } from './IncidentSeverityList/IncidentSeveritiesListPresenter'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { IFormViewModel, IncidentSeverityFormPresenter } from './IncidentSeverityForm/IncidentSeverityFormPresenter'
import { IncidentSeverityEditPresenter } from './IncidentSeverityEdit/IncidentSeverityEditPresenter'
import tinycolor2 from 'tinycolor2'

@injectable()
export class IncidentSeveritiesPagePresenter {
  @observable
  public incidentSeveritiesViewPresenter: IncidentSeveritiesViewPresenter = container.get(
    IncidentSeveritiesViewPresenter
  )

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public addFormPresenter: IncidentSeverityFormPresenter = new IncidentSeverityFormPresenter()

  @observable
  public incidentSeveritiesListPresenter: IncidentSeveritiesListPresenter = new IncidentSeveritiesListPresenter()

  @observable
  public incidentSeverityEditPresenter: IncidentSeverityEditPresenter = new IncidentSeverityEditPresenter()

  @observable
  public editFormPresenter: IncidentSeverityFormPresenter = new IncidentSeverityFormPresenter().withDirtyCallback(
    () => {
      this.incidentSeveritiesViewPresenter.pageVM.editPanelDirty = true
    }
  )

  constructor() {
    this.setupReactions()
  }

  @computed
  public get incidentSeveritiesTotal(): number {
    return this.incidentSeveritiesViewPresenter.incidentSeveritiesTotal
  }

  @computed
  public get angularJSSettingsPanelOpenState(): boolean {
    return container.get(CurrentPagePresenter).angularJSSettingsPanelOpenState
  }

  private setupReactions = () => {
    if (Object.keys(this.incidentSeveritiesViewPresenter.accountsVM).length === 0) {
      reaction(
        () => this.incidentSeveritiesViewPresenter.accountsVM,
        (accountsVm, reaction) => {
          reaction.dispose()
        }
      )
    }
  }

  @computed
  public get editPanelOpen(): boolean {
    return this.incidentSeveritiesViewPresenter.pageVM.editPanelOpen
  }

  @computed
  public get editPanelLoading(): boolean {
    return this.incidentSeveritiesViewPresenter.pageVM.editPanelLoading
  }

  public clearEditPanel = () => {
    this.incidentSeveritiesViewPresenter.clearEditPanel()
  }

  public setEditClosed = () => {
    this.incidentSeveritiesViewPresenter.setPageVMValue('editPanelOpen', false)
  }

  @computed
  public get listVM() {
    return this.incidentSeveritiesViewPresenter.listVM
  }

  @computed
  public get incidentSeverityVM() {
    return this.incidentSeveritiesViewPresenter.incidentSeverityVM
  }

  @action
  public incidentSeverityVMChange = () => {
    if (this.incidentSeverityVM) this.setFormViewModel()
  }

  @action
  public setAddFormViewModel = () => {
    this.addFormPresenter.withViewModel({
      label: ''
    })
  }

  @computed
  public get loading(): boolean {
    return this.incidentSeveritiesViewPresenter.repositoryLoading
  }

  @action
  public resetSubmit = () => {
    return false
  }

  public checkEditPanelIsDirty = (): boolean => {
    return this.incidentSeveritiesViewPresenter.checkEditPanelIsDirty()
  }

  @action
  private setFormViewModel = () => {
    const { incidentSeverityVM } = this.incidentSeveritiesViewPresenter
    if (!incidentSeverityVM) return

    const formVM: IFormViewModel = {
      label: incidentSeverityVM.label,
      color: tinycolor2(incidentSeverityVM.color).toRgb()
    }

    this.editFormPresenter.withViewModel(formVM)
  }
}
