import { Avatar } from '@cutover/react-ui'

type AvatarNodeProps = {
  firstName: string
  lastName: string
  color: string
}

// no recoil
export const AvatarNode = ({ firstName, lastName, color }: AvatarNodeProps) => {
  const user = { firstName, lastName, color }
  return <Avatar subject={user} />
}
