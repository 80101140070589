import { eventManager } from 'event-manager'
import { sortBy, unescape } from 'lodash'
import { useLocation, useMatch, useSearchParams } from 'react-router-dom'

import { useNotify } from '@cutover/react-ui'
import { NavItemSavedViewGroup } from './nav-item-saved-view-group'
import { useSavedViewPermissions, useSidebarNavigate } from '../hooks'
import { CreatePublicGroupInput, NavItem, SidebarSection } from '../nav-item'
import { useCurrentUser } from 'main/recoil/current-user'
import { useLanguage } from 'main/services/hooks'
import { PartialAccount as Workspace } from 'main/services/queries/use-accounts'
import { useCreateSavedViewGroup, useSavedViewGroups } from 'main/services/queries/use-saved-view-groups'

export const SectionWorkspaces = ({ workspaces, hidden }: { hidden: boolean; workspaces: Workspace[] }) => {
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'defaultNav' })
  return (
    <SidebarSection heading={t('title', { context: 'workspaces' })} hidden={hidden}>
      {sortBy(workspaces, 'name').map(workspace => {
        return <NavItemWorkspace key={workspace.id} account={workspace} />
      })}
    </SidebarSection>
  )
}

const NavItemWorkspace = ({ account }: { account: Workspace }) => {
  const notify = useNotify()
  const navigate = useSidebarNavigate()
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'defaultNav' })
  const currentUser = useCurrentUser()
  const defaultViewLink = `/app/${account.slug}/runbooks/list`
  const locationState: any = useLocation().state
  const hasActiveSavedView = !!locationState?.activeSavedView
  const workspaceMatch = useMatch({ path: `/app/${account.slug}`, end: false })
  const isAccountMatch = !!workspaceMatch
  const runbooksListMatch = useMatch({ path: defaultViewLink, end: true })
  const { isLoading: isLoadingSavedViews, data: savedViewGroupsData } = useSavedViewGroups(account.id, {
    enabled: !!isAccountMatch
  })
  // using >= 0 so that dummy created saved view from optimistic updated view created is included
  // NOTE: private and default group are given virtual negative ids from backend response
  const publicSavedViewGroups = savedViewGroupsData?.saved_view_groups.filter(group => group.id >= 0)
  const mySavedViewGroup = savedViewGroupsData?.saved_view_groups.find(group => group.name === '_private')
  const showActive = isAccountMatch && !hasActiveSavedView

  const [, setSearchParams] = useSearchParams()

  const { mutate: createSavedViewGroup } = useCreateSavedViewGroup(account.id, {
    onSuccess: () => notify.success(t('notification.savedViewGroupCreatedSuccess')),
    onError: () => notify.error(t('notification.savedViewGroupCreatedError'))
  })

  const userCan = useSavedViewPermissions(account.id)
  const canCreate = userCan('create')

  const handleClickCreateSavedViewGroup = (groupName: string) => {
    createSavedViewGroup({
      name: groupName,
      public: true,
      user_id: currentUser.id
    })
  }

  const handleClickWorkspace = () => {
    // can't use react router's params matching here because angular doesn't actually register them in the route change
    // when changes result from the filter panel.
    const paramsString = window.location.href.split('?')[1]
    if (!runbooksListMatch) {
      navigate(defaultViewLink)
    } else if (paramsString) {
      setSearchParams({})
      eventManager.emit('clear-runbook-filters')
    }
  }

  return (
    <NavItem
      loading={isLoadingSavedViews}
      icon={account.icon_name as any}
      label={unescape(account.name)}
      isActive={showActive}
      onClick={() => handleClickWorkspace()}
      expandable
      isOpen={isAccountMatch && !isLoadingSavedViews}
    >
      {mySavedViewGroup && (
        <NavItemSavedViewGroup
          groups={publicSavedViewGroups || []}
          accountId={account.id}
          isGlobal={false}
          savedViewGroup={mySavedViewGroup}
        />
      )}
      {publicSavedViewGroups?.map(savedViewGroup => {
        return (
          <NavItemSavedViewGroup
            // the group item needs the other groups for the move view functionality
            accountId={account.id}
            isGlobal
            key={savedViewGroup.id}
            savedViewGroup={savedViewGroup}
            groups={publicSavedViewGroups}
          />
        )
      })}
      {canCreate && <CreatePublicGroupInput onSubmit={handleClickCreateSavedViewGroup} />}
    </NavItem>
  )
}
