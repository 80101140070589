import { createElement } from 'react'
import { CustomLayerProps, Line, Serie } from '@nivo/line'
import { format, getUnixTime as toUnix } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { ChartAxesLayer, ChartBackgroundRowsLayer, colors, createChartYAxisLabel, nivoTheme } from '@cutover/react-ui'
import { getTaskCompletionChartData, useTaskCompletionChartData } from './use-task-completion-chart-data'
import { MrdDashboardWidget } from '../../account/mrd-dashboard-widget' // bring this level above so runbook context can use this
import { DashboardComponent, DashboardMediaType } from '../../types'

export type TaskCompletionOverTimeWidgetProps = {
  media: DashboardMediaType
  data: DashboardComponent
}

export type TaskCompletionOverTimeWidgetData = {
  id: string | number
  color: string
  data: Serie[]
}

export const TaskCompletionOverTimeWidget = (props: TaskCompletionOverTimeWidgetProps) => {
  const {
    data: { tasks = [], runbook, timezone },
    media
  } = props

  if (tasks.length === 0) return <></> // does not display the widget (in orignial angular version)

  const getChartDataFunction = media === 'screen' ? useTaskCompletionChartData : getTaskCompletionChartData
  const {
    lineChartData,
    latestEndPlanned,
    latestEndDisplay,
    chartEnd,
    chartStart,
    activePointsToDisplayFilledCircle,
    now
  } = getChartDataFunction({
    tasks,
    runbook
  })
  const shadedArea = createShadedArea({ latestEndPlanned, latestEndDisplay })
  const Points = createPoints(activePointsToDisplayFilledCircle)
  const YAxisLabel = createChartYAxisLabel('Number of Tasks')

  return (
    <MrdDashboardWidget title="Task Completion Over Time" fullWidth>
      <Line
        height={300}
        width={500}
        margin={{
          top: 10,
          right: 35,
          bottom: 25,
          left: 65
        }}
        data={lineChartData}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d %H:%M',
          useUTC: false
        }}
        yScale={{
          type: 'linear',
          max: tasks.length < 10 ? 10 : tasks.length * 1.1,
          min: 0
        }}
        theme={nivoTheme}
        layers={[
          ChartBackgroundRowsLayer,
          YAxisLabel,
          ChartAxesLayer,
          'axes',
          DashedLine,
          'markers',
          shadedArea,
          Points
        ]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickValues: [new Date(chartStart * 1000), new Date(chartEnd * 1000)], // x-axis chart start and chart end display only
          format: value => format(utcToZonedTime(value, timezone), 'dd MMM HH:mm'),
          ticksPosition: 'before',
          tickSize: 0
        }}
        axisLeft={{
          tickSize: 5,
          tickValues: 5,
          tickPadding: 10,
          legend: 'Number of Tasks',
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        markers={
          // x-axis vertical line indicating today marker. if today is greater than chartEnd, marker becomes chartEnd
          runbook.stage !== 'planning'
            ? [
                {
                  axis: 'x',
                  value:
                    toUnix(now) >= chartStart
                      ? toUnix(now) >= chartEnd
                        ? new Date(chartEnd * 1000)
                        : now
                      : new Date(chartStart * 1000),
                  lineStyle: { stroke: nivoTheme?.textColor, strokeWidth: 2, strokeDasharray: '2,2' },
                  textStyle: { fill: nivoTheme?.textColor, fontSize: 8 }
                }
              ]
            : []
        }
        pointSize={10}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        colors={d => d.color}
      />
    </MrdDashboardWidget>
  )
}

const createPoints = (activePoints: any[]) => (props: CustomLayerProps) => {
  const { pointSize = 0, points } = props
  // active points (actual data point, etc.) needs to have a filled circle symbol
  const findActivePointMatch = (point: any) => {
    return activePoints.some(activePoint => activePoint.x === point.data.x && activePoint.y === point.data.y)
  }
  const mappedPoints = points.reverse().map(point => {
    const borderColor = point.borderColor
    const isActivePoint = findActivePointMatch(point)
    const strokeDashArray = point.serieId === 'actual-forecast' && point.id === 'actual-forecast.1' ? '2,2' : 'none'
    return {
      id: point.id,
      symbol: Circle,
      x: point.x - pointSize / 2,
      y: point.y - pointSize / 2,
      fill: isActivePoint ? borderColor : colors.white,
      borderColor: borderColor,
      opacity: isActivePoint ? 1 : 'none',
      strokeDashArray
    }
  })

  return (
    <g>
      {mappedPoints.map(({ symbol, ...point }) => createElement(symbol, { ...point, size: pointSize, key: point.id }))}
    </g>
  )
}

const Circle = ({ x, y, size, opacity, borderWidth = 2, borderColor, fill, strokeDashArray }: any) => {
  return (
    <circle
      r={size / 2}
      cx={x + size / 2}
      cy={y + size / 2}
      fill={fill}
      opacity={opacity}
      strokeWidth={borderWidth}
      stroke={borderColor}
      style={{
        pointerEvents: 'none'
      }}
      strokeDasharray={strokeDashArray}
    />
  )
}

const DashedLine = (props: any) => {
  const { series, lineGenerator, xScale, yScale } = props
  return series.map(({ id, data, color }: Serie) => {
    if (!data.length) return <></>
    return (
      <path
        key={id}
        d={lineGenerator(
          data.map(d => ({
            x: xScale(d.data.x),
            y: d.data.y === null ? d.data.y : yScale(d.data.y)
          }))
        )}
        fill="none"
        stroke={color}
        style={id !== 'actual' ? { strokeDasharray: '2,1', strokeWidth: 2 } : { strokeWidth: 2 }}
      />
    )
  })
}

// shaded area logic to highlight difference between planned and forecast (ported over from - burn_chart_directive line 162)
const createShadedArea =
  ({ latestEndPlanned, latestEndDisplay }: { latestEndPlanned: number; latestEndDisplay: number }) =>
  (props: any) => {
    const { innerHeight: height, innerWidth } = props
    const actualEarlyShadeColor = colors.success
    const plannedActualDiffShadeColor = colors.warningOrange

    if (latestEndPlanned > latestEndDisplay) {
      const actualData = props.data.filter((data: Serie) => data.id === 'actual')[0]
      const actualEarlyDataPointToMark = actualData.data[actualData.data.length - 1]
      if (actualEarlyDataPointToMark && actualEarlyDataPointToMark.x) {
        const x = props.xScale(actualEarlyDataPointToMark.x)
        const width = innerWidth - x
        return <rect width={width} height={height} x={x} fill={actualEarlyShadeColor} opacity={0.2} />
      }
    } else {
      const plannedData = props.data.filter((data: Serie) => data.id === 'planned')[0]
      const plannedDataPointToMark = plannedData.data[plannedData.data.length - 1]
      if (plannedDataPointToMark && plannedDataPointToMark.x) {
        const x = props.xScale(plannedDataPointToMark.x)
        const width = innerWidth - x
        return <rect width={width} height={height} x={x} fill={plannedActualDiffShadeColor} opacity={0.2} />
      }
    }
  }
