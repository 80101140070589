// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { action, observable, computed } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { IncidentSeverityFormPresenter } from '../IncidentSeverityForm/IncidentSeverityFormPresenter'
import { IncidentSeveritiesViewPresenter } from 'app/Settings/IncidentSeverities/IncidentSeveritiesPage/IncidentSeveritiesViewPresenter'
import { ToasterService } from 'Components/Molecules'
import tinycolor2 from 'tinycolor2'

@injectable()
export class IncidentSeverityAddPresenter {
  private incidentSeveritiesViewPresenter: IncidentSeveritiesViewPresenter = container.get(
    IncidentSeveritiesViewPresenter
  )

  private toasterService: ToasterService = container.get(ToasterService)

  private language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public incidentSeverityFormPresenter: IncidentSeverityFormPresenter

  @observable
  public postSuccessCall?: () => void

  @computed
  public get formSubmitted(): boolean {
    return this.incidentSeveritiesViewPresenter.submitted
  }

  public saveFunction = () => {
    if (this.formSubmitted) this.save()
  }

  @action
  public withPostSuccessCall = (call: () => void) => {
    this.postSuccessCall = call
    return this
  }

  @action
  public withIncidentSeverityFormPresenter = (incidentSeverityFormPresenter: IncidentSeverityFormPresenter) => {
    this.incidentSeverityFormPresenter = incidentSeverityFormPresenter
    return this
  }

  @action
  public save = async () => {
    const result = this.incidentSeverityFormPresenter.processSubmission()

    if (result.result) {
      const response = await this.incidentSeveritiesViewPresenter.createIncidentSeverity({
        label: result.viewModel.label,
        color: `#${tinycolor2(result.viewModel.color).toHex()}`
      })

      if (!response.success) {
        if (response.errorMessages) {
          this.incidentSeverityFormPresenter.editForm.serverErrors = response.errorMessages
          this.incidentSeverityFormPresenter.errorAlertPresenter.setErrorMessages([...response.errorMessages])
        }
        this.incidentSeveritiesViewPresenter.submitted = false
      } else {
        this.toasterService.pop(
          this.language.get('incidentSeverities:toasters:add:notification:title'),
          this.language.get('incidentSeverities:toasters:add:notification:message'),
          'success'
        )

        if (this.postSuccessCall) {
          this.postSuccessCall()
        }

        this.incidentSeveritiesViewPresenter.loadIncidentSeverities()
        this.incidentSeveritiesViewPresenter.submitted = false
      }
    } else {
      this.incidentSeveritiesViewPresenter.submitted = false
    }
  }
}
