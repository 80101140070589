import { useCallback } from 'react'

import { useFeature } from '../hooks'
import { routes } from './routes'
import { useWorkspaceData } from 'main/services/api/data-providers/workspace'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'

export const useRouting = () => {
  const { isEnabled } = useFeature()
  const reactRunbook = isEnabled('react_runbook')
  const { runbookLookup } = useWorkspaceData()
  const { runbookTypeLookup } = useAccountRunbookTypes()

  const toRunbook = useCallback(
    ({ runbookId, ...params }: Parameters<typeof routes.toRunbook>[0]) => {
      const runbookTypeId = runbookLookup?.[runbookId]?.runbook_type_id
      const runbookType = runbookTypeId ? runbookTypeLookup?.[runbookTypeId] : undefined
      const shouldOpenReactRunbook = reactRunbook && !runbookType?.incident

      return routes.toRunbook({
        runbookId,
        ...params,
        reactRunbook: shouldOpenReactRunbook
      })
    },
    [reactRunbook, runbookLookup, runbookTypeLookup]
  )

  return { toRunbook }
}
