import { memo } from 'react'
import { useRecoilValue } from 'recoil'

import { TaskEditForm } from './task-edit-form'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { taskEditInitialDataState } from 'main/recoil/runbook'

export const TaskEditPanel = memo(() => {
  const [{ taskId }, { closeRightPanel }] = useRightPanelTypeState('task-edit')
  const taskEditInitialData = useRecoilValue(taskEditInitialDataState(taskId ?? null))

  return (
    <>
      {taskEditInitialData && (
        <TaskEditForm key={taskEditInitialData.id} data={taskEditInitialData} onClose={closeRightPanel} />
      )}
    </>
  )
})
