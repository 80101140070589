import { useCallback } from 'react'
import { useRecoilCallback } from 'recoil'
import { produce } from 'immer'

import {
  RunbookCommentCreateResponse,
  RunbookCommentsGetResponse,
  RunbookCommentToggleFeaturedResponse,
  RunbookResponse
} from 'main/services/api/data-providers/runbook-types'
import { runbookCommentsResponseState_INTERNAL } from '../models/runbook/runbook-comments'
import { useCommentNotifications } from './use-comment-notifications'

export const useProcessRunbookCommentsResponse = () => {
  const processRunbookCommentCreateResponse = useProcessRunbookCommentCreateResponse()
  const processRunbookCommentFeaturedResponse = useProcessRunbookCommentFeaturedResponse()
  const { commentCreateNotification } = useCommentNotifications()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          commentCreateNotification(response as RunbookCommentCreateResponse)
          processRunbookCommentCreateResponse(response as RunbookCommentCreateResponse)
          break
        case 'toggle_featured':
          processRunbookCommentFeaturedResponse(response as RunbookCommentToggleFeaturedResponse)
          break
        default:
          return
      }
    },
    [processRunbookCommentCreateResponse, processRunbookCommentFeaturedResponse]
  )
}

export const useProcessRunbookCommentCreateResponse = () => {
  return useRecoilCallback(({ set }) => async (response: RunbookCommentCreateResponse) => {
    set(runbookCommentsResponseState_INTERNAL, previousState =>
      produce(previousState, draft => {
        draft.comments.push(response.comment)
      })
    )
  })
}

export const useProcessRunbookCommentFeaturedResponse = () => {
  return useRecoilCallback(({ set }) => async (response: RunbookCommentToggleFeaturedResponse) => {
    set(runbookCommentsResponseState_INTERNAL, previousState =>
      produce(previousState, draft => {
        const index = draft.comments.findIndex(comment => comment.id === response.comment.id)

        if (index !== -1) {
          draft.comments[index] = response.comment
        }
      })
    )
  })
}

export const useProcessRunbookCommentsGetResponse = () => {
  return useRecoilCallback(({ set }) => async (response: RunbookCommentsGetResponse) => {
    set(runbookCommentsResponseState_INTERNAL, response)
  })
}
