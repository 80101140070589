import { ChangeEvent } from 'react'
import styled from 'styled-components'

import { Icon } from '@cutover/react-ui'
import { ActivityVM } from 'main/services/queries/use-activities'
import { useUpdateActivityMutation } from 'main/services/queries/use-update-activity-mutation'

type ActivityFeaturedProps = {
  activity: ActivityVM
  canUpdate: boolean
  isHovered: boolean
}

export const ActivityFeatured = ({ activity, canUpdate, isHovered }: ActivityFeaturedProps) => {
  const mutation = useUpdateActivityMutation()

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    if (!canUpdate) return

    mutation.mutate({
      activity: { ...activity, featured: e.target.checked }
    })
  }

  return (
    <CheckboxContainer canUpdate={canUpdate} htmlFor={`activity-featured-${activity.id}`}>
      {(activity.featured || isHovered) && (
        <>
          <HiddenCheckbox
            id={`activity-featured-${activity.id}`}
            data-testid={`activity-featured-${activity.id}`}
            aria-label="Featured"
            onChange={handleToggle}
            checked={!!activity.featured}
            aria-checked={!!activity.featured}
          />
          <Icon color="star" icon={activity.featured ? 'star-filled' : 'star'} />
        </>
      )}
    </CheckboxContainer>
  )
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  clip: rect(0 0 0 0);
  position: absolute;
  white-space: nowrap;
`

const CheckboxContainer = styled.label`
  cursor: ${(props: { canUpdate: boolean }) => (props.canUpdate ? 'pointer' : 'default')};
  display: inline-block;
`
