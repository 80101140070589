import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { getInfiniteRunbookCacheKey } from 'main/services/queries/use-runbooks-infinite'
import { RunbookListRunbook } from 'main/services/queries/types'

export const useHandleAccountResponse = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (response: {
      meta: {
        headers: { request_class: string; request_method: string; request_user_id: number }
        allowed_users: number[]
      }
      runbook: RunbookListRunbook
    }) => {
      if (response.meta.headers.request_class === 'Runbook') {
        queryClient.invalidateQueries(getInfiniteRunbookCacheKey())
      }
    },
    []
  )
}
