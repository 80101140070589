import { keyBy, mapValues } from 'lodash'
import { selector, selectorFamily } from 'recoil'

import { runbookVersionMetaState } from './runbook-version'
import {} from 'main/services/queries/use-runbook-versions'
import { filteredTasksState } from '../tasks/task-list'
import { RunbookTeam } from 'main/services/queries/types'

export const teamsState = selector<RunbookTeam[]>({
  key: 'teams:list',
  get: ({ get }) => {
    return get(runbookVersionMetaState).runbook_teams
  }
})

export const teamsStateLookup = selector<Record<RunbookTeam['id'], RunbookTeam>>({
  key: 'teams:lookup',
  get: ({ get }) => {
    return keyBy(get(teamsState), 'id')
  }
})

export const teamState = selectorFamily<RunbookTeam, { id: number }>({
  key: 'teams:id',
  get:
    ({ id }) =>
    ({ get }) => {
      return get(teamsStateLookup)[id]
    }
})

export const teamTasksCountState = selector<Record<number, number>>({
  key: 'teams:tasks:count',
  get: ({ get }) => {
    const teamsLookup = get(teamsStateLookup)
    const filteredTasks = get(filteredTasksState)

    return mapValues(teamsLookup, team => {
      return filteredTasks.filter(task => task.runbook_team_ids?.includes(team.id)).length
    })
  }
})
