import { useState } from 'react'
import styled from 'styled-components'

import { Box, Icon, Message, Modal, Text, TextInput, Tooltip } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ActivityVM, useDeleteCommentActivityMutation } from 'main/services/queries/use-activities'

type RemoveCommentControlProps = {
  activity: ActivityVM
  canUpdate: boolean
  isHovered: boolean
}

export const RemoveCommentControl = ({ activity, isHovered, canUpdate }: RemoveCommentControlProps) => {
  const { t } = useLanguage('activities')
  const deleteCommentMutation = useDeleteCommentActivityMutation()
  const comments = ['runbook.commented', 'task.commented'].includes(activity.key)
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [deletionReason, setDeletionReason] = useState<string>('')
  const [showError, setShowError] = useState<boolean>(false)

  const handleOnModalClose = () => {
    setConfirmModalOpen(false)
    setShowError(false)
    setDeletionReason('')
  }

  const deleteComment = async () => {
    if (activity.display?.comment_id) {
      deleteCommentMutation.mutate({ commentId: activity.display.comment_id, reason: deletionReason })
    }
    handleOnModalClose()
  }

  const renderModalContent = () => (
    <>
      {showError && <Message message={t('feed.deleteConfirm.error')} type="error" />}
      <Text>{t('feed.deleteConfirm.text')}</Text>
      <TextInput
        label={t('feed.deleteConfirm.inputLabel')}
        onChange={e => setDeletionReason(e.target.value)}
        hasError={showError}
        required
      />
    </>
  )

  return (
    <>
      <DeleteButtonContainer>
        {isHovered && comments && canUpdate && (
          <Tooltip content={t('feed.deleteComment')} placement="bottom">
            <Icon
              color="text-light"
              data-testid={`delete-button-${activity.id}`}
              icon="delete"
              onClick={() => setConfirmModalOpen(true)}
            />
          </Tooltip>
        )}
      </DeleteButtonContainer>
      <Modal
        data-testid={`confirm-deletion-${activity.id}`}
        open={confirmModalOpen}
        onClickConfirm={() => (!!deletionReason ? deleteComment() : setShowError(true))}
        onClose={() => handleOnModalClose()}
        title={t('feed.deleteConfirm.title')}
        dismissible={true}
        hasCancelButton={true}
        focusConfirmButton={true}
        confirmText={t('feed.deleteConfirm.button')}
        confirmIcon="check"
      >
        {renderModalContent()}
      </Modal>
    </>
  )
}

const DeleteButtonContainer = styled(Box)`
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
`
