import { memo } from 'react'
import { SetterOrUpdater, useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil'

import { Modal } from '@cutover/react-ui'
import { usePostAppEvents } from './apps-api'
import { AppsComponentParser } from './apps-component-parser'
import { AppModalViewState } from './apps-types'
import { appFieldValues, appModalLoadingState } from 'main/recoil/apps/apps-atoms'

type AppsModalProps = {
  modal: AppModalViewState
  setModal: SetterOrUpdater<AppModalViewState>
}

export const AppsModal = memo(({ modal, setModal }: AppsModalProps) => {
  const postAppEvents = usePostAppEvents()
  const { resourceId, appId, view, open } = modal
  const [loading, setLoading] = useRecoilState(appModalLoadingState)

  const {
    back_arrow: backArrow,
    block_dismissal: blockDismissal,
    hide_footer: hideFooter,
    content,
    button
  } = view ?? {}
  const context = `${resourceId}-${appId}`
  const getfieldValues = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        return await snapshot.getPromise(appFieldValues)
      },
    []
  )
  const setAppFieldValues = useSetRecoilState(appFieldValues)

  const handleModalActions = async (button: string) => {
    const values = await getfieldValues()
    const payload = {
      app_id: appId,
      runbook_id: resourceId,
      events: view ? [view] : undefined,
      state: { ...values[context], ...{ _modal_button: button } }
    }
    setLoading(true)
    postAppEvents(payload)
  }

  return (
    <>
      {view ? (
        <Modal
          onClickBack={backArrow ? () => handleModalActions('back') : undefined}
          dismissible={!blockDismissal}
          hasCancelButton={!blockDismissal}
          confirmText={button?.value}
          confirmIcon={button?.icon}
          hideFooter={hideFooter}
          loading={loading}
          onClickConfirm={() => {
            handleModalActions('confirm')
          }}
          onClose={() => setModal({ ...modal, open: false })}
          onAfterClose={() => {
            setModal({ view: undefined, appId: '', resourceId: '', open: false })
            setAppFieldValues(prevValues => {
              return { ...prevValues, [context]: {} }
            })
          }}
          open={open}
          title={view.header?.title || ''}
        >
          {content && content.length > 0 && (
            <AppsComponentParser content={content ?? []} appId={appId} resourceId={resourceId} />
          )}
        </Modal>
      ) : null}
    </>
  )
})
