import { useQuery } from 'react-query'

import { apiClient } from 'main/services/api'

export type Account = {
  id: number
  name: string
  slug: string
  title: string
  accountType: AccountType
}

export type AccountType = {
  id: number
  name: string
  iconType: string
}

/** @deprecated Use useAccountsQuery instead. */
export function useAccounts() {
  return useQuery<Account[], Error>(
    ['deprecated', 'accounts'],
    async () => {
      const { data } = await apiClient.get<Account[]>({
        url: 'accounts/permitted',
        params: { resource: 'user' },
        responseProperty: 'accounts',
        convertCase: true
      })

      return data ?? []
    },
    {
      cacheTime: 0
    }
  )
}
