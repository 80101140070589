import { observer } from 'mobx-react-lite'
import { Button, ButtonPresenter } from 'Components/Molecules'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

interface ISaveButton {
  saveCallback: () => void
  language: ILanguageService
}

export const SaveButton = observer<ISaveButton>(({ saveCallback, language }) => {
  const saveButtonPresenter = new ButtonPresenter(language.get('common:saveButton'), saveCallback)
    .withDisplayStyle('primary-invert')
    .withTextTransform('Lowercase')

  return <Button presenter={saveButtonPresenter} />
})
