import { ReactNode, useEffect } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom'

import { useFeature, useGlobalConfig } from '../../services/hooks'
import { useCurrentUser } from '../../recoil/current-user'

const DEFAULT_TIMEOUT = 36000

export const IntercomProviderWrapper = ({ children }: { children: ReactNode }) => {
  const { intercomAppId } = useGlobalConfig()
  const reactLoginEnabled = useFeature().isEnabled('react_login')

  if (!reactLoginEnabled || !intercomAppId) return <>{children}</>

  return (
    <IntercomProvider appId={intercomAppId || ''} shouldInitialize={process.env.NODE_ENV !== 'test'}>
      {children}
    </IntercomProvider>
  )
}

// This is an empty rendering component rather than a hook so that we can optionally render it
// depending on whether an intercomAppId is set in the global config
export const Intercom = () => {
  const { boot, shutdown } = useIntercom()
  const { deviseMaxSessionTimeout } = useGlobalConfig()
  const { hashed_email, first_name, last_name, email } = useCurrentUser()

  useEffect(() => {
    if (!hashed_email) return

    boot({
      name: `${first_name} ${last_name}`,
      email,
      userHash: hashed_email,
      sessionDuration: (deviseMaxSessionTimeout ? parseInt(deviseMaxSessionTimeout) : DEFAULT_TIMEOUT) * 1000,
      customLauncherSelector: '#help'
    })

    return shutdown
  }, [])

  return <></>
}
