// @ts-nocheck
import { observable, computed, action, Lambda, observe } from 'mobx'
import { postConstruct } from 'inversify'
import { Router } from 'app/Routing/Router'
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { IPageVM } from 'Shared/Entities/IPageVM'
import { toggleAnimateCutoverLogo } from 'Shared/Helpers/NavLogoAnimation'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { IncidentSeveritiesRepository } from 'app/Repositories/IncidentSeverity/IncidentSeveritiesRepository'
import { RoutingState } from 'app/Routing/RoutingState'
import { ISortParams } from 'Components/Organisms'
import { IIncidentSeverityPM } from 'Shared/Entities/Types/Incident/IIncidentSeverityPM'
import { IIncidentSeverityNewPM } from 'Shared/Entities/Types/Incident/IIncidentSeverityNewPM'
import { IIncidentSeverityVM } from 'Shared/Entities/Types/Incident/IIncidentSeverityVM'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IncidentSeverityRepository } from 'app/Repositories/IncidentSeverity/IncidentSeverityRespository'

@injectable()
export class IncidentSeveritiesViewPresenter {
  private router: Router = container.get(Router)
  private accountsRepository: AccountsRepository = container.get(AccountsRepository)
  private incidentSeveritiesRepository = container.get(IncidentSeveritiesRepository)
  private incidentSeverityRepository: IncidentSeverityRepository = container.get(IncidentSeverityRepository)
  private routingState: RoutingState = container.get(RoutingState)

  @observable
  public loading: boolean = false

  @observable
  public disposers: Lambda[] = []

  @observable
  public accountsVM: { [key: number]: string } = {}

  @observable
  public submitted: boolean = false

  @postConstruct()
  public init() {
    this.load()
    this.router.registerRouteLeaveCallback({ routeId: 'incidentSeverities', callback: this.clear })
    this.router.registerRouteEnterCallback({ routeId: 'incidentSeverities', callback: this.load })
  }

  @observable
  public sortParams: ISortParams = {
    accessor: 'label',
    dataType: 'string',
    sortDirection: 'asc'
  }

  @observable
  public pageVM: IPageVM = {
    editPanelLoading: false,
    editPanelOpen: false,
    editPanelDirty: false
  }

  @computed
  public get incidentSeverityVM(): IIncidentSeverityVM {
    const incidentSeverity = this.incidentSeverityRepository.incidentSeverityForEdit
    if (!incidentSeverity) return null
    return incidentSeverity
  }

  @action
  private load = async () => {
    this.loading = true
    toggleAnimateCutoverLogo(true)
    this.router.setCheckBeforeAction(() => {
      return this.pageVM.editPanelDirty
    })
    await this.loadAccounts()
    // add filtering
    this.buildAccountDependencies()
    this.setObservers()
    toggleAnimateCutoverLogo(false)
    this.loading = false
  }

  @computed
  public get incidentSeveritiesTotal(): number {
    return this.incidentSeveritiesRepository.incidentSeveritiesTotal
  }

  @action
  private clearDisposers = () => {
    this.disposers.forEach(disposer => disposer())
    this.disposers = []
  }

  @action
  private clear = () => {
    this.pageVM.editPanelOpen = false
    this.clearDisposers()
    setTimeout(() => this.clearEditPanel(), 500)
  }

  public clearEditPanel = () => {
    this.incidentSeverityRepository.clearIncidentSeverityForEdit()
  }

  public loadAccounts = async () => {
    await this.accountsRepository.safeLoadPermittedAccounts({ resource: 'user' })
  }

  public loadIncidentSeverities = async () => {
    return await this.incidentSeveritiesRepository.loadData({ sortParams: this.sortParams })
  }

  public loadIncidentSeverity = (id: number) => {
    if (this.pageVM.editPanelDirty) return
    this.incidentSeverityRepository.loadData({ id })
  }

  private setObservers = () => {
    const loadingObserver = observe(this.incidentSeveritiesRepository, 'loading', () => {
      this.pageVM.editPanelLoading = this.incidentSeveritiesRepository.loading
    })

    const editObserver = observe(this.incidentSeverityRepository, 'incidentSeverityForEdit', () => {
      if (this.incidentSeverityRepository.incidentSeverityForEdit) {
        this.pageVM.editPanelOpen = true
      }
    })

    const queryObserverDisposer = observe(this.routingState.currentState, 'query', async () => {
      if (this.routingState.currentState.routeId === 'incidentSeverities') {
        this.incidentSeveritiesRepository.loadData()
      }
    })

    this.disposers = [loadingObserver, queryObserverDisposer, editObserver]
  }

  @computed
  public get repositoryLoading(): boolean {
    return this.incidentSeveritiesRepository.loading || this.loading
  }

  @action
  public buildAccountDependencies = () => {
    let accountLookup = {}

    this.accountsRepository.permittedAccounts.forEach(account => {
      accountLookup[account.id] = account.name
    })

    this.pageVM.filterPanelLoading = false
    this.accountsVM = accountLookup
  }

  public createIncidentSeverity = async (incidentSeverity: IIncidentSeverityNewPM): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.incidentSeverityRepository.createIncidentSeverity(incidentSeverity)
    toggleAnimateCutoverLogo(false)
    return response
  }

  public setPageVMValue = (key: string, value: boolean) => {
    this.pageVM[key] = value
  }

  public checkEditPanelIsDirty = (): boolean => {
    return !!this.pageVM.editPanelDirty
  }

  @action
  public setSubmitted = (val: boolean) => {
    return (this.submitted = val)
  }

  @action
  public updateIncidentSeverityPM = (key: string, value: any) => {
    this.incidentSeverityRepository.incidentSeverityForEdit[key] = value
  }

  public saveIncidentSeverity = async (): Promise<IBaseResponse> => {
    toggleAnimateCutoverLogo(true)
    const response = await this.incidentSeverityRepository.saveIncidentSeverity()
    toggleAnimateCutoverLogo(false)
    return response
  }

  public resetIncidentSeverityForEdit = () => {
    this.incidentSeverityRepository.setProgrammersModel()
  }

  @computed
  public get listVM(): IIncidentSeverityPM[] {
    if (
      this.incidentSeveritiesRepository.incidentSeverities &&
      this.incidentSeveritiesRepository.incidentSeverities.length > 0
    ) {
      return this.incidentSeveritiesRepository.incidentSeverities.map((incidentSeverityPM: IIncidentSeverityPM) => {
        const { id, key, label, color } = incidentSeverityPM
        return {
          id,
          key,
          label,
          color
        }
      })
    }
    return []
  }
}
