import { useCallback, useMemo } from 'react'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { Box, ColumnConfig, IconButton, Table, Text } from '@cutover/react-ui'
import { useCloseRightPanelOnMount, useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useCurrentUser } from 'main/recoil/current-user'
import { useFeature, useLanguage } from 'main/services/hooks'
import { SamlConfiguration } from 'main/services/queries/types'
import { usePermissions } from 'main/services/queries/use-permissions'
import { useSamlConfigurationQuery } from 'main/services/queries/use-saml-configurations-query'

export const SamlConfigurationListPage = () => {
  const { t } = useLanguage('samlConfigurations')
  useCloseRightPanelOnMount()
  const { data } = useSamlConfigurationQuery()
  const items = data?.saml_configurations || []

  const permissions = usePermissions('saml-configs')
  const cutoverStaffUser = permissions('manage')
  const userCanViewSaml = permissions('show')

  const { isEnabled } = useFeature()
  const { openRightPanel, closeRightPanel } = useSetActiveRightPanelState()
  const { cutover_employee, roles } = useCurrentUser()
  const navigate = useNavigate()

  // TODO: remove this when feature is done and is no longer behind a feature flag
  const rolesWhitelist = ['Global admin', 'Global stakeholder', 'Global user admin']
  const currentUserRolesInWhitelist = rolesWhitelist.some(v => roles.includes(v))

  const rbacMappingColumns =
    isEnabled('rbac_mapping') || (cutover_employee && currentUserRolesInWhitelist)
      ? [
          {
            header: t('samlConfigurations.table.columnHeaders.roleMapping'),
            property: 'role_mapping',
            sortable: false,
            render: (samlConfiguration: SamlConfiguration) => (
              <Box direction="row" justify="center">
                <IconButton
                  icon="info"
                  label={t('samlConfigurations.table.columnHeaders.roleMapping')}
                  disabled={!Boolean(samlConfiguration.options?.active_roles)}
                  disableTooltip={true}
                  onClick={event => {
                    event.stopPropagation()
                    closeRightPanel()
                    navigate('../role_mappings', { state: { samlConfigurationId: samlConfiguration.id } })
                  }}
                />
              </Box>
            )
          }
        ]
      : []

  const samlConfigurationColumns: ColumnConfig<SamlConfiguration>[] = useMemo(() => {
    const hiddenColumn = {
      header: t('samlConfigurations.table.columnHeaders.hidden'),
      property: 'hidden',
      sortable: true,
      render: (datum: SamlConfiguration) => <Text>{datum.hidden ? 'Yes' : 'No'}</Text>
    }

    const columns = [
      {
        header: t('samlConfigurations.table.columnHeaders.id'),
        property: 'id',
        sortable: true,
        render: (datum: SamlConfiguration) => <Text> {datum.id} </Text>
      },
      {
        header: t('samlConfigurations.table.columnHeaders.name'),
        property: 'name',
        sortable: true
      },
      {
        header: t('samlConfigurations.table.columnHeaders.default'),
        property: 'default',
        sortable: true,
        render: (datum: SamlConfiguration) => <Text>{datum.default ? 'Yes' : 'No'}</Text>
      },
      {
        header: t('samlConfigurations.table.columnHeaders.lastUpdated'),
        property: 'updated_at',
        sortable: true,
        render: (datum: SamlConfiguration) => {
          return <Text>{format(new Date(datum.updated_at), 'E, do MMM yyyy HH:mm')}</Text>
        }
      }
    ].concat(rbacMappingColumns)

    if (cutoverStaffUser) {
      columns.splice(3, 0, hiddenColumn)
    }

    return columns
  }, [cutoverStaffUser])

  const handleClickRow = useCallback(
    ({ datum }: { datum: SamlConfiguration }) => {
      if (userCanViewSaml) {
        openRightPanel({ type: 'saml-configurations-edit', samlId: datum?.id as number })
      }
    },
    [openRightPanel, userCanViewSaml]
  )

  return <Table columns={samlConfigurationColumns} data={items} onClickRow={handleClickRow} />
}
