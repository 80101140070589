import { useMemo } from 'react'

import {
  Accordion,
  AccordionPanel,
  Badge,
  Icon,
  IconName,
  ListItem,
  MESSAGE_TYPE_ICON_MAP,
  Text
} from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { ValidateMergeResponse } from 'main/services/queries/validate-merge-query'

export const MergeValidationSummary = ({
  data: summaryData,
  isSnippet,
  isNewRunbook
}: {
  data: ValidateMergeResponse
  isSnippet?: boolean
  isNewRunbook?: boolean
}) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'mergeModal.validate' })
  const mergingRunbooksCount = summaryData.runbooks?.total // the number of runbooks selected
  const totalRunbooksCount = isNewRunbook ? mergingRunbooksCount : mergingRunbooksCount + 1

  const panels = useMemo(() => {
    return Object.keys(summaryData)
      .map((key: unknown) => {
        const section = key as keyof ValidateMergeResponse
        if (section === 'runbooks' || (section === 'streams' && isSnippet)) return null

        const { total, data } = summaryData[section]
        if (total === 0) return null

        const isAllSuccess = Object.keys(data).every(key => data[key] === totalRunbooksCount)

        const accordionPanelIcon: IconName = isAllSuccess ? MESSAGE_TYPE_ICON_MAP.success : MESSAGE_TYPE_ICON_MAP.info
        const accordionPanelIconColor = isAllSuccess ? 'success' : 'text-light'

        return (
          <AccordionPanel
            key={key as string}
            iconColor={accordionPanelIconColor}
            icon={accordionPanelIcon}
            label={t('section.title', { name: section })}
            suffix={<Badge aria-hidden label={total} max={4} type="light" />}
            a11yTitle={t('summary')}
          >
            <Text>{t('section.contains', { count: total, name: `$t(mergeModal.validate.${section})` })}</Text>
            {Object.keys(data).map(rowKey => {
              const rowTotal = data[rowKey]
              const isSuccess = rowTotal === totalRunbooksCount

              return (
                <ListItem
                  key={rowKey}
                  size="small"
                  startComponent={
                    <Icon
                      color={isSuccess ? 'success' : 'text-light'}
                      icon={isSuccess ? MESSAGE_TYPE_ICON_MAP.success : MESSAGE_TYPE_ICON_MAP.info}
                      size="small"
                    />
                  }
                  endComponents={[
                    <Text color="text-light">
                      {t('section.present', { amount: isSuccess ? 'all' : `${rowTotal}/${totalRunbooksCount}` })}
                    </Text>
                  ]}
                >
                  {rowKey}
                </ListItem>
              )
            })}
          </AccordionPanel>
        )
      })
      .filter(Boolean)
  }, [])

  return <Accordion>{panels}</Accordion>
}
