import { atom, selector, useRecoilValue, useResetRecoilState } from 'recoil'
import { groupBy } from 'lodash'

import { RunbookCommentsGetResponse } from 'main/services/api/data-providers/runbook-types'
import { RunbookComment } from 'main/services/api/data-providers/runbook-types/runbook-shared-types'

export const runbookCommentsResponseState_INTERNAL = atom<RunbookCommentsGetResponse>({
  key: 'runbook-comments:response',
  default: {
    meta: {
      permissions: {
        create: []
      }
    },
    comments: []
  }
})

export const runbookCommentsPermissionsState = selector({
  key: 'runbook-comments:permissions',
  get: ({ get }) => {
    return get(runbookCommentsResponseState_INTERNAL).meta.permissions
  }
})

export const taskCommentsLookupState = selector<Record<number, RunbookComment[]>>({
  key: 'task-comments:lookup',
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent'
  },
  get: ({ get }) => {
    const { comments } = get(runbookCommentsResponseState_INTERNAL)
    const taskComments = comments.filter(comment => !!comment.task_id)

    return groupBy(taskComments, 'task_internal_id')
  }
})

export const useResetComments = () => {
  return useResetRecoilState(runbookCommentsResponseState_INTERNAL)
}

export const useRunbookCommentsState = (id?: number) => {
  const { comments } = useRecoilValue(runbookCommentsResponseState_INTERNAL)
  const taskComments = useTaskComments(id)

  return id ? taskComments : comments
}

export const useCommentsCount = () => {
  const comments = useRunbookCommentsState()

  return comments ? comments.length : 0
}

export const useTaskCommentsCount = (id: number) => {
  return useTaskComments(id)?.length ?? 0
}

const useTaskComments = (id?: number) => {
  const taskComments = useRecoilValue(taskCommentsLookupState)

  return id ? taskComments[id] : []
}
