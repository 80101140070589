import { UIEvent, useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import styled from 'styled-components'

import { Box, Modal, px2rem } from '@cutover/react-ui'
import { RunbookTypeCard } from './runbook-type-card'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { useLanguage } from 'main/services/hooks'
import { RunbookTypeType } from 'main/services/queries/types'

export const ChooseRunbookType = () => {
  const maxHeight = 302
  const [showGradient, setShowGradient] = useState(false)

  const { activeRunbookTypes: runbookTypes } = useAccountRunbookTypes()

  const unmountConnector = () => {
    eventManager.emit('close-choose-runbook-type-modal')
  }

  const closeModal = () => {
    unmountConnector()
    setTimeout(() => {
      // needs to wait for the runbook template modal (old react) to open before focusing
      // TODO: remove once we aren't using event manager here
      const templateSearch = document.querySelector('#search-runbook-templates') as HTMLElement
      templateSearch && templateSearch.focus()
    }, 200)
  }

  useEffect(() => {
    if (runbookTypes && runbookTypes.length > 8) {
      setShowGradient(true)
    }
  }, [runbookTypes])

  const handleSelect = (runbookType: RunbookTypeType) => {
    closeModal()
    if (runbookType.runbook_templates_count > 0) {
      openRunbookTemplateModal(runbookType)
    } else {
      openEnterDetailsForm(runbookType)
    }
  }

  const openRunbookTemplateModal = (runbookType: RunbookTypeType) => {
    window.reactJS &&
      window.reactJS.openChooseRunbookTemplateModal &&
      window.reactJS.openChooseRunbookTemplateModal(
        {
          id: runbookType.id,
          iconName: runbookType.icon_name,
          iconColor: runbookType.icon_color
        },
        openPreviousModal
      )
  }

  const openEnterDetailsForm = (runbookType: RunbookTypeType) => {
    window.angularJS &&
      window.angularJS.openCreateRunbookModal &&
      window.angularJS.openCreateRunbookModal('enterDetailsForm', runbookType.id)
  }

  const openPreviousModal = () => {
    const elementForMount = document.querySelector('#cutover-react') as HTMLElement

    if (elementForMount) {
      eventManager.emit('open-choose-runbook-type-modal', {
        elementForMount: elementForMount
      })
    }
  }

  const onScroll = (event: UIEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLDivElement

    if (target) {
      const scrollPosition = target.scrollTop

      if (target.scrollHeight - scrollPosition === maxHeight && showGradient) {
        setShowGradient(false)
      } else {
        setShowGradient(true)
      }
    }
  }

  const { t } = useLanguage('runbooks')

  if (!runbookTypes) {
    return null
  }

  return (
    <Modal title={t('chooseRunbookType.heading')} width={572} open onClose={closeModal} hideFooter>
      <Content
        height={{ max: `${maxHeight}px` }}
        overflow={{ vertical: 'auto' }}
        direction="row"
        wrap
        justify="between"
        gap="15px"
        showGradient={showGradient}
        onScroll={onScroll}
      >
        {runbookTypes
          .sort((a, b) => a.id - b.id)
          .map(rbt => (
            <RunbookTypeCard key={rbt.id} runbookType={rbt} onSelect={handleSelect} />
          ))}
      </Content>
    </Modal>
  )
}

const Content = styled(Box)<{ showGradient: boolean }>`
  ${({ showGradient }) =>
    showGradient &&
    `
  &::after {
    content: '';
    position: absolute;
    bottom: ${px2rem(20)};
    left: 0;
    width: 100%;
    height: ${px2rem(40)};
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  `}
`
