import { atom } from 'recoil'

import { CsvImportForm } from 'main/components/runbook/modals/csv-import/csv-import-modal'

export const csvImportErrorsState = atom<string[] | undefined>({
  key: 'tasks:csv-import-errors',
  default: undefined
})

export const csvImportSubmittedValuesState = atom<CsvImportForm | undefined>({
  key: 'tasks:csv-import-form',
  default: undefined
})

export const csvImportModalStatus = atom<'submitting' | 'success' | 'error' | 'idle' | null>({
  key: 'tasks:csv-import:status',
  default: null
})
