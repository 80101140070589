import * as Sentry from '@sentry/react'
import { useQuery, UseQueryOptions } from 'react-query'

import { apiClient } from '../api'
import { GlobalConfigType } from '../hooks'
import { queryClient as staticQueryClient } from 'main/query-client'

export function useGetConfigs(opts: UseQueryOptions<GlobalConfigType, Error> = {}) {
  return useQuery<GlobalConfigType, Error, GlobalConfigType>('configs', getConfigs, {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...opts
  })
}

// Using with recoil will also populate the query client
export const fetchConfigQuery = async () => {
  return staticQueryClient.fetchQuery({
    queryKey: 'configs',
    queryFn: getConfigs,
    staleTime: Infinity,
    cacheTime: Infinity
  })
}

// feature flags included here will be added as tags in Sentry when enabled
const SENTRY_FEATURE_TAGS = ['react_runbook', 'react_workspace', 'react_login']

// Setting the Sentry tags in this function so that it occurs regardless of whether the
// configs are accessed via recoil or the hook wrapping react-query. This is only called
// once on app load by one or the other.
const getConfigs = async () => {
  const { data } = await apiClient.get<GlobalConfigType>({
    url: 'configs',
    convertCase: true
  })

  try {
    const { enabledFeatures } = data

    SENTRY_FEATURE_TAGS.forEach(feature => {
      // we don't want to set anything when false to avoid cluttering the tag list
      if (enabledFeatures.includes(feature)) {
        Sentry.setTag(feature, true)
      }
    })
  } catch (e) {
    console.warn('error setting config tags', e)
  }
  return data
}
