import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import { PlainTextButton } from '../../button'
import { ListItem } from '../../list'
import { focusRingCss } from '../../theme'

type Updater = Dispatch<SetStateAction<number>>

type ShowMoreButtonOptions = {
  showAll?: boolean
  step?: number
}

export const useShowMoreButton = (
  numOptions: number,
  { step = 10, showAll }: ShowMoreButtonOptions = {}
): [number, JSX.Element | null, Updater?] => {
  const [maxNumShowing, setMaxNumShowing] = useState(step)
  const hasNoButton = numOptions <= step
  const hasShowMoreButton = hasNoButton || showAll ? false : numOptions > maxNumShowing
  const hasShowLessButton = hasNoButton || showAll ? false : !hasShowMoreButton && maxNumShowing > step
  const hasShowMoreOrLessButton = hasShowMoreButton || hasShowLessButton

  const handleClickShowMore = useCallback((e: any) => {
    // need to include the prevent default to avoid the onChange handler from firing
    e.preventDefault()
    setMaxNumShowing(maxNum => maxNum + step)
  }, [])

  const handleClickShowLess = useCallback((e: any) => {
    // need to include the prevent default to avoid the onChange handler from firing
    e.preventDefault()
    setMaxNumShowing(step)
  }, [])

  const showButton = useMemo(() => {
    return (
      <ListItem size="small">
        <StyledPlainTextButton
          onClick={hasShowMoreButton ? handleClickShowMore : handleClickShowLess}
          css="text-align: center"
          onMouseDown={(event: any) => event.preventDefault()}
          onKeyDown={(event: any) => {
            if (event.key === 'Enter' || event.key === ' ') {
              return hasShowMoreButton ? handleClickShowMore(event) : handleClickShowLess(event)
            }
          }}
        >
          Show {hasShowMoreButton ? 'more' : 'less'}
        </StyledPlainTextButton>
      </ListItem>
    )
  }, [hasShowMoreButton, handleClickShowMore, handleClickShowLess])

  return [showAll ? numOptions : maxNumShowing, hasShowMoreOrLessButton ? showButton : null, setMaxNumShowing]
}

const StyledPlainTextButton = styled(PlainTextButton)<any>`
  &:focus-visible {
    ${focusRingCss}
  }
`
