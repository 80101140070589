import { unescape } from 'lodash'
import styled from 'styled-components'

import { duration, RagIndicator, Text } from '@cutover/react-ui'
import * as ListItem from 'main/components/shared/runbook-list-item/runbook-list-item-layout'
import { useCurrentUser } from 'main/recoil/current-user'
import { useRunbooksMetaProjects } from 'main/services/api/data-providers/workspace'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { useLanguage } from 'main/services/hooks'
import { FolderListFolder, RunbookListRunbook, RunbookTemplateStatus } from 'main/services/queries/types'

export const RunbookItemSubHeader = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { id } = runbook
  const { projectLookup } = useRunbooksMetaProjects()

  return (
    <>
      <ListItem.LeftItemTextSubtitle>
        <RagStatusDisplay runbook={runbook} />
        <Text truncate>
          <Text size="small" color="text-light">{`#${id}`}</Text>
          <TemplateStatus runbook={runbook} />
          <ProjectName runbook={runbook} projectLookup={projectLookup} />
          <DurationDiff runbook={runbook} />
          <TasksCount runbook={runbook} />
        </Text>
      </ListItem.LeftItemTextSubtitle>
    </>
  )
}

const RagStatusDisplay = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { status, status_message, template_type } = runbook
  const isSnippet = template_type === 'snippet'

  return !!status && !isSnippet ? (
    <RagIndicator
      status={status}
      tip={status_message}
      tipPlacement="top"
      data-testid={`rag-indicator-${status}`}
      // TODO: maybe refactor into something a little more future-proof
      css={`
        display: inline-block;
        position: relative;
        top: 1px;
        margin-right: 3px;
      `}
    />
  ) : null
}

const TemplateStatus = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { is_template, template_status, copies_count, reviewers, current_template_version_approval_status } = runbook

  return is_template && !!template_status ? (
    <TemplateInfo
      templateStatus={template_status}
      copiesCount={copies_count}
      reviewers={reviewers}
      templateApprovalStatus={current_template_version_approval_status}
    />
  ) : null
}

const ProjectName = ({
  runbook,
  projectLookup
}: {
  runbook: RunbookListRunbook
  projectLookup: Record<number, FolderListFolder> | undefined
}) => {
  const { project_parent_id, project_name } = runbook

  return (
    <>
      <Bullet />
      <Text size="small">
        {project_parent_id
          ? `${unescape(projectLookup?.[project_parent_id]?.name)} / ${project_name}`
          : capitalize(project_name)}
      </Text>
    </>
  )
}

const DurationDiff = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { is_template, end_diff, duration_display, template_type, start_display, run_type } = runbook
  const isSnippet = template_type === 'snippet'
  const { runbookTypeLookup } = useAccountRunbookTypes()
  const runbookType = runbookTypeLookup?.[runbook.runbook_type_id]
  const isIncident = runbookType?.incident

  if (isIncident) {
    return null
  }
  return !is_template && !isSnippet && run_type === 'live' ? (
    !!end_diff && (
      <>
        <Bullet />
        <DiffText early={end_diff.early} data-testid="diff-text" truncate>
          {durationDiffDisplay(end_diff)}
        </DiffText>
      </>
    )
  ) : !!start_display && !!duration_display ? (
    <>
      <Bullet />
      <Text size="small">{duration(duration_display)}</Text>
    </>
  ) : null
}

const TasksCount = ({ runbook }: { runbook: RunbookListRunbook }) => {
  const { tasks_count } = runbook
  const { t } = useLanguage('runbooks')

  return (
    <>
      {tasks_count > 0 && (
        <>
          <Bullet />
          <Text size="small" truncate>
            {t('list.listItem.subHeader.tasksCount', { count: tasks_count })}
          </Text>
        </>
      )}
    </>
  )
}

type TemplateInfoProps = {
  templateStatus: RunbookTemplateStatus
  copiesCount: RunbookListRunbook['copies_count']
  reviewers: RunbookListRunbook['reviewers']
  templateApprovalStatus: RunbookListRunbook['current_template_version_approval_status']
}
const TemplateInfo = (props: TemplateInfoProps) => {
  const { t } = useLanguage('runbooks')
  const currentUser = useCurrentUser()
  const awaitingReview = !!props.reviewers?.length && props.templateApprovalStatus === 'open'
  const currentUserIsReviewer = !!props.reviewers?.includes(currentUser?.id)

  return (
    <>
      <Bullet />
      <TemplateText {...props} data-testid="template-status">
        {capitalize(props.templateStatus.split('_')[1])}
        {awaitingReview && (
          <>
            {` - ${t('list.awaitingTemplateReview.partialText.awaiting')} `}
            {currentUserIsReviewer && <b>{`${t('list.awaitingTemplateReview.partialText.my')} `}</b>}
            {t('list.awaitingTemplateReview.partialText.review')}
          </>
        )}
      </TemplateText>
      {!!props.copiesCount && (
        <Text size="small">{` (${t('list.listItem.subHeader.copiesCount', { count: props.copiesCount })})`}</Text>
      )}
    </>
  )
}

const durationDiffDisplay = (end_diff: RunbookListRunbook['end_diff']) =>
  end_diff.value.substring(1, end_diff.value.length - 1)

const capitalize = (text: string) => text[0].toUpperCase() + text.slice(1).toLowerCase()

const TemplateText = styled(Text).attrs(({ templateStatus }: TemplateInfoProps) => ({
  size: 'small',
  color:
    templateStatus === 'template_draft'
      ? 'text-light'
      : templateStatus === 'template_pending'
      ? 'warning'
      : templateStatus === 'template_rejected'
      ? 'error'
      : 'success'
}))``

const DiffText = styled(Text).attrs((props: { early: boolean }) => ({
  size: 'small',
  color: props.early ? 'success' : 'warning'
}))<{ early: boolean }>``

const Bullet = () => <>&nbsp;•&nbsp;</>
