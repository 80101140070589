import { Placement } from '@floating-ui/react'

import { Box, Button, resolveColor, Tooltip, useTheme } from '@cutover/react-ui'

type FavoriteStarProps = {
  isFavorite: boolean
  toggleFavorite: () => void
  tooltip?: {
    addFavorite: string
    removeFavorite: string
    placement?: Placement
  }
}

export const FavoriteStar = ({ isFavorite, toggleFavorite, tooltip }: FavoriteStarProps) => {
  if (tooltip) {
    const { addFavorite, removeFavorite, placement } = tooltip

    return (
      <Tooltip content={isFavorite ? removeFavorite : addFavorite} placement={placement}>
        <FavoriteStarInner isFavorite={isFavorite} toggleFavorite={toggleFavorite} />
      </Tooltip>
    )
  }

  return <FavoriteStarInner isFavorite={isFavorite} toggleFavorite={toggleFavorite} />
}

const FavoriteStarInner = ({ isFavorite, toggleFavorite }: FavoriteStarProps) => {
  const theme = useTheme()

  return (
    <Box
      height="32px"
      align="center"
      justify="center"
      css={`
        position: relative;
        top: 1px;
      `}
    >
      <Button
        plain
        icon={isFavorite ? 'star-filled' : 'star'}
        onClick={toggleFavorite}
        css={`
          svg {
            height: 20px;
            width: 20px;
            fill: ${resolveColor(isFavorite ? 'star' : 'text-light', theme)};
          }
          &:hover {
            svg {
              fill: ${resolveColor('star', theme)};
            }
          }
        `}
      />
    </Box>
  )
}
