import { memo, useMemo } from 'react'
import { Heading } from 'grommet'
import { unescape } from 'lodash'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Box, IconButton, RunbookTypeIcon, TabGroup } from '@cutover/react-ui'
import { RunbookControl } from './runbook-control'
import { RunbookElapsedTime } from './runbook-elapsed-time'
import { RunbookToolbar } from './runbook-toolbar'
import {
  useAccountProperty,
  useIsCurrentRunbookVersion,
  useRunbookProperty,
  useRunbookRunbookType
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'

type RunbookActiveTab = 'pages' | 'dashboards' | 'tasks'

export const RunbookHeader = () => {
  const navigate = useNavigate()
  const isCurrentRunbookVersion = useIsCurrentRunbookVersion()
  const activeTab = 'tasks'

  const accountId = useAccountProperty({ attribute: 'slug' })

  const templateType = useRunbookProperty({ attribute: 'template_type' })
  const name = useRunbookProperty({ attribute: 'name' })

  const runbookType = useRunbookRunbookType()
  const isTemplate = templateType === 'default'

  return (
    <Box direction="row" fill justify="between" align="center">
      <Box direction="row" align="center" gap="6px">
        <Box height={{ min: '40px' }} width={{ min: '40px' }}>
          {/* TODO: go back to the runbook list with the most recent set of filters applied if navigated to the runbook
          from the runbooks list */}
          <IconButton
            tertiary
            icon="arrow-back"
            label="back" // TODO: i18n
            onClick={() => navigate(`/app/${accountId}/runbooks/list`)}
          />
        </Box>
        <Box height={{ min: '40px' }} width={{ min: '40px' }}>
          <RunbookTypeIcon
            icon={runbookType?.icon_name || 'getting-started'}
            color={runbookType?.icon_color || 'primary'}
            css="margin-right:8px;"
            template={isTemplate}
          />
        </Box>
        <Box justify="between" fill>
          <Box
            direction="row"
            gap="xxsmall"
            css={`
              position: relative;
              top: 5px;
            `}
            flex={false}
          >
            <RunbookTitle
              data-testid="runbook-title"
              responsive={false}
              margin={{ left: 'xsmall', right: 'none', vertical: 'none' }}
              level="1"
            >
              {unescape(name)}
            </RunbookTitle>
            <RunbookToolbar />
          </Box>
          <Box
            css={`
              white-space: nowrap;
              overflow-y: hidden;
              overflow-x: auto;
            `}
          >
            <Tabs activeTab={activeTab} />
          </Box>
        </Box>
      </Box>
      {isCurrentRunbookVersion && (
        <Box direction="row" align="center" flex={false}>
          <Box pad={{ left: 'xsmall', right: 'xsmall' }}>
            <RunbookElapsedTime />
          </Box>
          <Box direction="row" margin={{ top: '4px', right: '-6px' }} css="flex-basis: 52px;" flex={false}>
            <RunbookControl />
          </Box>
        </Box>
      )}
    </Box>
  )
}

const Tabs = memo(({ activeTab }: { activeTab: RunbookActiveTab }) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook.tabs' })

  const items = useMemo(() => {
    return [
      // Disabled Pages as only in Angular for now
      // {
      //   label: t('pages'),
      //   active: activeTab === 'pages',
      //   onClick: () => {}
      // },
      // Disabled Dashboards as only in Angular for now
      // {
      //   label: t('dashboards'),
      //   active: activeTab === 'dashboards',
      //   onClick: () => {}
      // },
      {
        label: t('tasks'),
        active: activeTab === 'tasks',
        onClick: () => {}
      }
    ]
  }, [activeTab])

  return <TabGroup items={items} />
})

const RunbookTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 5px;
`
