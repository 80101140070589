import { css } from 'styled-components/macro'

import { ListItemContainer } from '../list'
import { focusRingOutlineCss, overlayStyles, themeEdgeSize } from '../theme'

export const commonMenuStyles = css<{
  $showFocusRings: boolean
  $minWidth?: number
  $maxWidth?: number
  arrow: boolean
}>`
  z-index: 999;
  ul,
  li {
    border-radius: ${themeEdgeSize('xxsmall')};
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  ${ListItemContainer} {
    width: 100%;
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    &:focus-visible {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  ${props =>
    props.$showFocusRings &&
    css`
      ul,
      li {
        &:focus-visible {
          ${focusRingOutlineCss};
          outline-offset: 0 !important;
        }
      }
    `}

  li {
    cursor: pointer;
  }

  .szh-menu {
    list-style: none;
    ${props =>
      overlayStyles({
        minWidth: props.$minWidth ? `${props.$minWidth}px` : undefined,
        maxWidth: props.$maxWidth ? `${props.$maxWidth}px` : undefined
      })}
    ${props => props.arrow && 'border: 1px solid rgba(0, 0, 0, 0.05);'}
    box-sizing: border-box;
  }
`

export const arrowMenuStyles = css`
  .szh-menu__arrow {
    border-radius: 0;
    box-sizing: border-box;
    width: 0.75rem;
    height: 0.75rem;
    background-color: #fff;
    top: unset;
  }
  .szh-menu__arrow--dir-left {
    right: -0.375rem;
    transform: translateY(-50%) rotate(135deg);
  }
  .szh-menu__arrow--dir-right {
    left: -0.375rem;
    transform: translateY(-50%) rotate(-45deg);
  }
  .szh-menu__arrow--dir-top {
    bottom: -0.375rem;
    transform: translateX(-50%) rotate(-135deg);
  }
  .szh-menu__arrow--dir-bottom {
    top: -0.375rem;
    transform: translateX(-50%) rotate(45deg);
    border: 1px solid transparent;
    border-left-color: rgba(0, 0, 0, 0.05);
    border-top-color: rgba(0, 0, 0, 0.05);
  }
`
