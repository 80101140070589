import { RadioButton, RadioButtonExtendedProps } from 'grommet'
import styled, { css } from 'styled-components/macro'

import { Icon } from '../../icon'
import { Box } from '../../layout/'
import { ListItem } from '../../list'
import { focusRingOutlineCss, themeColor } from '../../theme'
import { Text } from '../../typography'

export type RadioboxProps = Omit<RadioButtonExtendedProps, 'children' | 'label' | 'name'> & {
  label?: string // should label be required?
  name?: string
}

/**
 * @deprecated used only in react-ui storybook, consider removing.
 */
export const Radiobox = ({ a11yTitle, label, disabled, name, ...props }: RadioboxProps) => {
  return (
    <RadioboxContainer>
      <RadioButton a11yTitle={a11yTitle} disabled={disabled} name={name ?? ''} {...props}>
        {({ checked }: { checked?: boolean }) => <RadioboxItem label={label} checked={checked} disabled={disabled} />}
      </RadioButton>
    </RadioboxContainer>
  )
}

type RadioboxItemProps = {
  label?: string
  disabled?: boolean
  checked?: boolean
  className?: string
  a11yTitle?: string
}

export const RadioboxItem = ({ label, disabled, checked, className, a11yTitle }: RadioboxItemProps) => {
  return (
    <RadioboxListItem
      checked={checked}
      disabled={disabled}
      startComponent={<RadioboxIcon checked={checked} disabled={disabled} />}
      className={className}
      tabIndex={-1}
      aria-label={a11yTitle ?? label}
    >
      <RadioboxText truncate checked={checked} disabled={disabled}>
        {label}
      </RadioboxText>
    </RadioboxListItem>
  )
}

const RadioboxIcon = styled(Icon).attrs((props: { checked?: boolean; disabled?: boolean }) => ({
  color: props.disabled ? 'text-disabled' : props.checked ? 'primary' : 'text-light',
  icon: props.checked ? 'radiobox-checked' : 'radiobox'
}))<{
  checked?: boolean
  disabled?: boolean
}>``

const RadioboxText = styled(Text).attrs((props: { checked: boolean; disabled?: boolean }) => ({
  color: props.disabled ? 'text-disabled' : props.checked ? 'text' : 'text-light'
}))<{ checked?: boolean; disabled?: boolean }>``

const RadioboxListItem = styled(ListItem).attrs(() => ({
  direction: 'row',
  align: 'center',
  size: 'small'
}))<{ checked?: boolean; disabled?: boolean }>`
  height: 32px;
  ${props =>
    !props.checked &&
    !props.disabled &&
    css`
      &:hover {
        ${RadioboxText} {
          color: ${themeColor('text')};
        }

        ${RadioboxIcon} {
          fill: ${themeColor('text')};
        }
      }
    `}
`

export const radioBoxFocusCss = css`
  input[type='radio']:focus-visible {
    // remove the global input focus styles
    box-shadow: none !important;
    // added specificity for Safari
    &[tabindex='0'] {
      opacity: 0 !important;
    }
    // grab the first sibling div of the Radiobox
    & + div {
      // use aria-labels to access the Radiobox icon and apply focus styles
      svg[aria-label='Radiobox'],
      svg[aria-label='RadioboxChecked'] {
        ${focusRingOutlineCss}
        border-radius: 50%;
        overflow: hidden;
        outline-offset: -1px !important;
      }
    }
  }
`

const RadioboxContainer = styled(Box)`
  ${radioBoxFocusCss}
`
