import { useCallback } from 'react'

import { useNotify } from '@cutover/react-ui'
import { RunbookCommentCreateResponse } from 'main/services/api/data-providers/runbook-types'
import { useCurrentUser } from 'main/recoil/current-user'
import { useLanguage } from 'main/services/hooks'

export const useCommentNotifications = () => {
  const { t } = useLanguage('notification')
  const notify = useNotify()
  const { id: currentUserId } = useCurrentUser()

  const commentCreateNotification = useCallback(
    (response: RunbookCommentCreateResponse) => {
      if (response.meta.headers.request_user_id === currentUserId) return

      let message
      const userName = response.meta.headers.request_user_name
      if (response.comment.task_id) {
        message = t('comment.created.userMessageTask', { userName, taskName: response.comment.task?.name })
      } else {
        message = t('comment.created.userMessageRunbook', { userName })
      }
      notify.success(message, { title: t('comment.created.title') })
    },
    [currentUserId]
  )

  return {
    commentCreateNotification
  }
}
