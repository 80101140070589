import { atom, useRecoilState, useSetRecoilState } from 'recoil'

import { runbookAtom } from 'main/recoil/shared/recoil-state-runbook-decorators'

export const taskCreateFromPredecessorIdState_INTERNAL = runbookAtom<number | undefined>({
  key: 'tasks:add:predecessor-id',
  default: undefined
})

export const taskCreateNameState = atom<string | undefined>({
  key: 'tasks:add:name',
  default: undefined
})

export const useToggleTaskCreateState = () => {
  const [fromPredecessorId, setFromPredecessorId] = useRecoilState(taskCreateFromPredecessorIdState_INTERNAL)

  const toggleFromPredecessorId = ({ fromPredecessorId }: { fromPredecessorId?: number }) =>
    setFromPredecessorId(prev => (prev === fromPredecessorId ? undefined : fromPredecessorId))

  return [fromPredecessorId, toggleFromPredecessorId] as [number | undefined, typeof toggleFromPredecessorId]
}

export const useSetTaskCreateState = () => {
  const setFromPredecessorId = useSetRecoilState(taskCreateFromPredecessorIdState_INTERNAL)

  const setFromPredecessor = ({ fromPredecessorId }: { fromPredecessorId?: number }) =>
    setFromPredecessorId(fromPredecessorId)

  return setFromPredecessor
}
