// @ts-nocheck
import 'reflect-metadata'
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { computed, action, observable, reaction } from 'mobx'
import { RunbookTypesViewPresenter } from './RunbookTypesViewPresenter'
import { RunbookTypeFormPresenter, IFormViewModel } from './RunbookTypeForm/RunbookTypeFormPresenter'
import { RunbookTypeFilterPresenter } from './RunbookTypeFilter/RunbookTypeFilterPresenter'
import { RunbookTypeListPresenter } from './RunbookTypeList/RunbookTypeListPresenter'
import { RunbookTypesPageHeadingPresenter } from './RunbookTypesPageHeading/RunbookTypesPageHeadingPresenter'
import { RunbookTypeEditPresenter } from './RunbookTypeEdit/RunbookTypeEditPresenter'
import { CurrentPagePresenter } from 'app/Routing/CurrentPage/CurrentPagePresenter'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { IRunbookTypeVM } from 'Shared/Entities/Types/RunbookType/IRunbookTypeVM'
import { Types } from 'Gateways/Service/Types'

@injectable()
export class RunbookTypesPagePresenter {
  @observable
  private runbookTypesViewPresenter: RunbookTypesViewPresenter = container.get(RunbookTypesViewPresenter)

  @observable
  public filterSectionPresenter: RunbookTypeFilterPresenter = container.get(RunbookTypeFilterPresenter)

  public language: ILanguageService = container.get(Types.ILanguageService)

  @observable
  public addFormPresenter: RunbookTypeFormPresenter = new RunbookTypeFormPresenter()

  @observable
  public editFormPresenter: RunbookTypeFormPresenter = new RunbookTypeFormPresenter().withDirtyCallback(() => {
    this.runbookTypesViewPresenter.pageVM.editPanelDirty = true
  })

  @observable
  public headingPresenter: RunbookTypesPageHeadingPresenter = new RunbookTypesPageHeadingPresenter()

  @observable
  public runbookTypeEditPresenter = new RunbookTypeEditPresenter()

  @observable
  public listPresenter: RunbookTypeListPresenter = new RunbookTypeListPresenter()

  constructor() {
    this.setupReactions()
  }

  @computed
  public get angularJSSettingsPanelOpenState(): boolean {
    return container.get(CurrentPagePresenter).angularJSSettingsPanelOpenState
  }

  private setupReactions = () => {
    if (Object.keys(this.runbookTypesViewPresenter.accountsVM).length === 0) {
      reaction(
        () => this.runbookTypesViewPresenter.accountsVM,
        (_accountsVm, reaction) => {
          this.setupFormPresenters()
          reaction.dispose()
        }
      )
    } else {
      this.setupFormPresenters()
    }
  }

  private setupFormPresenters = () => {
    this.addFormPresenter.withVisibilityAccounts(this.getAccountsByName())
    this.editFormPresenter.withVisibilityAccounts(this.getAccountsByName())
  }

  @computed
  public get listVM() {
    return this.runbookTypesViewPresenter.listVM
  }

  public listVMChangeFunction = () => {
    this.addFormPresenter.withUnavailableNames(
      this.runbookTypesViewPresenter.listVM.map(runbookType => runbookType.name)
    )
    this.editFormPresenter.withUnavailableNames(this.editUnavailableNames)
  }

  @computed
  public get runbookTypeVM() {
    return this.runbookTypesViewPresenter.runbookTypeVM
  }

  public runbookTypeVMChangeFunction = () => {
    if (this.runbookTypeVM) {
      this.setFormViewModel()
      this.editFormPresenter.withUnavailableNames(this.editUnavailableNames)
    }
  }

  @action
  public setAddFormViewModel = () => {
    this.addFormPresenter.withViewModel({
      name: '',
      description: '',
      disabled: false,
      disabledName: false,
      disabledCheckboxDisabled: false,
      disabledGlobal: !this.runbookTypesViewPresenter.can('global'),
      disabledAccount: false,
      dynamic: false,
      incident: false,
      availableApprovalFlows: []
    })
  }

  private isEditFormEditable = (runbook: IRunbookTypeVM): boolean => {
    return this.runbookTypesViewPresenter.can('update') && !runbook.archived
  }

  private isEditFormDisabled = (runbook: IRunbookTypeVM): boolean => {
    return runbook.default || !this.isEditFormEditable(runbook)
  }

  @action
  private setFormViewModel = () => {
    if (!this.runbookTypesViewPresenter.runbookTypeVM) return

    const runbookForEdit = this.runbookTypesViewPresenter.runbookTypeVM

    const formVm: IFormViewModel = {
      name: runbookForEdit.name,
      description: runbookForEdit.description,
      disabled: runbookForEdit.disabled,
      disabledName: this.isEditFormDisabled(runbookForEdit),
      disabledCheckboxDisabled: !this.isEditFormEditable(runbookForEdit),
      disabledGlobal: this.isEditFormDisabled(runbookForEdit) || !this.runbookTypesViewPresenter.can('global'),
      disabledDynamic: !this.runbookTypesViewPresenter.can('global'),
      disabledAccount:
        this.isEditFormDisabled(runbookForEdit) || Object.keys(this.runbookTypesViewPresenter.accountsVM).length === 1,
      dynamic: runbookForEdit.dynamic,
      incident: runbookForEdit.incident,
      approvalFlowId: runbookForEdit.approvalFlowId,
      availableApprovalFlows: runbookForEdit.availableApprovalFlows ?? []
    }

    if (runbookForEdit.accountId && runbookForEdit.visibilityType !== 'global') {
      formVm.account = this.runbookTypesViewPresenter.accountsVM[runbookForEdit.accountId]
    }
    this.editFormPresenter.withViewModel(formVm)
  }

  @computed
  private get editUnavailableNames(): string[] {
    return this.runbookTypesViewPresenter.listVM.map(runbookType => {
      if (!this.runbookTypesViewPresenter.runbookTypeVM) return runbookType.name
      if (this.runbookTypesViewPresenter.runbookTypeVM.name !== runbookType.name) return runbookType.name
    })
  }

  @computed
  public get canCreateRunbookType(): boolean {
    return this.runbookTypesViewPresenter.can('create')
  }

  private getAccountsByName = (): string[] => {
    return Object.values(this.runbookTypesViewPresenter.accountsVM)
  }

  @computed
  public get editOpen(): boolean {
    return this.runbookTypesViewPresenter.pageVM.editPanelOpen
  }

  public setEditClosed = () => {
    this.runbookTypesViewPresenter.setPageVMValue('editPanelOpen', false)
  }

  @computed
  public get editLoading(): boolean {
    return this.runbookTypesViewPresenter.pageVM.editPanelLoading
  }

  @computed
  public get filterPanelLoading(): boolean {
    return this.runbookTypesViewPresenter.pageVM.filterPanelLoading
  }

  @computed
  public get loading(): boolean {
    return this.runbookTypesViewPresenter.pageVM.filterPanelLoading || this.runbookTypesViewPresenter.loading
  }

  @computed
  public get archiveModalOpenState(): boolean {
    return this.runbookTypesViewPresenter.pageVM.archiveModalOpenState
  }

  @action
  public resetSubmit = () => {
    this.runbookTypesViewPresenter.submitted = false
  }

  public clearEditPanel = () => {
    this.runbookTypesViewPresenter.clearEditPanel()
  }

  public checkEditPanelIsDirty = (): boolean => {
    return this.runbookTypesViewPresenter.checkEditPanelIsDirty()
  }
}
