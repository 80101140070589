// @ts-nocheck
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { IncidentSeveritiesPagePresenter } from './IncidentSeveritiesPagePresenter'
import { AddButton } from 'Components/Atoms'
import { fluidPage } from 'Shared/HooksAndPresenters/FluidPage'
import { IncidentSeveritiesPageModals } from './IncidentSeveritiesPageModals'
import {
  HeaderPanelWrapper,
  PageWrapper,
  PageWrapperInner,
  CenterMainWrapper,
  MainPanelWrapper,
  GenericList,
  HeadingTitle,
  EditPanelWrapper
} from 'Components/Templates'
import { px2rem } from 'app/Styles/Variables'
import { IncidentSeveritiesEditSection } from './IncidentSeverityEdit/IncidentSeverityEditSection'

const HeaderWrapperInner = styled.div`
  padding-left: ${px2rem(18)};
  padding-right: ${px2rem(6)};
`

interface IIncidentSeveritiesPageProps {
  presenter: IncidentSeveritiesPagePresenter
}

const IncidentSeveritiesPage = observer<IIncidentSeveritiesPageProps>(props => {
  const {
    addFormPresenter,
    angularJSSettingsPanelOpenState,
    checkEditPanelIsDirty,
    clearEditPanel,
    editFormPresenter,
    editPanelLoading,
    editPanelOpen,
    incidentSeveritiesListPresenter,
    incidentSeveritiesTotal,
    incidentSeverityEditPresenter,
    incidentSeverityVM,
    incidentSeverityVMChange,
    language,
    loading,
    resetSubmit,
    setAddFormViewModel,
    setEditClosed
  } = props.presenter

  const [incidentSeverityAddModalOpenState, setIncidentSeverityAddModalOpenState] = useState(false)
  const { showAddButton } = fluidPage(
    editPanelOpen,
    setEditClosed,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState
  )

  const addModalCallback = (modalOpen: boolean) => {
    resetSubmit()
    setIncidentSeverityAddModalOpenState(modalOpen)
  }

  useEffect(incidentSeverityVMChange, [incidentSeverityVM])

  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <HeaderWrapperInner>
            <HeadingTitle
              title={language.get('incidentSeverities:heading:title:default')}
              count={incidentSeveritiesTotal && `(${incidentSeveritiesTotal})`}
            />
          </HeaderWrapperInner>
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <MainPanelWrapper extraStyles={{ zIndex: 1 }} loading={loading}>
            <GenericList listPresenter={incidentSeveritiesListPresenter} />
            {showAddButton && (
              <AddButton
                action={() => {
                  setIncidentSeverityAddModalOpenState(true)
                }}
                language={language}
              />
            )}
            <IncidentSeveritiesPageModals
              addFormPresenter={addFormPresenter}
              addModalOpenState={incidentSeverityAddModalOpenState}
              language={language}
              setAddModalOpenState={addModalCallback}
              setAddFormViewModel={setAddFormViewModel}
            />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      <EditPanelWrapper
        isOpen={editPanelOpen}
        loading={editPanelLoading}
        panelCloseCallback={() => {
          clearEditPanel()
        }}
      >
        <IncidentSeveritiesEditSection
          presenter={incidentSeverityEditPresenter}
          editFormPresenter={editFormPresenter}
        />
      </EditPanelWrapper>
    </PageWrapper>
  )
})

export default withPresenter(IncidentSeveritiesPagePresenter)(IncidentSeveritiesPage)
