import { useMutation, useQuery, useQueryClient } from 'react-query'

import { GetAccountResponseType } from '../api/data-providers/account/use-get-account-data'
import { apiClient, ApiError } from 'main/services/api/api-client'
import { QueryKeys } from 'main/services/queries/query-keys'
import { CustomField, FieldOption } from 'main/services/queries/types'

export function useCustomFieldsQuery() {
  return useQuery<CustomField[], Error>([QueryKeys.CustomFields], async () => {
    const { data } = await apiClient.get<CustomField[]>({
      url: 'custom_fields',
      responseProperty: 'custom_fields',
      convertCase: true
    })

    return data
  })
}

type FieldOptionResponse = {
  field_option: FieldOption
}

export type FieldOptionPayload = {
  account_slug?: string
  field_option: {
    custom_field_id: number
    name: string
  }
}

// There's no need for optimistic update so we're just udpating the cache upon a successful response.
// Instead of updating the cache, we should consider invalidating the query so that updates to custom field options are available elsewhere, such as in filters.
export function useFieldOptionsMutation() {
  const queryClient = useQueryClient()

  return useMutation<FieldOptionResponse, Error, FieldOptionPayload>(async (payload: FieldOptionPayload) => {
    const { data } = await apiClient.post<Omit<FieldOptionPayload, 'account_slug'>, FieldOptionResponse>({
      url: 'field_options',
      data: { field_option: payload.field_option }
    })

    const previousAccountData = queryClient.getQueryData<GetAccountResponseType>([
      QueryKeys.Accounts,
      'subdomain',
      payload.account_slug
    ])
    queryClient.setQueryData([QueryKeys.Accounts, 'subdomain', payload.account_slug], {
      ...previousAccountData,
      meta: {
        ...previousAccountData?.meta,
        custom_fields: previousAccountData?.meta?.custom_fields.map(cf => {
          if (cf.id === payload.field_option.custom_field_id && data?.field_option) {
            cf.field_options.push(data.field_option)
          }
          return cf
        })
      }
    })

    return data as FieldOptionResponse
  })
}

export function useCustomFieldCreate() {
  const queryClient = useQueryClient()

  // what is a mutation key and should it match query keys?
  return useMutation<CustomField, ApiError, any>(
    [QueryKeys.CustomField],
    async values => {
      const { data } = await apiClient.post<any, CustomField>({
        url: 'custom_fields',
        data: values,
        requestProperty: 'custom_field',
        responseProperty: 'custom_field'
      })

      return data as CustomField
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CustomField])
      }
    }
  )
}
