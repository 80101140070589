import { useEffect } from 'react'
import { saveAs } from 'file-saver'
import { Anchor } from 'grommet'
import { useFormContext } from 'react-hook-form'
import {
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE as useRecoilTransaction,
  useRecoilValue,
  useResetRecoilState
} from 'recoil'
import * as yup from 'yup'
// @ts-ignore

import { Box, Message, Text, useInitialMount, useNotify } from '@cutover/react-ui'
import { ApiResponse, downloadRunbookCSVTemplate, importTasksCsv } from './import-tasks-csv'
import { CheckboxField, FileInputField, FormModal, TimezoneSelectField } from 'main/components/shared/form'
import {
  csvImportErrorsState,
  csvImportModalStatus,
  csvImportSubmittedValuesState,
  runbookIdState,
  runbookVersionIdState
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { getClientIANATimezone } from 'main/services/timezone-util'

const validationSchema = yup.object().shape({
  file: yup.mixed().required('You must choose a file'),
  timezone: yup.string().required(),
  overwrite: yup.bool()
})

export type CsvImportForm = yup.InferType<typeof validationSchema>

type CsvImportFormProps = {
  open: boolean
  closeModal: () => void
  planningMode: boolean
  resourceType: string
}

export const CsvImportModal = ({ open, planningMode, resourceType, closeModal }: CsvImportFormProps) => {
  const { t } = useLanguage('runbook')
  const notify = useNotify()
  const timezone = getClientIANATimezone()
  const modalStatus = useRecoilValue(csvImportModalStatus)
  const errorState = useRecoilValue(csvImportErrorsState)

  const clearState = useRecoilTransaction(({ reset }) => () => {
    reset(csvImportSubmittedValuesState)
    reset(csvImportModalStatus)
    reset(csvImportErrorsState)

    closeModal()
  })

  const clickSubmit = useRecoilCallback(
    ({ snapshot, set }) =>
      async (data: CsvImportForm) => {
        const formData = new FormData()
        if (data) {
          formData.append('file', data.file as File)
          formData.append('timezone', data.timezone)
          formData.append('overwrite', JSON.stringify(data.overwrite))
          const runbookId = await snapshot.getPromise(runbookIdState)
          const runbookVersionId = await snapshot.getPromise(runbookVersionIdState)

          set(csvImportModalStatus, 'submitting')
          return await importTasksCsv({ formData, runbookId, runbookVersionId })
        }
      },
    []
  )

  useEffect(() => {
    if (modalStatus === 'success') {
      notify.success(t('csvImportModal.successMessage'))
      clearState()
    }
  }, [modalStatus])

  const downloadTemplate = async () => {
    try {
      const csvTemplate: ApiResponse = await downloadRunbookCSVTemplate()
      const file = new Blob([csvTemplate.data], { type: 'text/csv' })
      const fileName = 'RunbookTemplate.csv'
      saveAs(file, fileName)
      notify.success(t('csvImportModal.templateDownloadSuccess'))
    } catch (error) {
      notify.error(t('csvImportModal.templateDownloadError'))
    }
  }

  return (
    <FormModal<CsvImportForm>
      title={t('csvImportModal.title')}
      confirmText="Import"
      confirmIcon="save"
      open={open}
      onClose={() => clearState()}
      onSubmit={clickSubmit}
      reValidateMode="onSubmit"
      schema={validationSchema}
      preventAutoClose={true}
      customErrors={errorState}
      description={
        <Text>
          {t('csvImportModal.description')}{' '}
          <Anchor href={t('csvImportModal.learnMoreLink')} label={t('csvImportModal.learnMoreText')} />
          <br />
          <br />
          <Anchor
            tabIndex={0}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                downloadTemplate()
              }
            }}
            onClick={downloadTemplate}
          >
            {t('csvImportModal.downloadTemplate')}
          </Anchor>
        </Text>
      }
      defaultValues={{
        file: undefined,
        timezone: timezone,
        overwrite: false
      }}
    >
      <CsvImportFormFields showCheckbox={planningMode} resourceType={resourceType} />
    </FormModal>
  )
}
type CsvImportFormFieldsProps = {
  showCheckbox: boolean
  resourceType: string
  timezone?: any
}

const CsvImportFormFields = ({ showCheckbox, resourceType }: CsvImportFormFieldsProps) => {
  const isFirstMount = useInitialMount()
  const { t } = useLanguage('runbook')
  const { watch, reset } = useFormContext()
  const resetErrorsState = useResetRecoilState(csvImportErrorsState)
  const status = useRecoilValue(csvImportModalStatus)
  const isSnippet = resourceType === 'Snippet'
  const fileInputValue = watch('file')

  useEffect(() => {
    if (isFirstMount) return
    reset({ file: fileInputValue })
    resetErrorsState()
  }, [fileInputValue])

  return (
    <>
      <Box gap="medium">
        {isSnippet && <Message message={t('csvImportModal.snippetInfo')} type="info" />}
        <FileInputField<CsvImportForm> name="file" disabled={status === 'submitting'} accept="text/csv" />
        <TimezoneSelectField<CsvImportForm>
          name="timezone"
          css="width: 100%"
          label={t('csvImportModal.timezoneLabel', { resourceType: resourceType.toLowerCase() })}
          disabled={status === 'submitting'}
          customFirstOption={null}
          required
        />
      </Box>
      {showCheckbox && (
        <CheckboxField<CsvImportForm>
          name="overwrite"
          disabled={status === 'submitting'}
          label={t('csvImportModal.overwriteLabel')}
        />
      )}
    </>
  )
}
