import { useRef } from 'react'
import * as yup from 'yup'

import { FormModal, TextInputField } from 'main/components/shared/form'
import { useAccountId, useRunbookId, useRunbookVersionId } from 'main/recoil/runbook'
import { useAppliedFilters } from 'main/recoil/shared/filters'
import { useLanguage } from 'main/services/hooks'
import { useCreateFilter } from 'main/services/queries/use-filters'
import { useSavedFiltersActions } from 'main/recoil/runbook/models/runbook/saved-filters'
import { FormType } from 'main/components/shared/form/form'
import { SavedFilter } from 'main/services/api/data-providers/runbook-types'

type SaveFilterModalForm = {
  name: string
  resource_type: string
  resource_id: number
}

export const SaveFilterModal = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'subHeader.saveFilterModal' })
  const formRef = useRef<FormType<SaveFilterModalForm>>(null)
  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()
  const accountId = useAccountId()
  const filters = useAppliedFilters()

  const saveFilter = useCreateFilter().mutateAsync
  const { addSavedFilter } = useSavedFiltersActions()

  return (
    <FormModal<SaveFilterModalForm>
      title={t('title')}
      formRef={formRef}
      open
      onClose={closeModal}
      confirmText={t('confirmText')}
      confirmIcon="add"
      successMessage={t('successMessage')}
      description={t('description')}
      defaultValues={{
        name: '',
        resource_type: 'Runbook',
        resource_id: runbookId
      }}
      onSubmit={async data => {
        const queryString = JSON.stringify({ accountId, runbookId, runbookVersionId, ...filters })
        const { filter } = await saveFilter({ filter: { ...data, query_string: queryString } })
        await addSavedFilter(filter as SavedFilter)
      }}
      schema={yup.object({
        name: yup.string().required(),
        resource_type: yup.string().required(),
        resource_id: yup.number().required()
      })}
    >
      <TextInputField
        onKeyDownCapture={event => {
          if (event.key === 'Enter') {
            formRef.current?.submit()
          }
        }}
        autoFocus
        name="name"
        label={t('filterNameFieldLabel')}
      />
    </FormModal>
  )
}
