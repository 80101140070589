import { useMemo } from 'react'
import { Meter, Stack } from 'grommet'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { Box, Button, ColorProp, Icon, IconName, resolveColor, themeColor, useTheme } from '@cutover/react-ui'
import { PauseRunbookModal, ResumeRunbookModal, StartRunbookModal } from 'main/components/runbook/modals/run-modals'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import {
  runActionState,
  useRunbookCurrentVersionProperty,
  useRunbookProperty,
  useRunbookRunbookType,
  useRunbookVersionProperty,
  useRunsPermission
} from 'main/recoil/runbook'
import { TaskListTask } from 'main/services/queries/types'

const CONTROL_ICONS: { [stage: string]: IconName } = {
  planning: 'play-arrow',
  active: 'pause',
  paused: 'play-arrow',
  complete: 'check-solid',
  cancelled: 'close'
}

const COMMS_ICONS: { [comms: string]: IconName } = {
  off: 'volume-mute',
  on: 'volume-high',
  test: 'volume-low'
}

const COMMS_ICON_COLORS: { [comms: string]: ColorProp } = {
  off: 'text-light',
  on: 'success',
  test: 'warning'
}

export type RunModalAction = 'show-start' | 'show-pause' | 'show-resume'

export const RunbookControl = () => {
  const theme = useTheme()
  const runbookType = useRunbookRunbookType()
  const { closeRightPanel } = useSetActiveRightPanelState()

  const runbookId = useRunbookProperty({ attribute: 'id' })
  const templateType = useRunbookProperty({ attribute: 'template_type' })
  const linkedRunbookDetails = useRunbookProperty({ attribute: 'linked_runbook_details' })
  const timezone = useRunbookProperty({ attribute: 'timezone' })

  const stage = useRunbookVersionProperty({ attribute: 'stage' })
  const runbookVersionId = useRunbookVersionProperty({ attribute: 'id' })
  const auto_start = useRunbookVersionProperty({ attribute: 'auto_start' })
  const run = useRunbookVersionProperty({ attribute: 'run' })
  const completed_tasks_count = useRunbookVersionProperty({ attribute: 'completed_tasks_count' })
  const tasks_count = useRunbookVersionProperty({ attribute: 'tasks_count' })

  const start_scheduled = useRunbookCurrentVersionProperty({ attribute: 'start_scheduled' })
  const timing_mode = useRunbookCurrentVersionProperty({ attribute: 'timing_mode' })
  const progressValue = completed_tasks_count ? (completed_tasks_count / tasks_count) * 100 : 0

  const tasks = [] as TaskListTask[] // TODO: use recoil state for this

  const isTemplate = templateType === 'default'
  const [runAction, setRunAction] = useRecoilState<RunModalAction | null>(runActionState)

  const canCreateRun = useRunsPermission({ attribute: 'create' })
  const canPauseRun = useRunsPermission({ attribute: 'pause' })
  const canResumeRun = useRunsPermission({ attribute: 'resume' })

  const comms = run?.comms

  const handleIconClick = () => {
    closeRightPanel()
    switch (stage) {
      case 'planning':
        return setRunAction('show-start')
      case 'active':
        return setRunAction('show-pause')
      case 'paused':
        return setRunAction('show-resume')
    }
  }

  const handleCommsClick = () => {
    console.log('open edit comms modal')
  }

  const runModal = useMemo(() => {
    // const isParentRunbook = tasks.find(task => task.linked_resource && task.linked_resource.id)
    const isParentRunbook = false // TODO: use recoil state for this

    switch (runAction) {
      case 'show-start':
        const hasParentRunbook = Object.keys(linkedRunbookDetails || {}).length !== 0
        const isLiveRunInParentRunbook = hasParentRunbook && linkedRunbookDetails?.linked_resource?.run_type === 'live'

        return (
          <StartRunbookModal
            onClose={() => setRunAction(null)}
            runbookVersionId={runbookVersionId}
            runbookId={runbookId}
            templateType={templateType}
            hasParentRunbook={hasParentRunbook}
            isLiveRunInParentRunbook={isLiveRunInParentRunbook}
            isScheduled={timing_mode === 'scheduled'}
            autoStart={auto_start}
            startScheduled={start_scheduled}
            timezone={null} // TODO: timezone is handled as a TimezoneType in modal but is a string here
          />
        )
      case 'show-pause':
        return (
          <>
            {run && (
              <PauseRunbookModal
                onClose={() => setRunAction(null)}
                runbookId={runbookId}
                runbookVersionId={runbookVersionId}
                runId={run.id}
                templateType={templateType}
                isParentRunbook={isParentRunbook}
                runType={run.run_type}
                isRunCommsOff={run.comms === 'off'}
              />
            )}
          </>
        )
      case 'show-resume':
        return (
          <>
            {run && (
              <ResumeRunbookModal
                onClose={() => setRunAction(null)}
                runId={run.id}
                runbookVersionId={runbookVersionId}
                runbookId={runbookId}
                templateType={templateType}
                isParentRunbook={isParentRunbook}
                runType={run.run_type}
                isRunCommsOff={run.comms === 'off'}
              />
            )}
          </>
        )
      default:
        return null
    }
  }, [runAction, runbookId, runbookVersionId, templateType, linkedRunbookDetails, runbookType, timezone, tasks])

  const showControl = () => {
    if (runbookVersionId && !isTemplate && !runbookType?.incident) {
      if (
        (stage === 'planning' && canCreateRun && tasks_count > 0 && !linkedRunbookDetails?.linked_resource) ||
        (stage === 'active' && canPauseRun) ||
        (stage === 'paused' && canResumeRun) ||
        stage === 'cancelled' ||
        (stage === 'complete' && !linkedRunbookDetails?.linked_resource)
      ) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Stack anchor="center">
        {stage === 'planning' ? (
          <DashedCircle />
        ) : (
          <Meter
            type="circle"
            background="bg-4"
            size="52px"
            css={{ minWidth: '52px', maxWidth: '52px' }}
            thickness="5px"
            values={[{ value: progressValue, color: 'primary' }]}
          />
        )}
        {stage && showControl() ? (
          <Box direction="row" align="center" pad={{ bottom: 'xxsmall' }}>
            {stage === 'cancelled' ? (
              <Icon icon="close" size="43px" color="bg-4" aria-hidden="true" aria-label="icon-cancelled" />
            ) : (
              <Button
                data-testid={`runbook-control-${stage}-button`}
                plain
                icon={CONTROL_ICONS[stage]}
                onClick={handleIconClick}
                css={`
                  border-radius: 50%;
                  padding: 5px;
                  cursor: pointer;
                  svg {
                    fill: ${run?.run_type === 'rehearsal'
                      ? resolveColor('bg-3', theme)
                      : resolveColor('text-light', theme)};
                  }
                  &:hover {
                    background-color: ${resolveColor('bg', theme)};
                    svg {
                      fill: ${resolveColor('text', theme)};
                    }
                  }
                `}
              />
            )}
          </Box>
        ) : null}
        {comms && (
          <Button
            plain
            icon={COMMS_ICONS[comms]}
            size="small"
            onClick={handleCommsClick}
            css={`
              position: absolute;
              top: 6px;
              left: 6px;
              background: ${resolveColor('bg', theme)};
              border-radius: 50%;
              svg {
                width: 20px;
                height: 20px;
                fill: ${resolveColor(COMMS_ICON_COLORS[comms], theme)};
              }
              &:hover {
                background: ${resolveColor('bg', theme)};
                svg {
                  fill: ${resolveColor('primary', theme)};
                }
              }
            `}
          />
        )}
      </Stack>
      {runModal}
    </>
  )
}

const DashedCircle = styled(Box)`
  width: 52px;
  height: 52px;
  border: 4px dashed ${themeColor('bg-3')};
  border-radius: 50%;
  position: relative;
  top: -3px;
`
