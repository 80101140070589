import { atom, selector, selectorFamily, useRecoilValue } from 'recoil'

import { toSnakeCase } from '@cutover/api'
import { GlobalConfigType } from 'main/services/hooks'
import { fetchConfigQuery } from 'main/services/queries/use-get-configs'

const DEFAULT_TASK_LEVEL_ID = 'level_3'

export const globalConfig = atom({
  key: 'config',
  default: selector({
    key: 'config:default',
    get: async () => {
      const config = await fetchConfigQuery()
      return {
        ...config,
        integrations: config.enabledFeatures.includes('conditional_logic')
          ? config.integrations
          : config.integrations.filter(i => i.klass !== 'Integrations::ConditionalLogic::ConditionalLogic')
      }
    }
  })
})

export const globalConfigProperty = selectorFamily<
  GlobalConfigType[keyof GlobalConfigType],
  { attribute: keyof GlobalConfigType }
>({
  key: 'config:property',
  get:
    ({ attribute }) =>
    ({ get }) => {
      return get(globalConfig)[attribute]
    }
})

export const useGlobalTaskLevelOrDefault = (id = DEFAULT_TASK_LEVEL_ID) => {
  const taskLevels = useRecoilValue(globalConfigProperty({ attribute: 'taskLevels' })) as GlobalConfigType['taskLevels']
  // eslint-disable-next-line
  return taskLevels.find(level => level.id === id)! // task level always exists
}

// Using this to build filter labels (checking that the exist in configs)
export const convertConfigObjectKeysToSnakeCase = (originalObject: any) => {
  const snakeKeysConfig = {} as Record<string, any>
  for (const camelKey in originalObject) {
    snakeKeysConfig[toSnakeCase(camelKey)] = originalObject[camelKey]
  }
  return snakeKeysConfig
}
