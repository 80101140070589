import { memo, ReactNode, useEffect } from 'react'
import { eventManager } from 'event-manager'
import { useMount, useUnmount } from 'react-use'

import { StreamEditPanel, TaskEditPanel } from './right-panels'
import { UserOrTeamDetailsPanel } from './right-panels/user-team-details-panel'
import { RunbookFilter } from './runbook-filter/runbook-filter'
import { RunbookHeader } from './runbook-header/runbook-header'
import { RunbookRightNavigation } from './runbook-right-navigation/runbook-right-navigation'
import { RunbookSubHeader } from './runbook-sub-header/runbook-sub-header'
import { RunbookBanner } from '../layout/runbook-banner'
import { Layout } from 'main/components/layout'
import { RunbookCopiesPanel } from '../workspace/right-panels'
import { RunbookEditPanelTaskList } from './right-panels/runbook-edit-panel-task-list'
import { useDefaultLayout } from 'main/components/layout/layout-hooks'
import {
  useActiveRightPanelValueType,
  useCloseRightPanelOnMount,
  useSetActiveRightPanelState
} from 'main/components/layout/right-panel'
import { AiSuggestionsPanel } from './right-panels/ai-suggestions/ai-suggestions-panel'
import { CommentsPanel } from './right-panels/comments-panel/comments-panel'

export const RunbookLayout = memo(({ children }: { children?: ReactNode }) => {
  const { closeRightPanel } = useSetActiveRightPanelState()
  useCloseRightPanelOnMount()

  useDefaultLayout({
    filterPanel: true,
    subHeader: true,
    rightNav: true
  })

  useMount(() => {
    closeRightPanel()
    eventManager.emit('close-angular-right-panel')
  })

  useUnmount(() => {
    closeRightPanel()
  })

  useEffect(() => {
    const refreshRunbookPageData = () => {
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook' } }))
      window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'tasks' } }))
    }

    eventManager.on('refresh-runbook-page-data', refreshRunbookPageData)

    return () => {
      eventManager.off('refresh-runbook-page-data', refreshRunbookPageData)
    }
  }, [])

  return (
    <Layout
      header={<RunbookHeader />}
      subHeader={<RunbookSubHeader />}
      banner={<RunbookBanner />}
      filter={<RunbookFilter />}
      rightNavigation={<RunbookRightNavigation />}
      rightPanels={<RightPanels />}
    >
      {children}
    </Layout>
  )
})

const RightPanels = memo(() => {
  const panelType = useActiveRightPanelValueType()

  switch (panelType) {
    case 'stream-edit':
      return <StreamEditPanel />
    case 'task-edit':
      return <TaskEditPanel />
    case 'runbook-edit':
      return <RunbookEditPanelTaskList />
    case 'runbook-copies':
      return <RunbookCopiesPanel />
    case 'user-team-details-edit':
      return <UserOrTeamDetailsPanel />
    case 'ai-suggestions-list':
      return <AiSuggestionsPanel />
    case 'runbook-comments':
      return <CommentsPanel />
    default:
      return null
  }
})
