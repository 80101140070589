import { useEffect } from 'react'
import { eventManager } from 'event-manager'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'
import { useSetRecoilState } from 'recoil'

import { Favorite } from './types'
import { useFeature } from '../hooks'
import { favoritesState } from 'main/recoil/runbook'
import { apiClient } from 'main/services/api/api-client'
import { QueryKeys } from 'main/services/queries/query-keys'

export function useFavorites() {
  const queryClient = useQueryClient()

  useEffect(() => {
    const invalidateFavoritesQuery = () => {
      queryClient.invalidateQueries([QueryKeys.Favorites])
    }
    const updateFavoritesFromAngularAction = ({ favorite }: { favorite: Favorite }) => {
      optimisticallyUpdateFavorites({
        queryClient,
        payload: favorite
      })
    }
    eventManager.on('trigger-react-refetch-favorites', invalidateFavoritesQuery)
    eventManager.on('angular-toggle-favorite-runbook', updateFavoritesFromAngularAction)

    return () => {
      eventManager.off('trigger-react-refetch-favorites', invalidateFavoritesQuery)
      eventManager.off('angular-toggle-favorite-runbook', updateFavoritesFromAngularAction)
    }
  }, [])

  return useQuery<Favorite[], Error>([QueryKeys.Favorites], async () => {
    const { data } = await apiClient.get<Favorite[]>({
      url: 'favorites',
      responseProperty: 'runbooks'
    })

    return data
  })
}

type ToggleFavoriteRequestPayload = {
  favorable_type: string
  favorable_id: string | number
}

export const useToggleFavorite = (toggle?: 'on' | 'off') => {
  const queryClient = useQueryClient()
  const { isEnabled } = useFeature()
  const setRecoilFavorites = useSetRecoilState(favoritesState)
  return useMutation<Favorite, Error, string | number | Favorite>(
    'favorites/toggle_favorite',
    async payload => {
      const { data } = await apiClient.patch<ToggleFavoriteRequestPayload, Favorite>({
        url: 'favorites/toggle_favorite',
        data: {
          favorable_type: 'Runbook',
          favorable_id: typeof payload === 'number' || typeof payload === 'string' ? payload : payload.id
        }
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return data!
    },
    {
      onMutate: payload => {
        if (isEnabled('react_runbook')) {
          const newFavorites = optimisticallyUpdateFavorites({ queryClient, payload, toggle })
          // setting the recoil state here so that the side nav and the header favorite icon are synced
          setRecoilFavorites(newFavorites)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.Favorites])
        eventManager.emit('trigger-angular-refetch-favorites')
      }
    }
  )
}

const isFavoritePayload = (payload: string | number | Favorite): payload is Favorite => {
  return typeof payload === 'object' && 'id' in payload
}

export function optimisticallyUpdateFavorites({
  queryClient,
  payload,
  toggle: toggleDirection
}: {
  queryClient: QueryClient
  payload: string | number | Favorite
  toggle?: 'on' | 'off'
}) {
  const id = isFavoritePayload(payload) ? payload.id : payload
  const existingFavorites = queryClient.getQueryData<Favorite[] | undefined>([QueryKeys.Favorites])
  const toggle = toggleDirection ?? (existingFavorites ?? []).some(favorite => favorite.id === id) ? 'off' : 'on'
  let newFavorites: Favorite[] | undefined = existingFavorites

  if (toggle === 'on') {
    if (isFavoritePayload(payload)) {
      newFavorites = [...(existingFavorites ?? []), payload]
    }
  } else {
    const id = isFavoritePayload(payload) ? payload.id : payload
    newFavorites = existingFavorites?.filter(favorite => String(favorite.id) !== String(id))
  }

  queryClient.setQueryData([QueryKeys.Favorites], newFavorites)
  return newFavorites
}
