import { DragEvent, useState } from 'react'
import { useRecoilCallback } from 'recoil'
import { produce } from 'immer'

import { RunbookTeam } from 'main/services/queries/types'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { quickUpdateTask, QuickUpdateTaskPayload } from 'main/services/queries/use-task'
import { runbookIdState } from '../runbook/runbook'
import { runbookVersionIdState } from '../runbook-version/runbook-version'
import { taskListResponseState_INTERNAL, taskListTaskState } from './task-list'

type DragUserTeamData = {
  type: 'runbook_team' | 'user'
  id: number
}

export const useDropAssignment = ({
  canUpdateTask,
  teams,
  users
}: {
  canUpdateTask: boolean
  teams: RunbookTeam[]
  users: RunbookVersionUser[]
}) => {
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false)

  const isDropPermitted = (e: DragEvent) => {
    if (!e.dataTransfer || !e.dataTransfer.getData('text')) {
      return false
    }

    if (!canUpdateTask) {
      return false
    }

    const transferData = JSON.parse(e.dataTransfer.getData('text'))

    // Check if transfer is redundant (users/teams already exist)
    if (
      (transferData.type === 'runbook_team' && !!teams.find(team => team.id === transferData.id)) ||
      (transferData.type === 'user' && !!users.find(user => user.id === transferData.id))
    ) {
      return false
    }

    return true
  }

  const handleDropAssign = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ taskId, data }: { taskId: number; data: DragUserTeamData }) => {
        const runbookId = await snapshot.getPromise(runbookIdState)
        const runbookVersionId = await snapshot.getPromise(runbookVersionIdState)
        const task = await snapshot.getPromise(taskListTaskState(taskId))

        setIsLoadingAvatar(true)

        const payload = {} as QuickUpdateTaskPayload
        if (data.type === 'runbook_team') {
          payload.runbook_teams = [Number(data.id), ...task.runbook_team_ids]
        } else if (data.type === 'user') {
          payload.users = [Number(data.id), ...task.user_ids]
        }

        const response = await quickUpdateTask({ runbookId, runbookVersionId, taskId, payload })

        set(taskListResponseState_INTERNAL, prevTasksResponse =>
          produce(prevTasksResponse, draftTasksResponse => {
            const task = draftTasksResponse.tasks.find(t => t.id === taskId)
            if (response && task) {
              task.user_ids = response.task.user_ids
              task.runbook_team_ids = response.task.runbook_team_ids
            }
          })
        )

        setIsLoadingAvatar(false)
      }
  )

  return {
    handleDropAssign,
    isLoadingAvatar,
    isDropPermitted
  }
}
