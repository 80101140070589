import { ReactNode } from 'react'
import { HeadingProps } from 'grommet'
import styled from 'styled-components/macro'

import { Box } from '../../layout'
import { Text } from '../../typography'

type PropsOf<TComponent> = TComponent extends React.ComponentType<infer P> ? P : never
type TextProps = PropsOf<typeof Text>

const ListItemTextComponent = ({
  heading,
  headingLevel,
  subheading,
  subheadingLevel,
  className,
  ...props
}: TextProps & {
  className?: string
  heading?: ReactNode
  headingLevel?: HeadingProps['level']
  subheading?: ReactNode
  subheadingLevel?: HeadingProps['level']
}) => {
  if (props.children) {
    return (
      <Text truncate="tip" {...props} className={className}>
        {props.children}
      </Text>
    )
  } else {
    return (
      <Box className={className}>
        {heading && (
          <Text weight={500} tag={headingLevel && `h${headingLevel}`}>
            {heading}
          </Text>
        )}
        {subheading && (
          <Text color="text-light" size="small" tag={subheadingLevel && `h${subheadingLevel}`}>
            {subheading}
          </Text>
        )}
      </Box>
    )
  }
}

export const ListItemText = styled(ListItemTextComponent)``
