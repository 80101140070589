import { usePagedList } from '@cutover/react-ui'
import { apiClient } from 'main/services/api/api-client'
import { UserStatus } from 'main/services/queries/types'

export function getUserStatus(user: { archived: boolean; notInAccount: boolean; status: string }): UserStatus {
  if (user.archived) {
    return 'archived'
  }

  if (user.notInAccount) {
    return 'not_in_account'
  }

  if (user.status === 'invited') {
    return 'invited'
  }

  return 'active'
}

export type UsersQuery = {
  accountId: number
}

export type UserSummary = {
  id: number
  name: string
  color: string
  status: UserStatus
  online: boolean | null
}

export type UserSummaryApi = {
  id: number
  name: string
  handle: string
  online: boolean
  color: string
  archived?: boolean
  notInAccount?: boolean
  status: string
}

export function useUsersQuery({ accountId }: UsersQuery) {
  return usePagedList<UserSummary>({
    load: async ({ filterText }) => {
      const { data } = await apiClient.get<UserSummaryApi[]>({
        url: 'users/list',
        responseProperty: 'users',
        params: {
          account_id: accountId,
          query: filterText
        },
        convertCase: true
      })

      return {
        items: data.map(u => ({
          id: u.id,
          name: u.name,
          color: u.color,
          online: u.online,
          status: getUserStatus({
            archived: u.archived ?? false,
            notInAccount: u.notInAccount ?? false,
            status: u.status
          })
        })),
        cursor: undefined
      }
    },
    initialItems: []
  })
}
