import { memo, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { Box, IconButton, media } from '@cutover/react-ui'
import { useActiveRightPanelValueType, useToggleRightPanel } from 'main/components/layout/right-panel'
import { useRunbookId } from 'main/recoil/runbook'
import { useFeature, useLanguage } from 'main/services/hooks'
import { useCommentsCount, useResetComments } from 'main/recoil/runbook/models/runbook/runbook-comments'

export const RunbookRightNavigation = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'rightNavigation' })
  const { isEnabled } = useFeature()
  const runbookId = useRunbookId()
  const activeRightPanelType = useActiveRightPanelValueType()
  const commentsCountState = useCommentsCount()
  const [commentsCount, setCommentsCount] = useState(0)
  const [newCommentsCount, setNewCommentsCount] = useState(0)
  const resetComments = useResetComments()

  const toggleAiSuggestions = useToggleRightPanel('ai-suggestions-list')
  const toggleRunbookEdit = useToggleRightPanel('runbook-edit')
  const togglePeoplePanel = useToggleRightPanel('user-team-details-edit', () => true)
  // open panel check function here so that it will only toggle closed when its viewing the full list of runbook comments. If it is viewing
  // a specific task's comments, switch to viewing all comments.
  const toggleCommentsPanel = useToggleRightPanel('runbook-comments', openPanel => !openPanel.taskId)

  useEffect(resetComments, [])

  // The total number of runbook and task comments is not serialized with the Runbook,
  // so we must derive when new comments arrive by checking the recoil state against a local state.
  useEffect(() => {
    if (activeRightPanelType !== 'runbook-comments') {
      if (commentsCountState > commentsCount && commentsCount > 0) {
        setNewCommentsCount(newCommentsCount + commentsCountState - commentsCount)
      } else {
        setNewCommentsCount(commentsCountState)
      }
    }
    setCommentsCount(commentsCountState)
  }, [commentsCountState])

  // Reset new comments count when opening the comments panel.
  useEffect(() => {
    if (activeRightPanelType === 'runbook-comments') {
      setNewCommentsCount(0)
    }
  }, [activeRightPanelType])

  return (
    <NavContainer>
      <IconButton
        icon="edit"
        onClick={() => toggleRunbookEdit({ runbookId })}
        label={t('runbookDetails')}
        isActive={activeRightPanelType === 'runbook-edit' || activeRightPanelType === 'runbook-copies'}
      />
      <IconButton
        icon="users"
        onClick={() => togglePeoplePanel({ initialScreen: 'users-and-teams' })}
        label={t('usersAndTeams')}
        isActive={activeRightPanelType === 'user-team-details-edit'}
        data-testid="view-users-teams"
      />
      <IconButton
        icon="message"
        onClick={() => toggleCommentsPanel({})}
        label={t('runbookComments')}
        isActive={activeRightPanelType === 'runbook-comments'}
        {...(newCommentsCount > 0 ? { badge: { label: newCommentsCount, type: 'primary' } } : {})}
      />
      {/* TODO see https://cutover.atlassian.net/browse/CFE-446 */}
      {/* <IconButton icon="phone" onClick={handleComms} label="Ad hoc Comms" /> */}
      {isEnabled('ai_features') && (
        <IconButton
          badge={{ type: 'alert' }}
          icon="rocket"
          isActive={activeRightPanelType === 'ai-suggestions-list'}
          onClick={() => toggleAiSuggestions({ runbookId })}
          label={t('aiSuggestions')}
        />
      )}
    </NavContainer>
  )
})

const NavContainer = styled(Box)`
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 14px;
  ${media.sm(css`
    flex-direction: row;
    justify-content: center;
    gap: 32px;
  `)}
`
