import { useRecoilValue, useSetRecoilState } from 'recoil'

import { Box, Modal } from '@cutover/react-ui'
import { appConnectionLost } from 'main/recoil/apps/apps-atoms'
import { useLanguage } from 'main/services/hooks'

export const ConnectionLostModal = () => {
  const setAppConnectionLost = useSetRecoilState<boolean>(appConnectionLost)
  const appConnectionLostError = useRecoilValue<boolean>(appConnectionLost)
  const { t } = useLanguage('global')

  return (
    <Modal
      open={appConnectionLostError}
      onClose={() => setAppConnectionLost(false)}
      hasCancelButton={false}
      confirmText={t('confirmText')}
      onClickConfirm={() => window.location.reload()}
      title={t('connectionLostErrorTitle')}
      confirmIcon={'refresh'}
    >
      <Box>{t('connectionLostErrorBody')}</Box>
    </Modal>
  )
}
