import { ReactNode, useCallback, useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { useRunbookPermissionsState, useRunbookProperty } from 'main/recoil/runbook'
import { operations, useComponentPropsStateHandler } from './apps-component-props-helper'
import { AppsChannelResponse } from 'main/components/apps/apps-types'
import { appComponentProps, appHeaderView, appViews } from 'main/recoil/apps/apps-atoms'
import { useCurrentUser } from 'main/recoil/current-user'
import { useWebsockets } from 'main/services/hooks'

const APPS_RESOURCE_CHANNEL_NAME = 'AppResourceChannel'

export const AppsResourceChannelSubscriberReact = ({ children }: { children: ReactNode }) => {
  const runbookPermissions = useRunbookPermissionsState()
  const isTemplate = useRunbookProperty({ attribute: 'is_template' })

  return (
    <AppsResourceChannelSubscriber runbookPermissions={runbookPermissions} isTemplate={isTemplate}>
      {children}
    </AppsResourceChannelSubscriber>
  )
}

export const AppsResourceChannelSubscriberAngular = ({ children }: { children: ReactNode }) => {
  const [isTemplate, setIsTemplate] = useState<boolean | undefined>(false)
  const [runbookPermissions, setRunbookPermissions] = useState<{ [x: string]: number[] } | undefined>(undefined)

  useEffect(() => {
    const handleRunbookData = ({
      permissions,
      runbook
    }: {
      permissions: { [x: string]: number[] }
      runbook: { is_template: boolean }
    }) => {
      setRunbookPermissions(permissions)
      setIsTemplate(runbook.is_template)
    }
    eventManager.on('runbook-data', handleRunbookData)

    return () => {
      eventManager.off('runbook-data', handleRunbookData)
    }
  }, [])

  return (
    <AppsResourceChannelSubscriber isTemplate={isTemplate} runbookPermissions={runbookPermissions}>
      {children}
    </AppsResourceChannelSubscriber>
  )
}

export const AppsResourceChannelSubscriber = ({
  isTemplate,
  runbookPermissions,
  children
}: {
  isTemplate?: boolean
  runbookPermissions?: { [x: string]: number[] }
  children: ReactNode
}) => {
  const componentPropsStateHandler = useComponentPropsStateHandler()
  const setAppViews = useSetRecoilState(appViews)
  const setHeaderApp = useSetRecoilState(appHeaderView)
  const setComponentProps = useSetRecoilState(appComponentProps)
  const [subscriptionId, setSubscriptionId] = useState<string>('')
  const websockets = useWebsockets()
  const { runbookId } = useParams()
  const user = useCurrentUser()

  const updateAppViewsState = useCallback(
    (response: AppsChannelResponse) => {
      const appId = response.app_id
      const resourceId = response.resource_id
      const view = response.view
      const type = view.type
      const context = `${resourceId}-${appId}`

      componentPropsStateHandler({ response, context, setComponentProps })

      if (!operations.includes(type)) {
        switch (type) {
          case 'header':
            setHeaderApp({ view, appId, resourceId })
            break
          case 'panel':
            setAppViews(views => {
              const existingView = views[context]
              if (existingView.allow_bulk_refresh) {
                const updatedView = {
                  ...response.view,
                  appId,
                  resourceId,
                  visible: existingView.visible,
                  order: existingView?.order
                }
                return { ...views, [context]: updatedView }
              } else {
                return views
              }
            })
            break
          default:
            console.warn(`App resource channel does not accept ${type} type nodes.`)
        }
      }
    },
    [componentPropsStateHandler, setComponentProps, setHeaderApp, setAppViews]
  )

  const findAppsResourceChannelSubscription = useCallback(
    (id: string) => websockets.findExistingSubscription(APPS_RESOURCE_CHANNEL_NAME, id),
    [websockets]
  )

  const subscribeForRunbook = useCallback(
    (id: string) => {
      if (!findAppsResourceChannelSubscription(id)) {
        websockets.subscribe(APPS_RESOURCE_CHANNEL_NAME, id, {
          received: data => {
            updateAppViewsState(data.response)
          }
        })
      }
    },
    [findAppsResourceChannelSubscription, websockets, updateAppViewsState]
  )

  useEffect(() => {
    if (!runbookPermissions || isTemplate) {
      findAppsResourceChannelSubscription(subscriptionId)?.unsubscribe()
      return
    }

    const capability = runbookPermissions.update.includes(user.id) ? 'edit' : 'read'
    const id = `${runbookId}:${capability}`

    setSubscriptionId(id)
    subscribeForRunbook(id)

    return () => {
      findAppsResourceChannelSubscription(subscriptionId)?.unsubscribe()
    }
  }, [runbookPermissions, isTemplate, findAppsResourceChannelSubscription, runbookId, subscribeForRunbook])

  return <>{children}</>
}
