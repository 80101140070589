import { useState } from 'react'
import styled, { css } from 'styled-components'
import { format as formatDate, utcToZonedTime } from 'date-fns-tz'
import { useRecoilValue } from 'recoil'

import { Box, Icon, IconName, media, Text, themeColor } from '@cutover/react-ui'
import { incidentTimezone } from 'main/recoil/apps/apps-atoms'
import { ActivityConfig, ActivityVM } from 'main/services/queries/use-activities'
import { ActivityHeader } from './activity-header'
import { ActivityFeatured } from './activity-featured'
import { RemoveCommentControl } from './remove-comment-control'
import { ActivityDetails } from './activity-details'

type ActivityProps = {
  activity: ActivityVM
  activityConfig: ActivityConfig[]
  canUpdate: boolean
  previousActivity: ActivityVM
  index: number
  active: boolean
  setActive: (index: number | null) => void
  scrollToActivity: (index: number) => void
}

export const Activity = ({
  activity,
  activityConfig,
  previousActivity,
  canUpdate,
  index,
  active,
  setActive,
  scrollToActivity
}: ActivityProps) => {
  const showDetails = !!activity.display?.message || !!activity.changes?.length
  const [activityIsHovered, setActivityIsHovered] = useState(false)
  const commentIsDeleted = !!activity.display?.reason
  const timezone = useRecoilValue<string>(incidentTimezone)
  const formattedDate = formatDate(utcToZonedTime(new Date(activity.created_at), timezone), 'HH:mm')
  const formattedPreviousDate = previousActivity
    ? formatDate(utcToZonedTime(new Date(previousActivity.created_at), timezone), 'HH:mm')
    : ''
  const samePreviousActivityTime = formattedDate === formattedPreviousDate

  return (
    <ActivityStyledBox
      featured={activity.featured}
      key={activity.id}
      data-testid={`activity-${activity.id}`}
      onMouseEnter={() => setActivityIsHovered(true)}
      onMouseLeave={() => setActivityIsHovered(false)}
    >
      {!activity.grouped && (
        <ActivityHeader
          activity={activity}
          activityConfig={activityConfig}
          previousActivity={previousActivity}
          time={samePreviousActivityTime ? '' : formattedDate}
          index={index}
          active={active}
          setActive={setActive}
        />
      )}
      {showDetails && (
        <ActivityDetails
          index={index}
          scrollToActivity={(index: number) => scrollToActivity(index)}
          activity={activity}
        />
      )}
      {activityIsHovered && (activity.grouped || samePreviousActivityTime) && (
        <ActivityInsightsWrapper>
          <ActivityInsights time={formattedDate} />
        </ActivityInsightsWrapper>
      )}
      <CallToActionWrapper featured={activity.featured}>
        {!commentIsDeleted && (
          <RemoveCommentControl activity={activity} canUpdate={canUpdate} isHovered={activityIsHovered} />
        )}
        <ActivityFeatured activity={activity} canUpdate={canUpdate} isHovered={activityIsHovered} />
      </CallToActionWrapper>
    </ActivityStyledBox>
  )
}

const ActivityStyledBox = styled(Box)<{ featured?: boolean }>`
  list-style: none;
  margin-right: 16px;
  padding-right: 20px;
  padding-left: 10px;
  ${media.sm(css`
    padding: 0 20px;
  `)}
  background-color: ${props => (props.featured ? 'rgb(251, 244, 221)' : themeColor('bg'))};

  &:hover {
    background-color: ${props => (props.featured ? 'rgb(251, 244, 221)' : themeColor('bg-1'))};
  }
`

const CallToActionWrapper = styled(Box)<{ featured?: boolean }>`
  display: block;
  right: 28px;
  padding-left: 10px;
  position: absolute;
  margin-top: 6px;
  background-color: ${props => (props.featured ? 'rgb(251, 244, 221)' : 'inherit')};
`

const ActivityInsightsWrapper = styled(Box)`
  display: block;
  left: 14px;
  margin-top: 3px;
  position: absolute;
`

export const ActivityInsights = ({ time, icon, featured }: { time?: string; icon?: IconName; featured?: boolean }) => {
  return (
    <Box gap="xsmall" direction="row">
      <Box width="30px" align="center" pad="xsmall">
        {time && (
          <Text size={'13px'} color="text-light">
            {time}
          </Text>
        )}
      </Box>
      {icon && (
        <Box
          flex={false}
          align="center"
          background={featured ? { custom: 'rgba(251, 196, 56, 0.2)' } : 'bg-2'}
          round="xsmall"
          pad="xsmall"
        >
          <Icon size="small" icon={icon} color="text-light" aria-hidden />
        </Box>
      )}
    </Box>
  )
}
