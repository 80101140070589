import { useCallback } from 'react'
import { useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { keyBy } from 'lodash'

import { runbookVersionResponseState_INTERNAL } from '../models'
import {
  RunbookResponse,
  RunbookTeamDestroyResponse,
  RunbookTeamUpdateResponse
} from 'main/services/api/data-providers/runbook-types'
import { GetRunbookVersionResponse } from 'main/services/queries/use-runbook-versions'

export const useProcessRunbookTeamResponse = () => {
  const processRunbookTeamUpdateResponse = useProcessRunbookTeamUpdateResponse()
  const processRunbookTeamDeleteResponse = useProcessRunbookTeamDeleteResponse()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'update':
          processRunbookTeamUpdateResponse(response as RunbookTeamUpdateResponse)
          break
        case 'destroy':
          processRunbookTeamDeleteResponse(response as RunbookTeamDestroyResponse)
        default:
          return
      }
    },
    [processRunbookTeamUpdateResponse]
  )
}

export const useProcessRunbookTeamUpdateResponse = () => {
  return useRecoilTransaction_UNSTABLE(({ set }) => (response: RunbookTeamUpdateResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        const runbookUserLookup = keyBy(prevRunbookVersionResponse.meta.users, 'id')

        const { role_types } = response.runbook_team

        if (role_types && role_types[0].users.length > 0) {
          role_types[0].users?.forEach(user => {
            if (!runbookUserLookup[user.id]) {
              draftRunbookVersionResponse.meta.users.push(user)
            }
          })
        }

        const { users_removed } = response.meta

        removeUsersFromMeta({ userIds: users_removed, draft: draftRunbookVersionResponse })
      })
    )
  })
}

export const useProcessRunbookTeamDeleteResponse = () => {
  return useRecoilTransaction_UNSTABLE(({ set }) => (response: RunbookTeamDestroyResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        const { removed_user_ids } = response.meta

        removeUsersFromMeta({ userIds: removed_user_ids, draft: draftRunbookVersionResponse })

        const runbook_team_index = draftRunbookVersionResponse.meta.runbook_teams.findIndex(
          team => team.id === response.runbook_team.id
        )
        draftRunbookVersionResponse.meta.runbook_teams.splice(runbook_team_index, 1)
      })
    )
  })
}

const removeUsersFromMeta = ({ userIds, draft }: { userIds: number[]; draft: GetRunbookVersionResponse }) => {
  if (userIds?.length > 0) {
    userIds.forEach(id => {
      const index = draft.meta.users.findIndex(user => user.id === id)
      draft.meta.users.splice(index, 1)
    })
  }
}
