import { ReactNode, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useUnmount } from 'react-use'

import { useHandleAccountResponse } from './account-operations'
import { useCurrentUserId } from 'main/recoil/current-user'
import { useWebsockets } from 'main/services/hooks'

export const AccountChannelSubscriber = ({ children }: { children?: ReactNode }) => {
  const { subscribe, findExistingSubscription } = useWebsockets()
  const { accountId: accountSlug, runbookId } = useParams<{ accountId?: string; runbookId?: string }>()
  const currentUserId = useCurrentUserId()
  const processAccountResponse = useHandleAccountResponse()

  const websocketRef = useRef<ActionCable.Channel | undefined>()

  useEffect(() => {
    websocketRef.current?.unsubscribe?.()

    if (!accountSlug || runbookId) return

    const existingRunbookChannel = findExistingSubscription('AccountChannel', accountSlug)

    if (existingRunbookChannel) return

    websocketRef.current = subscribe('AccountChannel', accountSlug, {
      received: response => {
        const [sameTab, sameUser] = [
          response.meta?.headers?.request_hash === window.sessionStorage.getItem('browserHash'),
          response.meta?.headers?.request_user_id === currentUserId
        ]

        if (sameTab && sameUser) return

        processAccountResponse(response)
      }
    })
  }, [accountSlug, runbookId])

  useUnmount(() => {
    websocketRef.current?.unsubscribe?.()
  })

  return <>{children}</>
}
