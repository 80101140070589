// @ts-nocheck
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AngularChooseRunbookTemplate } from './AngularChooseRunbookTemplate/AngularChooseRunbookTemplate'
import { AngularRunbooksPageModalsPresenter } from './AngularRunbooksPageModalsPresenter'
import { AngularChooseRunbookStartTime } from './AngularChooseRunbookStartTime/AngularChooseRunbookStartTime'
import { Modal } from 'Components/Atoms'

interface IRunbooksPageComponentProps {
  presenter: AngularRunbooksPageModalsPresenter
}

export const AngularRunbooksPageModals = observer<IRunbooksPageComponentProps>(({ presenter }) => {
  const { chooseRunbookTemplateModalPresenter, chooseRunbookStartTimeModalPresenter } = presenter
  const [runbookType, setRunbookType] = useState(null)
  const [openPreviousModal, setOpenPreviousModal] = useState(null)

  useEffect(() => {
    window.reactJS.openChooseRunbookTemplateModal = (
      runbookType: {
        id: string
        iconName: string
        iconColor: string
      },
      openPreviousModal: () => void
    ) => {
      setOpenPreviousModal(() => openPreviousModal)
      setRunbookType(runbookType)
      chooseRunbookTemplateModalPresenter.onOpen()
    }

    return () => {
      delete window.reactJS.openChooseRunbookTemplateModal
    }
  }, [])

  return (
    <>
      <Modal
        topOffset={350}
        customWidth={572}
        closeButtonHasDeepPosition
        presenter={chooseRunbookTemplateModalPresenter}
      >
        {runbookType && (
          <AngularChooseRunbookTemplate
            runbookType={runbookType}
            modalPresenter={chooseRunbookTemplateModalPresenter}
            openPreviousModal={openPreviousModal}
          />
        )}
      </Modal>
      <AngularChooseRunbookStartTime modalPresenter={chooseRunbookStartTimeModalPresenter} />
    </>
  )
})
