import { forwardRef, useMemo } from 'react'
import { Box, DropProps, Text as GrommetText, TextExtendedProps as GrommetTextProps, TipProps } from 'grommet'
import { ColorType } from 'grommet/utils'
import styled, { css } from 'styled-components/macro'

import { TOOLTIP_MAX_WIDTH } from '../../overlays/tooltip/tooltip'
import { ColorProp, themeColor } from '../../theme'

const ARROW_SIZE = 8

const PLACEMENT: Record<'left' | 'right' | 'top' | 'bottom', DropProps['align']> = {
  left: { right: 'left' },
  right: { left: 'right' },
  top: { bottom: 'top' },
  bottom: { top: 'bottom' }
}

export type TextProps = Omit<GrommetTextProps, 'color'> & {
  tip?: string
  tipPlacement?: keyof typeof PLACEMENT
  tipMaxWidth?: number
  color?: ColorProp | ColorType
}

export const Text = forwardRef<HTMLDivElement, TextProps>(
  ({ tip, tipPlacement = 'bottom', truncate, ...textProps }: TextProps, ref) => {
    const dropProps = useMemo(() => ({ align: PLACEMENT[tipPlacement] }), [tipPlacement])

    // NOTE: We're not using our own Tooltip component due to complexity of implementation
    const customTip: TipProps = {
      plain: true,
      content: (
        <TipContainer ref={ref} tipPlacement={tipPlacement}>
          <TipText>{tip ?? textProps.children}</TipText>
        </TipContainer>
      ),
      dropProps
    }

    return (
      <GrommetText
        {...textProps}
        color={textProps.color as ColorType}
        aria-label={tip ? undefined : textProps.a11yTitle}
        truncate={!tip && truncate}
        tip={tip || (truncate && truncate === 'tip') ? customTip : undefined}
      />
    )
  }
)

const arrowUp = css`
  left: 50%;
  top: calc(-${ARROW_SIZE}px / 2);
  margin-left: calc(${ARROW_SIZE}px * -0.7);
`
const arrowDown = css`
  left: 50%;
  bottom: calc(-${ARROW_SIZE}px / 2);
  margin-left: calc(${ARROW_SIZE}px * -0.7);
`
const arrowLeft = css`
  bottom: 50%;
  left: calc(-${ARROW_SIZE}px / 2);
  margin-bottom: calc(${ARROW_SIZE}px * -0.7);
`
const arrowRight = css`
  bottom: 50%;
  right: calc(-${ARROW_SIZE}px / 2);
  margin-bottom: calc(${ARROW_SIZE}px * -0.7);
`

const TipText = styled(Text).attrs(() => ({
  size: '14px',
  textAlign: 'center'
}))`
  text-align: center;
  size: 14px;
  overflow-wrap: break-word;
`

const TipContainer = styled(Box).attrs(() => ({
  round: '4px',
  margin: 'xsmall',
  background: 'tooltip-bg',
  pad: { horizontal: '8px', vertical: '4px' }
}))<Required<Pick<TextProps, 'tipPlacement'>>>`
  position: relative;
  color: ${themeColor('tooltip-text')};
  width: max-content;
  max-width: ${TOOLTIP_MAX_WIDTH}px;

  &::after {
    content: '';
    position: absolute;
    display: block;
    transform: rotate(45deg);
    width: ${ARROW_SIZE}px;
    height: ${ARROW_SIZE}px;
    background: inherit;

    ${props => {
      switch (props.tipPlacement) {
        case 'left':
          return arrowRight
        case 'right':
          return arrowLeft
        case 'top':
          return arrowDown
        case 'bottom':
          return arrowUp
      }
    }}
  }
`
