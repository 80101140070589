import { Card, Grid, RunbookTypeIcon } from '@cutover/react-ui'
import { useAccountRunbookTypes } from 'main/services/api/data-providers/account/account-data'
import { useMergeRunbooksContext } from '../merge-runbooks-modal-provider'

export const StepNewRunbookChooseType = () => {
  const { activeRunbookTypes: runbookTypes } = useAccountRunbookTypes()
  const { setNewRunbookTypeId, setStep } = useMergeRunbooksContext()

  return (
    <Grid minWidth={200}>
      {runbookTypes &&
        runbookTypes
          .sort((a, b) => a.id - b.id)
          .map(type => (
            <Card
              key={type.id}
              role="button"
              title={type.name}
              subtitle={type.description}
              startComponent={<RunbookTypeIcon icon={type.icon_name} color={type.icon_color} />}
              onClick={() => {
                setNewRunbookTypeId(type.id)
                setStep('enter-new-runbook-details')
              }}
            />
          ))}
    </Grid>
  )
}
