import { useMutation } from 'react-query'

import { apiClient } from 'main/services/api/api-client'
import { ApiError } from 'main/services/api/http-gateway-adapter'

type SnippetCreateProps = {
  accountId: string | number
}

type SnippetCreateRequestType = {
  name: string
  accountId: string | number
}

export type SnippetCreateResponseType = {
  id: number
}

export function useSnippetCreate({ accountId }: SnippetCreateProps) {
  return useMutation<SnippetCreateResponseType, ApiError, { name: string }>(['snippet-create'], async ({ name }) => {
    const payload = {
      name,
      accountId,
      templateType: 'snippet',
      templateStatus: 'template_draft'
    }

    const { data } = await apiClient.post<SnippetCreateRequestType, SnippetCreateResponseType>({
      url: 'runbooks',
      requestProperty: 'runbook',
      responseProperty: 'runbook',
      data: payload,
      convertCase: true
    })

    return data as SnippetCreateResponseType
  })
}
