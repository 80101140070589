// @ts-nocheck
import { ButtonPresenter, Button } from 'Components/Molecules/Button'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'

interface IAddButtonProps {
  action: () => void
  language: ILanguageService
  ariaLabel?: string
}

export const AddButton = (props: IAddButtonProps) => {
  const buttonPresenter = new ButtonPresenter('', props.action)
    .withIconName('add')
    .withDisplayStyle('add-button')
    .withAriaLabel(props.ariaLabel || props.language.get('common:ariaLabels:add'))
  return (
    <>
      <Button presenter={buttonPresenter} />
    </>
  )
}
