import { useCallback } from 'react'
import { useRecoilCallback, useRecoilTransaction_UNSTABLE } from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import { runbookResponseState_INTERNAL, runbookVersionResponseState_INTERNAL, useTaskListRefetch } from '../models'
import {
  RunbookResponse,
  RunbookVersionConvertToTemplateResponse,
  RunbookVersionCreateResponse,
  RunbookVersionImportResponse
} from 'main/services/api/data-providers/runbook-types'
import { useNavigateToVersion } from 'main/recoil/shared/nav-utils'
import { updateCurrentVersion } from './shared-updates'

export const useProcessRunbookVersionResponse = () => {
  const processRunbookVersionCsvImportResponse = useProcessRunbookVersionCsvImportResponse()
  const processRunbookVersionCreateResponse = useProcessRunbookVersionCreateResponse()
  const processRunbookVersionConvertToTemplateResponse = useProcessRunbookVersionConvertToTemplateResponse()
  const navigateToVersion = useNavigateToVersion()

  return useCallback(
    (response: RunbookResponse) => {
      switch (response.meta.headers.request_method) {
        case 'create':
          processRunbookVersionCreateResponse(response as RunbookVersionCreateResponse)
          const currentVersionId = (response as RunbookVersionCreateResponse).runbook_version.id
          navigateToVersion(currentVersionId)
          break
        case 'runbook_version_import':
          processRunbookVersionCsvImportResponse(response as RunbookVersionImportResponse)
          break
        case 'convert_to_template':
          processRunbookVersionConvertToTemplateResponse(response as RunbookVersionConvertToTemplateResponse)
          break
        default:
          return
      }
    },
    [processRunbookVersionCsvImportResponse, processRunbookVersionCreateResponse]
  )
}

export const useProcessRunbookVersionCsvImportResponse = () => {
  const refetchTasks = useTaskListRefetch()

  // FIXME: seems wrong. we don't updeate the runbook state's current_version and runbook_version_id
  return useRecoilCallback(({ set }) => async (data: RunbookVersionImportResponse) => {
    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, data.runbook_version)
      })
    )

    refetchTasks()
  })
}

export const useProcessRunbookVersionCreateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookVersionCreateResponse) => {
    const { set } = transactionInterface

    updateCurrentVersion(transactionInterface)(response.runbook_version)

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
      })
    )
  })
}

export const useProcessRunbookVersionConvertToTemplateResponse = () => {
  return useRecoilTransaction_UNSTABLE(transactionInterface => (response: RunbookVersionConvertToTemplateResponse) => {
    const { set } = transactionInterface

    set(runbookResponseState_INTERNAL, prevRunbookResponse =>
      produce(prevRunbookResponse, draftRunbookResponse => {
        draftRunbookResponse.runbook.is_template = response.meta.runbook_is_template
        draftRunbookResponse.runbook.template_status = response.meta.runbook_template_status
        if (response.meta.runbook_is_template) draftRunbookResponse.runbook.template_type = 'default'
      })
    )

    set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
      produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
        extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
        extend(draftRunbookVersionResponse.meta.permissions, response.meta.permissions)
      })
    )
  })
}
