import { keyBy } from 'lodash'

import { RunbookFilterType } from './filters'
import { buildCriticalPath, findAllPredecessorIds } from '../critical-path'
import {
  CustomField,
  RunbookComponent,
  RunbookTeam,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import { FieldValuesByTaskAndCustomFieldIdLookup } from 'main/recoil/runbook'

/**
 * Build the filtering context from the filter string
 * TODO: currently the data payload is missing information on the current user and its teams
 * TODO: decouple from dashboard component data
 * @param filters The filters object
 * @param data data needed to create the context including runbook, tasks, custom filters, task types, etc
 * @returns a context object
 */

export type BuildFilterContextData = {
  now?: number
  tasks?: TaskListTask[]
  custom_fields?: CustomField[]
  task_types?: TaskType[]
  runbook_teams?: RunbookTeam[]
  field_values_lookup?: FieldValuesByTaskAndCustomFieldIdLookup
  // not using currently but these are the users off the
  // runbook version, should we use these?
  runbook_users?: RunbookVersionUser[]
  current_user: number
  // dashboard component data has Stream type but runbook page data has RunbookVersionStream type.
  streamInternalIdLookup?: Record<number, StreamListStream>
  taskLookup?: { [key: number]: TaskListTask }
  runbookComponentInternalIdLookup?: Record<number, RunbookComponent>
}

export type TaskFilterContext = {
  /** the task.ids for all tasks on the critical path */
  criticalIds?: number[]
  criticalToHereIds?: number[]
  predecessorsToHereIds?: number[]
  floatLookup?: Record<number, number>
  /** the task_type.id for the milestone task type */
  milestoneId?: number
  customFields?: CustomField[]
  customFieldLookup?: { [key: number]: CustomField }
  fieldValuesLookup?: FieldValuesByTaskAndCustomFieldIdLookup
  currentUserId?: number
  streamIds?: number[]
  /** runbook_teams.id for the current user */
  currentUserRunbookTeamIds?: number[]
  /** the user.ids for users assigned to tasks via teams */
  teamUserIds?: number[]
  /** the runbook_team.ids for any runbook_team.team_id searched for */
  runbookTeamIds?: number[]
  /** the runbook_team.ids for any user matched via user filter */
  userTeamIds?: number[]
  /** the search query string when the query param does not start with # */
  query?: string
  /** The task internal ids to match when search query string starts with # */
  taskIds?: number[]
  /** The user ids for users assigned directly to tasks (not via team) */
  userIds?: number[]
  /** Current timestamp / 1000 for date related filters such as startWithin */
  now?: number
  runbookComponentIds?: number[]
}

export function buildFilterContext(
  filters: RunbookFilterType,
  {
    now = 0,
    field_values_lookup = {},
    tasks = [],
    custom_fields = [],
    task_types = [],
    runbook_teams = [],
    // Keeping to remember this is here for usage
    runbook_users: _runbook_users = [],
    current_user,
    streamInternalIdLookup = {},
    taskLookup = {},
    runbookComponentInternalIdLookup = {}
  }: BuildFilterContextData
): TaskFilterContext {
  const { taskIds, floatLookup } = buildCriticalPath(tasks, { from: null, to: 0, float: 0 })

  let context: TaskFilterContext = {
    criticalIds: taskIds,
    floatLookup: floatLookup
  }

  if (filters.critical_to_here) {
    context.criticalToHereIds = buildCriticalPath(tasks, {
      from: null,
      to: Number(filters.critical_to_here),
      float: 0
    }).taskIds
  }

  if (filters.predecessors_to_here) {
    context.predecessorsToHereIds = findAllPredecessorIds(filters.predecessors_to_here, tasks, taskLookup)
  }

  if (filters.stream) {
    context.streamIds = filters.stream.flatMap(internalId => {
      const stream = streamInternalIdLookup[internalId]
      if (stream) {
        return [stream.id, ...(stream.children ?? []).map(child => child.id)]
      } else {
        return []
      }
    })
  }

  if (filters.rbc) {
    context.runbookComponentIds = filters.rbc.flatMap(internalId => {
      const runbookComponent = runbookComponentInternalIdLookup[internalId]
      if (runbookComponent) {
        return [runbookComponent.id]
      }
      return []
    })
  }

  // TODO: could pull out each chunk into extracted buildXFilterContext functions, like done for q
  if (filters.q) {
    context = { ...context, ...buildSearchQueryFilterContext({ q: filters.q }) }
  }

  if (filters.m !== undefined) {
    const milestoneTaskType = task_types.find(tt => tt.name.toLowerCase() === 'milestone')
    context.milestoneId = milestoneTaskType?.id
  }

  if (filters.f) {
    context.customFields = custom_fields
    context.customFieldLookup = keyBy(custom_fields, 'id')
    context.fieldValuesLookup = field_values_lookup
  }

  if (filters.team) {
    /** lookup for runbook_teams by id */
    const runbookTeamLookup = keyBy(runbook_teams, 'id')
    /** lookup for runbook_teams by team_id */
    const runbookTeamTeamIdLookup = keyBy(runbook_teams, 'team_id')

    // removes undefined values when finding team by id = 0 (no team)
    context.runbookTeamIds = filters.team.flatMap(teamId =>
      runbookTeamTeamIdLookup[teamId]?.id ? [runbookTeamTeamIdLookup[teamId]?.id] : []
    )

    // when include users is set for any team
    // @ts-ignore
    if (filters.includeUsers && filters.team[0] === '*') {
      context.teamUserIds = Array.from(
        new Set(
          tasks.flatMap(task => task.runbook_team_ids?.flatMap(id => runbookTeamLookup[id]?.user_ids ?? []) ?? [])
        )
      )
    }

    // when include users is set with specific teams
    else if (filters.includeUsers) {
      context.teamUserIds = context.runbookTeamIds?.flatMap(id => runbookTeamLookup[id]?.user_ids ?? [])
    }
  }

  if (filters.mt !== undefined) {
    context.currentUserRunbookTeamIds = Array.from(
      new Set(buildRunbookTeamIdsFromUserIds([current_user], runbook_teams))
    )
    // NOTE: dashboard component payload is sent in directly and has a key of current_user which has a numeric id value.
    context.currentUserId = current_user
  }

  if (filters.user) {
    // @ts-ignore
    if (filters.user[0] === '*') {
      context.userIds = Array.from(new Set(tasks.flatMap(task => task.user_ids ?? [])))
    } else {
      context.userIds = filters.user.filter(val => val !== 0) as unknown as number[]
    }
    context.userTeamIds = Array.from(new Set(buildRunbookTeamIdsFromUserIds(context.userIds, runbook_teams)))
  }

  if (filters.dd && now !== 0) {
    context.now = now
  }

  return context
}

export const buildSearchQueryFilterContext = ({
  q: searchQuery
}: {
  q: string
}): { query?: string; taskIds?: number[] } => {
  const searchContext: { [key: string]: unknown } = {}
  if (searchQuery !== undefined) {
    searchContext.query = (searchQuery.toString() || '').toLowerCase()
    if (searchQuery[0] === '#') {
      searchContext.taskIds = searchQuery.replace(/\#/g, '').split(',').map(Number)
    }
  }
  return searchContext
}

// NOTE: refactor -- copied exactly from angular
function buildRunbookTeamIdsFromUserIds(userIds: number[], runbookTeams: RunbookTeam[]): number[] {
  const runbookTeamIds = []

  for (let i = 0, n = runbookTeams.length; i < n; i++) {
    for (let u = 0; u < userIds.length; u++) {
      if (runbookTeams[i].user_ids.indexOf(userIds[u]) !== -1) {
        runbookTeamIds.push(runbookTeams[i].id)
      }
    }
  }

  return runbookTeamIds
}
