import { useEffect, useState } from 'react'
import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'

import { Box, Icon } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookIntegration } from 'main/services/queries/types'

type ActionLogsProps = {
  runbookIntegration: RunbookIntegration
}

export const IntegrationActionLogs = ({ runbookIntegration }: ActionLogsProps) => {
  const { t } = useLanguage()
  const [actionLogOpen, setActionLogOpen] = useState<boolean>(false)

  useEffect(() => {
    setActionLogOpen(false)
  }, [])

  return (
    <Box direction="column" margin={{ vertical: 'xsmall' }}>
      <Box direction="row" align="center" onClick={() => setActionLogOpen(!actionLogOpen)}>
        <Box direction="column" css={'font-size: 15px; font-weight: 600;'}>
          {t('runbook:integrationsPanel:actionLogTitle')}
        </Box>
        <Icon
          icon={actionLogOpen ? 'chevron-down' : 'chevron-right'}
          color="text-light"
          size="small"
          css="margin-left: 4px; cursor: pointer;"
        />
      </Box>
      {actionLogOpen && (
        <Box direction="column" margin={{ vertical: 'xsmall' }}>
          {runbookIntegration.integrationEvents
            .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
            .map(integrationEvent => (
              <Box
                direction="row"
                margin={{ vertical: 'xxsmall' }}
                key={integrationEvent.eventId}
                css={'font-size: 10px;'}
              >
                <Box direction="column" css={{ width: '40%' }}>
                  {format(parseISO(integrationEvent.timestamp), 'dd MMM HH:mm')}
                </Box>
                <Box direction="column" css={'font-weight: 600;'}>
                  {t(`integrationStatuses:${integrationEvent.status.toLowerCase()}`, {
                    entityType: 'Runbook'
                  })}
                </Box>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  )
}
