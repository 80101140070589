// @ts-nocheck
import { useEffect } from 'react'
import { IncidentSeveritiesViewPresenter } from './IncidentSeveritiesViewPresenter'
import { ModalPresenter, GenericAddModal } from 'Components/Atoms'
import { IPageModalProps } from 'app/Shared/Entities/IPageModalProps'
import IncidentSeverityAddSection from './IncidentSeverityAdd/IncidentSeverityAddSection'
import { container } from 'app/Config/IOC'

export const IncidentSeveritiesPageModals = (props: IPageModalProps) => {
  const { addFormPresenter, addModalOpenState, setAddModalOpenState, setAddFormViewModel, language } = props

  const incidentSeveritesViewPresenter = container.get(IncidentSeveritiesViewPresenter)
  const incidentSeverityAddModalPresenter = new ModalPresenter().withOnCloseExtra(() => {
    addFormPresenter.reset()
    setAddModalOpenState(false)
  })

  useEffect(() => {
    if (addModalOpenState === true) {
      setAddFormViewModel()
      incidentSeverityAddModalPresenter.onOpen()
    }
  }, [addModalOpenState])

  return (
    <>
      <GenericAddModal
        title={language.get('incidentSeverities:addSection:title')}
        presenter={incidentSeverityAddModalPresenter}
        cancelCallback={() => {
          setAddModalOpenState(false)
          addFormPresenter.reset()
        }}
        viewPresenter={incidentSeveritesViewPresenter}
        language={language}
      >
        <IncidentSeverityAddSection
          postSuccessCallback={() => {
            incidentSeverityAddModalPresenter.onClose()
            setAddModalOpenState(false)
          }}
          addFormPresenter={addFormPresenter}
          presenter={incidentSeveritesViewPresenter}
        />
      </GenericAddModal>
    </>
  )
}
