import { useState } from 'react'
import { sortBy } from 'lodash'
import { useToggle } from 'react-use'

import { Modal, SanitizedString, Text, useNotify } from '@cutover/react-ui'
import { NavItemSavedView } from './nav-item-saved-view'
import { useActiveSavedView, useSavedViewPermissions } from '../hooks'
import { NavItem, NavItemInfoText } from '../nav-item'
import { useCurrentUser } from 'main/recoil/current-user'
import { useLanguage } from 'main/services/hooks'
import {
  SavedViewGroup,
  useDeleteSavedViewGroup,
  useUpdateSavedViewGroup
} from 'main/services/queries/use-saved-view-groups'

export const NavItemSavedViewGroup = ({
  accountId,
  savedViewGroup,
  isGlobal,
  groups
}: {
  accountId: string | number
  savedViewGroup: SavedViewGroup
  isGlobal: boolean
  groups: SavedViewGroup[]
}) => {
  const currentUser = useCurrentUser()
  const { t } = useLanguage('sidebarNav', { keyPrefix: 'defaultNav' })
  const notify = useNotify()
  const activeSavedView = useActiveSavedView()
  const [isOpen, toggleOpen] = useToggle(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  // angular uses an orderBy filter in the view and with a function that concats the _display + name. However,
  // it doesn't come up with the same order. Not sure how angular filteres work or if this is important beyond
  // creating a deterministic order?
  const views = sortBy(savedViewGroup.saved_views, view => {
    const { query_string, name } = view
    const { _display } = JSON.parse(query_string)
    return _display + name
  })

  const { mutate: updateSavedViewGroup } = useUpdateSavedViewGroup(accountId, {
    onSuccess: () => notify.success(t('notification.savedViewGroupUpdatedSuccess')),
    onError: () => notify.error(t('notification.savedViewGroupUpdatedError'))
  })

  const { mutate: deleteSavedViewGroup } = useDeleteSavedViewGroup(accountId, {
    onMutate: () => setIsDeleteModalOpen(false),
    onSuccess: () => notify.success(t('notification.savedViewGroupDeletedSuccess')),
    onError: () => notify.error(t('notification.savedViewGroupDeletedError'))
  })

  const userCan = useSavedViewPermissions(accountId)
  const canDelete = userCan('destroy')
  const canRename = userCan('update')

  const deletable = isGlobal && views.length === 0 && canDelete
  const canDeleteWithoutConfirmation = savedViewGroup.user_id === currentUser.id

  const handleClickDelete = !deletable
    ? undefined
    : () => {
        canDeleteWithoutConfirmation ? deleteSavedViewGroup(savedViewGroup.id) : setIsDeleteModalOpen(true)
      }

  const handleClickRename =
    isGlobal && canRename
      ? (newName: string) => {
          updateSavedViewGroup({
            id: savedViewGroup.id,
            name: newName
          })
        }
      : undefined

  const hasPublicActiveSavedView =
    !!activeSavedView?.global && activeSavedView.saved_view_group_id === savedViewGroup.id
  const hasPrivateActiveSavedView =
    !!activeSavedView && !activeSavedView.global && activeSavedView.user_id === currentUser.id

  const showActive = !isOpen && ((isGlobal && hasPublicActiveSavedView) || (!isGlobal && hasPrivateActiveSavedView))

  return (
    <>
      <NavItem
        label={isGlobal ? savedViewGroup.name : t('privateGroupLabel')}
        expandable
        icon="caret-right"
        isOpen={isOpen}
        isActive={showActive}
        onClick={toggleOpen}
        onDelete={handleClickDelete}
        onRename={handleClickRename}
      >
        {views.length === 0 ? (
          <NavItemInfoText level={1}>
            {!isGlobal ? t('createSavedViewsInstructions') : t('savedViewInfo')}
          </NavItemInfoText>
        ) : (
          views.map((view, i) => {
            return (
              <NavItemSavedView
                isGlobal={isGlobal}
                groups={groups.filter(g => g.id !== savedViewGroup.id)}
                accountId={accountId}
                key={view.id ?? `${view.name}-${i}`}
                savedView={view}
              />
            )
          })
        )}
      </NavItem>
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onClickConfirm={() => deleteSavedViewGroup(savedViewGroup.id)}
        title={t('deleteGroup')}
        confirmText={t('confirmDelete')}
        confirmIcon={t('deleteIcon')}
      >
        {/* FIXME: Fix this poorly worded message */}
        {/* TODO: i18n */}
        <Text>
          <SanitizedString input={t('deleteGroupInfoText', { name: savedViewGroup.name })} />
        </Text>
      </Modal>
    </>
  )
}
