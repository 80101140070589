// @ts-nocheck
import { useRef, useEffect, useState } from 'react'

import styled from 'styled-components'
import { colors, px2rem } from 'app/Styles/Variables'
import { Heading, Modal, ModalPresenter } from 'Components/Atoms'
import { TMode } from './TMode'
import { ArchiveButton, CloseButton, ResetButton, SaveButton } from 'Components/Molecules'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { RefreshButton } from 'Shared/Components/Molecules/EditFormButtons/RefreshButton'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { EditPanelDirtyConfirmModal, EditPanelDirtyConfirmModalPresenter } from 'Shared/Components/Templates'
import { scrollToTop } from 'Shared/HooksAndPresenters/ScrollToTop'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const HeaderWrapper = styled.div`
  padding-left: ${px2rem(16)};
  border-bottom: ${px2rem(1)} solid ${colors.primaryGreyBackgroundColor};
`

const HeaderButtonWrapper = styled.div`
  position: absolute;
  right: ${px2rem(12)};
  top: 0;
  display: flex;
  flex-direction: row;
  line-height: ${px2rem(32)};
  padding: ${px2rem(16)} 0;
  font-size: 125%;
  ${(props: { iconOnly: boolean }) =>
    props.iconOnly
      ? `
      padding: 20px 0;
      button {
          width: 36px;
        }`
      : ''}
  i {
    font-size: ${px2rem(24)};
  }
`

const ContentWrapper = styled.div`
  padding: ${px2rem(16)};
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
`

const SavingText = styled.p`
  margin-top: 0;
  color: ${colors.secondaryGrey};
`

interface IEditPanelFormWrapperProps {
  heading: string
  extraStyles?: CSSProperties
  children: ReactNode
  mode: TMode
  showArchive?: boolean
  archiveTooltip?: string
  archiveCallback?: () => void
  closeCallback: () => void
  saveCallback: () => void
  resetCallback: () => void
  singleLineArchiveTooltip?: boolean
  editPanelSaving: boolean
  showRefresh?: boolean
  refreshTooltip?: string
  refreshCallback?: () => Promise<void>
  singleLineRefreshTooltip?: boolean
  confirmModalPresenter: ModalPresenter
  triggerScroll?: boolean
  setTriggerScroll?: (boolean: boolean) => void
}

export const EditPanelFormWrapper = ({
  heading,
  mode,
  showArchive,
  children,
  archiveTooltip,
  resetCallback,
  saveCallback,
  archiveCallback,
  editPanelSaving,
  singleLineArchiveTooltip,
  showRefresh,
  refreshTooltip,
  refreshCallback,
  closeCallback,
  singleLineRefreshTooltip,
  confirmModalPresenter,
  triggerScroll,
  setTriggerScroll
}: IEditPanelFormWrapperProps) => {
  const language: ILanguageService = container.get(Types.ILanguageService)
  const [lastTarget, setLastTarget] = useState(null)
  const [isContinue, setIsContinue] = useState(false)
  const [shouldReturnFocusAfterModalClose, setShouldReturnFocusAfterModalClose] = useState(false)
  const isEditPanelDirty = mode === 'panel-open-confirm'

  const editPanelRef = useRef<any>()

  let elementClickTimeout = null
  let modalCloseTimeout = null

  const onOutsideClick = (e: MouseEvent) => {
    const noModalOpen = !document.body.classList.contains('ReactModal__Body--open')

    if (editPanelRef && !editPanelRef.current.contains(e.target) && noModalOpen) {
      e.stopPropagation()
      e.preventDefault()
      setLastTarget({
        element: e.target,
        clientX: e.clientX,
        clientY: e.clientY
      })

      confirmModalPresenter.onOpen()
    }
  }

  const addListener = () => {
    // Listen to `mouseDown` event to catch the earliest interaction
    window.document.addEventListener('mousedown', onOutsideClick)
    window.document.addEventListener('touchstart', onOutsideClick)
  }

  const removeListener = () => {
    window.document.removeEventListener('mousedown', onOutsideClick)
    window.document.removeEventListener('touchstart', onOutsideClick)
  }

  useEffect(() => {
    if (isEditPanelDirty) {
      addListener()
    } else {
      removeListener()
    }

    return () => {
      removeListener()
    }
  }, [mode, confirmModalPresenter, confirmModalPresenter.isOpen])

  useEffect(() => {
    if (lastTarget && !isEditPanelDirty && isContinue) {
      // Check if element exists in the document.
      // Sometimes after re-renders it could no longer be found
      if (document.contains && document.contains(lastTarget.element)) {
        lastTarget.element && lastTarget.element.click()

        // If element can't be found on the document, try to find it using coordinates
      } else if (!!document.elementFromPoint && lastTarget.clientX !== null && lastTarget.clientY !== null) {
        // Give sometime to close the modal to find a correct element for click
        elementClickTimeout = setTimeout(() => {
          const element: HTMLElement = document.elementFromPoint(lastTarget.clientX, lastTarget.clientY) as HTMLElement
          element && element.click()
        }, 100)
      }

      setLastTarget(null)
      setIsContinue(false)
    }
  }, [mode, isContinue, lastTarget])

  const continueOnModalClose = (callback: Function) => async () => {
    await callback()
    // Add timeout to unblock animation to scroll a little bit smoother
    modalCloseTimeout = setTimeout(confirmModalPresenter.onClose, 10)
    setShouldReturnFocusAfterModalClose(false)
    setIsContinue(true)
  }

  const cancelOnModalClose = () => {
    setShouldReturnFocusAfterModalClose(true)
    confirmModalPresenter.onClose()
  }

  // Remove coordinates after resize,
  // since they can be wrong
  const handleResize = () => {
    setLastTarget({
      element: lastTarget && lastTarget.element,
      clientX: null,
      clientY: null
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(elementClickTimeout)
      clearTimeout(modalCloseTimeout)
    }
  }, [])

  const editPanelDirtyConfirmModalPresenter = new EditPanelDirtyConfirmModalPresenter(
    cancelOnModalClose,
    continueOnModalClose(resetCallback),
    continueOnModalClose(saveCallback)
  )

  const scrollRef = scrollToTop(triggerScroll, setTriggerScroll)

  return (
    <>
      <Wrapper ref={editPanelRef}>
        <HeaderWrapper>
          <Heading extraStyles={{ lineHeight: '64px' }} size={22}>
            {heading}
          </Heading>
          {editPanelSaving ? (
            <HeaderButtonWrapper iconOnly={false}>
              <SavingText>{language.get('common:savingText')}</SavingText>
            </HeaderButtonWrapper>
          ) : (
            <>
              {mode == 'panel-open' && (
                <HeaderButtonWrapper iconOnly={true}>
                  {showRefresh && (
                    <RefreshButton
                      tooltipMessage={refreshTooltip}
                      refreshCallback={refreshCallback}
                      singleLineRefreshTooltip={singleLineRefreshTooltip}
                      language={language}
                    />
                  )}
                  {showArchive && (
                    <ArchiveButton
                      tooltipMessage={archiveTooltip}
                      archiveCallback={archiveCallback}
                      singleLineArchiveTooltip={singleLineArchiveTooltip}
                      language={language}
                    />
                  )}
                  <CloseButton closeCallback={closeCallback} language={language} />
                </HeaderButtonWrapper>
              )}
              {isEditPanelDirty && (
                <HeaderButtonWrapper iconOnly={false}>
                  <ResetButton resetCallback={resetCallback} language={language} />
                  &nbsp;
                  <SaveButton saveCallback={saveCallback} language={language} />
                </HeaderButtonWrapper>
              )}
            </>
          )}
        </HeaderWrapper>
        <ContentWrapper ref={scrollRef}>{children}</ContentWrapper>
      </Wrapper>
      <Modal presenter={confirmModalPresenter} shouldReturnFocusAfterClose={shouldReturnFocusAfterModalClose}>
        <EditPanelDirtyConfirmModal isSaving={editPanelSaving} presenter={editPanelDirtyConfirmModalPresenter} />
      </Modal>
    </>
  )
}
