import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import debounce from 'debounce-promise'
import { uniqBy } from 'lodash'

import { MultiSelect, MultiSelectEnterKeyDownChanges } from '@cutover/react-ui'
import { RecipientMultiselectSkeleton } from './recipient-multiselect-skeleton'
import { RecipientTypeProvider } from './recipient-type-provider'
import { RecipientListItem } from './selected-items'
import {
  isUserRecipient,
  RecipientType,
  RunbookTeamResponse,
  TeamRecipient,
  TeamResponse,
  UserRecipient,
  UserResponse
} from './types'
import { useBulkCreateUserMutation } from '../../services/queries/use-bulk-create-user-mutation'
import { apiClient } from 'main/services/api/api-client'
import { useInitialMount } from 'main/services/hooks'

type RecipientMultiselectBaseProps = {
  runbookId?: number
  runbookVersionId?: number
  accountId: number
  onChange?: (val: RecipientType[] | null) => void
  label?: string
  required?: boolean
  hasError?: boolean
  readOnly?: boolean
  disabled?: boolean
  initialSelected?: RecipientType[]
  autoFocus?: boolean
}

const MIN_CHARS = 2

export type RecipientMultiselectProps = RecipientMultiselectBaseProps &
  // getInitialRecipients is useful for when you have an array of ids to represent
  // users and teams (happens frequently in responses from the API)
  (| {
        getInitialRecipients: true
        initialSelected?: never
        users?: (number | string)[]
        teams?: number[]
      }

    // getInitialRecipients={false} is useful for when you already have the user and team objects and have
    // converted them into RecipientType[]
    | {
        getInitialRecipients?: false
        initialSelected?: RecipientType[]
        users?: never
        teams?: never
      }
  )

export const RecipientMultiselect = forwardRef<{ reset: () => void } | undefined, RecipientMultiselectProps>(
  ({ getInitialRecipients, ...props }, ref) => {
    return getInitialRecipients ? (
      <RecipientTypeProvider
        runbookId={props.runbookId}
        runbookVersionId={props.runbookVersionId}
        users={props?.users}
        teams={props?.teams}
        render={({ isLoading, recipients, isError }) =>
          isLoading || isError ? (
            <RecipientMultiselectSkeleton
              isLoading={isLoading}
              label={props.label || 'Recipient Users and Teams'}
              error={isError ? 'There was an error fetching users and teams' : undefined}
              required
            />
          ) : (
            <RecipientMultiselectBase ref={ref} initialSelected={recipients} {...props} />
          )
        }
      />
    ) : (
      <RecipientMultiselectBase {...props} ref={ref} />
    )
  }
)

const RecipientMultiselectBase = forwardRef<{ reset: () => void } | undefined, RecipientMultiselectBaseProps>(
  (
    {
      runbookId,
      runbookVersionId,
      accountId,
      initialSelected = [],
      onChange,
      required,
      label = 'Recipient Users and Teams',
      readOnly,
      disabled,
      hasError,
      autoFocus
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const isInitialMount = useInitialMount()
    const [selectedRecipients, setSelectedRecipients] = useState<RecipientType[] | null>(initialSelected)

    useEffect(() => {
      if (isInitialMount) return
      onChange?.(selectedRecipients)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRecipients])

    useImperativeHandle(ref, () => ({
      reset: () => setSelectedRecipients(initialSelected)
    }))

    const fetchUsersAndTeams = createFetchUsersAndTeamsFunction({ runbookId, runbookVersionId, accountId })
    const bulkCreateUserMutation = useBulkCreateUserMutation()

    const loadRecipients = debounce(async (query: string) => {
      if (containsBulkInputOrLargeString(query) || query === '' || query.length < MIN_CHARS) {
        return new Promise<RecipientType[]>(res => {
          return res([])
        })
      }

      return await fetchUsersAndTeams(query)
    }, 300)

    const handleOnEnterKeydown = useCallback(
      ({ selectedItem, inputValue }: MultiSelectEnterKeyDownChanges<RecipientType>) => {
        if (!inputValue || selectedItem) {
          return
        }

        const inputForPost = containsBulkInputOrLargeString(inputValue)
          ? parseBulkInputIntoArray(inputValue)
          : [inputValue]

        bulkCreateUserMutation.mutate(
          { accountId, query: inputForPost },
          {
            onSuccess: data => {
              setSelectedRecipients(current => {
                return uniqBy([...(current || []), ...data], (recipient: RecipientType) => {
                  if (isUserRecipient(recipient)) {
                    return `${recipient.id}-${recipient.handle}-${recipient.name}-${recipient.status}`
                  } else {
                    return recipient.id
                  }
                })
              })
            }
          }
        )
      },
      [accountId, bulkCreateUserMutation]
    )

    const getOptionId = (item: RecipientType) => {
      if (isUserRecipient(item)) {
        return `${item.id}-${item.handle}-${item.name}-${item.status}`
      } else {
        return item.id
      }
    }

    return (
      <MultiSelect<RecipientType>
        inputRef={inputRef}
        required={required}
        hasError={hasError}
        readOnly={readOnly}
        disabled={disabled}
        label={label}
        icon="user-add"
        minChars={MIN_CHARS}
        loadOptions={loadRecipients}
        placeholder="Start typing a recipient's name"
        onEnterKeyDown={handleOnEnterKeydown}
        value={selectedRecipients ?? undefined}
        autoFocus={autoFocus}
        valueKey={getOptionId}
        optionToString={(option: RecipientType) => {
          if (option.type === 'team') {
            return option.name
          } else if (option.id) {
            return `${option.firstName} ${option.lastName}`
          } else {
            return `${option.handle}`
          }
        }}
        onChange={(value?: RecipientType[]) => setSelectedRecipients(value ?? null)}
        renderOption={(recipient, renderProps) => {
          return <RecipientListItem item={recipient} {...renderProps} disabled={disabled} />
        }}
      />
    )
  }
)

function containsBulkInputOrLargeString(query: string) {
  return query.includes(',') || query.includes(';') || query.length > 256
}

function parseBulkInputIntoArray(query: string) {
  return [
    ...new Set(
      query
        .replace(/,\s*$/, '')
        .split(/[ ,;]+/)
        .filter(item => item) // removes empty string
    )
  ]
}

function convertUsersToRecipients(users: UserResponse[]): UserRecipient[] {
  return users.map(d => ({
    ...d,
    type: 'user'
  }))
}

function convertTeamsToRecipients(teams: RunbookTeamResponse[] | TeamResponse[] = []): TeamRecipient[] {
  return teams.map((team: RunbookTeamResponse | TeamResponse) => ({
    id: team.id,
    name: team.name,
    color: team.color,
    usersCount: team.usersCount,
    type: 'team'
  }))
}

function createFetchUsersAndTeamsFunction({
  runbookId,
  runbookVersionId,
  accountId
}: {
  runbookId?: number
  runbookVersionId?: number
  accountId?: number
}) {
  return async (query: string): Promise<RecipientType[]> => {
    const fetchUsers = async (): Promise<UserRecipient[]> => {
      const { data } = await apiClient.get<{ users: UserResponse[] }>({
        url: accountId ? `users/list?query=${query}&account_id=${accountId}` : `users/list?query=${query}`,
        convertCase: true
      })
      return convertUsersToRecipients(data.users)
    }

    const fetchTeams = async (): Promise<TeamRecipient[]> => {
      const url =
        runbookId && runbookVersionId
          ? `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/runbook_teams/list?query=${query}`
          : accountId
          ? `teams/list?query=${query}&account_id=${accountId}`
          : `teams/list?query=${query}`
      const { data } = await apiClient.get<
        { runbookTeam: RunbookTeamResponse[]; team: undefined } | { runbookTeam: undefined; team: TeamResponse[] }
      >({
        url,
        convertCase: true
      })

      return convertTeamsToRecipients(data.runbookTeam || data.team)
    }

    const [users, teams] = await Promise.all([fetchUsers(), fetchTeams()])

    return [...users, ...teams]
  }
}
