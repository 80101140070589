import { ReactNode, useId } from 'react'
import { AccordionPanelExtendedProps, AccordionPanel as GrommetAccordionPanel, Heading, HeadingProps } from 'grommet'
import styled, { css } from 'styled-components/macro'

import { Icon, IconName } from '../../icon'
import { resolveColor, themeColor } from '../../theme'
import { ColorProp } from '../../theme/color'
import { Box } from '../placeholder-box'

type AccordionPanelProps = Omit<AccordionPanelExtendedProps, 'header' | 'label' | 'prefix'> & {
  active?: boolean
  error?: boolean // TODO: visual error state
  /** the icon to be rendered to the left of the label text */
  icon?: IconName
  integrationIcon?: React.ReactNode
  /** defaults to iconColor passed into the parent Accordion */
  iconColor?: string // may come from the backend
  label: string
  truncateHeading?: boolean
  headingLevel?: number
  /** further components to be rendered next to the label */
  labelSuffix?: ReactNode
  /** further components to be rendered on the right hand side of the header */
  suffix?: ReactNode
  disabled?: boolean
  hasError?: boolean
  a11yTitle?: string
  'data-testid'?: string
}

export const AccordionPanel = ({
  active,
  icon,
  integrationIcon,
  iconColor,
  label,
  truncateHeading = true,
  headingLevel = 4,
  labelSuffix,
  suffix,
  children,
  disabled,
  hasError,
  ...restProps
}: AccordionPanelProps) => {
  const headingId = useId()
  const groupAriaId = useId()
  const groupId = restProps.id ?? groupAriaId
  const contentId = useId()

  return (
    <>
      {/* @ts-ignore I don't know why the ref type is causing a ts error */}
      <StyledGrommetAccordionPanel
        {...restProps}
        disabled={disabled}
        active={active}
        hasError={hasError}
        aria-disabled={disabled}
        aria-label={restProps.a11yTitle}
        aria-labelledby={`${restProps.a11yTitle ? groupId : ''} ${headingId}`}
        id={groupId}
        label={
          <Box
            direction="row"
            align="center"
            justify="between"
            width="100%"
            // unclear why need to use style here vs css prop for ssr build to succeed (for dashboard email)
            style={{
              cursor: disabled ? 'default' : 'pointer'
            }}
          >
            <Box direction="row" gap="xxsmall" align="center" pad={{ left: 'xsmall' }}>
              {icon && <Icon aria-hidden css="margin-right: 4px" icon={icon} color={iconColor as ColorProp} />}
              {integrationIcon && !icon && <Box margin={{ right: '4px', left: '1px' }}>{integrationIcon}</Box>}
              <AccordionHeading
                disabled={disabled}
                truncate={truncateHeading}
                active={active}
                headingLevel={headingLevel}
                hasError={hasError}
                id={headingId}
                data-testid={label + ' accordion'}
              >
                {label}
              </AccordionHeading>
              {labelSuffix}
            </Box>
            <SuffixContainer>{suffix}</SuffixContainer>
          </Box>
        }
      >
        <Box
          role="region"
          id={contentId}
          tabIndex={-1}
          aria-labelledby={`${restProps.a11yTitle ? groupId : ''} ${headingId} ${contentId}`}
          aria-label="group content"
          pad={{
            right: '4px',
            bottom: '16px',
            left: '4px'
          }}
        >
          {children}
        </Box>
      </StyledGrommetAccordionPanel>
    </>
  )
}

const StyledIcon = styled(Icon)``

export const ExpandIcon = styled(StyledIcon).attrs(() => ({
  icon: 'chevron-right',
  size: 'medium',
  color: 'text-disabled',
  'aria-hidden': true
}))``

export const CollapseIcon = styled(StyledIcon).attrs(() => ({
  icon: 'chevron-down',
  size: 'medium',
  color: 'text-light',
  'aria-hidden': true
}))``

const SuffixContainer = styled(props => <Box {...props} />)`
  fill: ${({ theme }) => resolveColor('text-light', theme)};
  color: ${({ theme }) => resolveColor('text-light', theme)};
  transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  flex-shrink: 0;
`

const StyledHeading = styled(Heading)``

type AccordionHeadingProps = HeadingProps & {
  active?: boolean
  disabled?: boolean
  hasError?: boolean
}

const StyledGrommetAccordionPanel = styled(GrommetAccordionPanel)<AccordionHeadingProps>`
  ${StyledIcon},
  ${StyledHeading},
  & {
    // TODO: should find a better place to define and apply this theme-wide?
    transition: all 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  }

  ${StyledIcon} {
    // if disabled don't show icons
    ${({ disabled }) =>
      disabled &&
      css`
        fill: transparent;
      `}
  }

  &:hover ${StyledHeading} {
    color: ${({ disabled, hasError, theme }) => (hasError ? 'error' : !disabled && resolveColor('text', theme))};
  }

  &:hover ${SuffixContainer} {
    fill: ${({ theme }) => resolveColor('text', theme)};
    color: ${({ theme }) => resolveColor('text', theme)};
  }

  &:hover ${ExpandIcon} {
    fill: ${({ theme, disabled }) => !disabled && resolveColor('text', theme)};
  }
`

const AccordionHeading = styled(StyledHeading).attrs(({ headingLevel }: { headingLevel: number }) => ({
  level: headingLevel,
  color: 'text-light',
  responsive: false,
  // TODO: we don't have 16px as a theme'd t-shirt edge size yet and it likely should be.
  margin: { vertical: 'medium' }
}))<AccordionHeadingProps>`
  font-weight: ${({ active }) => (active ? 500 : 400)};
  font-size: 18px;
  line-height: normal;
  color: ${({ disabled, active, hasError, theme }) =>
    resolveColor(disabled ? 'text-disabled' : hasError ? 'error' : active ? 'text' : 'text-light', theme)} !important;

  button[aria-expanded='true'] & {
    color: ${({ hasError }) => themeColor(hasError ? 'error' : 'text')};
    font-weight: 500;
  }
`
