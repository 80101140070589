import { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  Box,
  Button,
  Menu,
  MenuListItem,
  MenuListItemHeader,
  PlainTextButton,
  Text,
  themeColor
} from '@cutover/react-ui'
import { CaretDown } from '@cutover/icons'
import { useLanguage } from 'main/services/hooks'
import { ActivityConfig } from 'main/services/queries/use-activities'
import { excludedActionFilterOptions, getGroupedFilterOptions } from '../../activity/activity-helper'

export type ActivityFilterPanelProps = {
  showFilterPanel: boolean
  onFilterChange: (filters: string[]) => void
  appliedFilterCount?: number
  activityConfig?: ActivityConfig[]
  onClearAll?: () => void
  initialSelectedKeys?: string[]
  isLiveIncident?: boolean
}

export type OptionSection = {
  key: string
  title: string
  items: { title: string; key: string[] }[]
}

export const ActivityFilterPanel = function ({
  showFilterPanel,
  onFilterChange,
  appliedFilterCount,
  activityConfig = []
}: ActivityFilterPanelProps) {
  const { t } = useLanguage('activities', { keyPrefix: 'filters' })

  const [selectedActivityTypeFilters, setSelectedActivityTypeFilters] = useState<string[]>([])
  const totalSelectedActionFilters = selectedActivityTypeFilters.filter(
    selected => !excludedActionFilterOptions(selected) && !selected.includes('external')
  )
  const totalSelectedAppsFilters = selectedActivityTypeFilters.filter(selected => selected.includes('external'))

  useEffect(() => {
    onFilterChange(selectedActivityTypeFilters)
  }, [selectedActivityTypeFilters])

  useEffect(() => {
    if (appliedFilterCount === 0) {
      setSelectedActivityTypeFilters([])
    }
  }, [appliedFilterCount])

  const { runbook, task, stream, ...external } = getGroupedFilterOptions(activityConfig)

  const handleSelectFilter = (event: any) => {
    setSelectedActivityTypeFilters(prev => {
      if (prev.includes(event.value[0])) {
        return prev.filter(id => event.value.every((val: string) => !id.includes(val)))
      } else {
        return [...prev, ...event.value]
      }
    })
  }

  const handleFilterToggle = (filters: string[]) => {
    setSelectedActivityTypeFilters(prevFilters => {
      const filterExists = filters.some(filter => prevFilters.includes(filter))

      if (filterExists) {
        return prevFilters.filter(filter => !filters.includes(filter))
      } else {
        return [...prevFilters, ...filters]
      }
    })
  }

  return (
    <PanelContainer open={showFilterPanel} flex={false}>
      {showFilterPanel && (
        <>
          <FilterPanelButton
            plain
            label={t('pillLabels.comments')}
            active={!!selectedActivityTypeFilters.includes('runbook.commented')}
            onClick={() => handleFilterToggle(['runbook.commented'])}
          />
          <FilterPanelButton
            plain
            label={t('pillLabels.starred')}
            active={!!selectedActivityTypeFilters.includes('featured')}
            onClick={() => handleFilterToggle(['featured'])}
          />
          <Menu
            menuStyle={{ maxHeight: '300px', overflow: 'auto' }}
            unmountOnClose
            keepOpen
            trigger={
              <FilterPanelButton
                tertiary
                active={totalSelectedActionFilters.length > 0}
                label={
                  <>
                    <Text>{`Actions ${
                      totalSelectedActionFilters.length > 0 ? `(${totalSelectedActionFilters.length})` : ''
                    }`}</Text>{' '}
                    <CaretDown />
                  </>
                }
              />
            }
          >
            {Object.values({ runbook, task, stream }).map(section => (
              <Box key={section.key + '-section'}>
                <MenuListItemHeader data-testid={`item-header-${section.key}`} label={section.title} />
                {section.items.map(item => {
                  const isSelected = selectedActivityTypeFilters.includes(item.key[0])
                  return (
                    <MenuListItem
                      checked={isSelected}
                      type="checkbox"
                      key={item.key[0]}
                      value={item.key}
                      label={item.title}
                      data-testid={`item-${item.key}${isSelected ? '-selected' : ''}`}
                      onClick={handleSelectFilter}
                    />
                  )
                })}
              </Box>
            ))}
          </Menu>
          <Menu
            menuStyle={{ maxHeight: '300px', overflow: 'auto' }}
            unmountOnClose
            keepOpen
            trigger={
              <FilterPanelButton
                tertiary
                active={totalSelectedAppsFilters.length > 0}
                label={
                  <>
                    <Text>{`Apps ${
                      totalSelectedAppsFilters.length > 0 ? `(${totalSelectedAppsFilters.length})` : ''
                    }`}</Text>{' '}
                    <CaretDown />
                  </>
                }
              />
            }
          >
            {Object.values({ ...external }).map(section => (
              <Box key={section.key + '-section'}>
                <MenuListItemHeader data-testid={`item-header-${section.key}`} label={section.title} />
                {section.items.map(item => {
                  const isSelected = selectedActivityTypeFilters.includes(item.key[0])
                  return (
                    <MenuListItem
                      checked={isSelected}
                      type="checkbox"
                      key={item.key[0]}
                      value={item.key}
                      label={item.title}
                      data-testid={`item-${item.key}${isSelected ? '-selected' : ''}`}
                      onClick={handleSelectFilter}
                    />
                  )
                })}
              </Box>
            ))}
          </Menu>
          {selectedActivityTypeFilters.length > 0 && (
            <Box justify="center">
              <PlainTextButton onClick={() => setSelectedActivityTypeFilters([])}>{t('clearAll')}</PlainTextButton>
            </Box>
          )}
        </>
      )}
    </PanelContainer>
  )
}

const PanelContainer = styled(Box)<{ open: boolean }>`
  width: 100%;
  height: ${props => (props.open ? '64px' : 0)};
  flex-direction: row;
  gap: 12px;
  margin-top: ${props => (props.open ? '-16px' : '0')};
  padding-bottom: ${props => (props.open ? '4px' : '0')};
`
const FilterPanelButton = styled(Button)`
  background: ${props => (props.active ? themeColor('bg-3') : themeColor('bg-1'))};
  color: ${props => (props.active ? themeColor('text') : themeColor('text-light'))};
  padding: 10px;
  height: 40px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  line-height: 1;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;

  &:hover {
    color: ${themeColor('text')};
  }
`
