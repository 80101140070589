// @ts-nocheck
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { withPresenter } from 'app/Config/withPresenter'
import { RunbookTypesPagePresenter } from './RunbookTypesPagePresenter'
import {
  PageWrapper,
  PageWrapperInner,
  HeaderPanelWrapper,
  CenterMainWrapper,
  EditPanelWrapper,
  MainPanelWrapper,
  FilterPanelWrapper,
  GenericList
} from 'Components/Templates'
import { RunbookTypesPageHeadingSection } from './RunbookTypesPageHeading/RunbookTypesPageHeadingSection'
import RunbookTypeEditSection from './RunbookTypeEdit/RunbookTypeEditSection'
import { RunbookTypeFilterSection } from './RunbookTypeFilter/RunbookTypeFilterSection'
import { RunbookTypesPageModals } from './RunbookTypesPageModals'
import { AddButton } from 'Components/Atoms'
import { fluidPage } from 'Shared/HooksAndPresenters/FluidPage'

interface IRunbookTypesPageComponentProps {
  presenter: RunbookTypesPagePresenter
}

const RunbookTypesPage = observer<IRunbookTypesPageComponentProps>(({ presenter }) => {
  const {
    editOpen,
    setEditClosed,
    clearEditPanel,
    editLoading,
    filterPanelLoading,
    filterSectionPresenter,
    resetSubmit,
    addFormPresenter,
    editFormPresenter,
    canCreateRunbookType,
    listPresenter,
    headingPresenter,
    loading,
    listVM,
    listVMChangeFunction,
    runbookTypeVM,
    runbookTypeVMChangeFunction,
    runbookTypeEditPresenter,
    archiveModalOpenState,
    setAddFormViewModel,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState,
    language
  } = presenter

  const { archiveModalPresenter, archiveConfirmName, cancelArchiveCallback, continueArchiveCallback, responseErrors } =
    runbookTypeEditPresenter

  const [runbookTypeAddModalOpenState, setRunbookTypeAddModalOpenState] = useState(false)

  const { showAddButton, filterPanelOpen, setFilterPanelOpenState } = fluidPage(
    editOpen,
    setEditClosed,
    checkEditPanelIsDirty,
    angularJSSettingsPanelOpenState
  )

  useEffect(() => {
    listVMChangeFunction()
  }, [listVM])

  useEffect(() => {
    runbookTypeVMChangeFunction()
  }, [runbookTypeVM])

  const addModalCallback = (modalOpen: boolean) => {
    resetSubmit()
    setRunbookTypeAddModalOpenState(modalOpen)
  }

  return (
    <PageWrapper>
      <PageWrapperInner>
        <HeaderPanelWrapper>
          <RunbookTypesPageHeadingSection
            presenter={headingPresenter}
            filterOpen={filterPanelOpen}
            setFilterOpen={setFilterPanelOpenState}
            loading={loading}
          />
        </HeaderPanelWrapper>
        <CenterMainWrapper>
          <FilterPanelWrapper filterPanelOpen={filterPanelOpen} loading={filterPanelLoading}>
            {filterPanelOpen && <RunbookTypeFilterSection presenter={filterSectionPresenter} />}
          </FilterPanelWrapper>
          <MainPanelWrapper filterPanelOpen={filterPanelOpen} extraStyles={{ zIndex: 1 }}>
            <GenericList listPresenter={listPresenter} />
            {canCreateRunbookType && showAddButton && (
              <AddButton
                action={() => {
                  setRunbookTypeAddModalOpenState(true)
                }}
                language={language}
              />
            )}
            <RunbookTypesPageModals
              addModalOpenState={runbookTypeAddModalOpenState}
              setAddModalOpenState={addModalCallback}
              addFormPresenter={addFormPresenter}
              archiveModalPresenter={archiveModalPresenter}
              archiveConfirmName={archiveConfirmName}
              cancelArchiveCallback={cancelArchiveCallback}
              continueArchiveCallback={continueArchiveCallback}
              archiveResponseErrors={responseErrors}
              archiveModalOpenState={archiveModalOpenState}
              setAddFormViewModel={setAddFormViewModel}
              language={language}
            />
          </MainPanelWrapper>
        </CenterMainWrapper>
      </PageWrapperInner>
      <EditPanelWrapper
        isOpen={editOpen}
        loading={editLoading}
        panelCloseCallback={() => {
          clearEditPanel()
        }}
      >
        <RunbookTypeEditSection editFormPresenter={editFormPresenter} />
      </EditPanelWrapper>
    </PageWrapper>
  )
})

export default withPresenter(RunbookTypesPagePresenter)(RunbookTypesPage)
