// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action } from 'mobx'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IRunbookTypePM } from 'app/Shared/Entities/Types/RunbookType/IRunbookTypePM'
import { IRunbookTypeNewPM } from 'Shared/Entities/Types/RunbookType/IRunbookTypeNewPM'
import { IRunbookTypeDTO } from 'Shared/Entities/Types/IRunbookTypeDTO'
import { IApprovalFlowDTO } from 'app/Shared/Entities/Types/ApprovalFlow/IApprovalFlowDTO'

type TRunbookTypeVisibility = 'global' | 'select_accounts'

interface IRunbookTypeUpdateDTO {
  runbook_type: {
    id: number
    name: string
    description: string
    approval_flow_id: number
    available_approval_flows: IApprovalFlowDTO[]
    default: boolean
    archived: boolean
    global: boolean
    account_id: number
    visibility: TRunbookTypeVisibility
    dynamic: boolean
    incident: boolean
    disabled: boolean
  }
}

interface IRunbookTypeResponse {
  success: boolean
  body: {
    runbook_type: IRunbookTypeDTO
  }
}

interface IRunbookTypeCreateDTO {
  runbook_type: {
    visibility: TRunbookTypeVisibility
    name: string
    description: string
    account_id: number
    global?: boolean
    dynamic: boolean
    incident: boolean
    approval_flow_id: number
    available_approval_flows: IApprovalFlowDTO[]
  }
}

@injectable()
export class RunbookTypeRepository extends PermissableRepository {
  @observable
  public runbookTypeForEdit: IRunbookTypePM = null

  public runbookTypeForCreation: IRunbookTypeNewPM = {
    name: '',
    description: '',
    visibilityType: 'global',
    accountId: null,
    dynamic: false,
    incident: false,
    approvalFlowId: null
  }

  private runbookTypeDTO: IRunbookTypeDTO

  @observable
  public archiving: boolean = false

  constructor() {
    super('runbook_type')
  }

  public preLoad = (args: { [key: string]: any }): string => {
    return 'runbook_types/' + args.id
  }

  public postLoad = (rawData: IRunbookTypeResponse) => {
    const runbookType = rawData.body.runbook_type
    this.runbookTypeDTO = runbookType
    this.runbookTypeDTO.available_approval_flows = rawData.body.meta?.available_approval_flows
    this.setProgrammersModel()
  }

  public setProgrammersModel = () => {
    this.runbookTypeForEdit = {
      id: this.runbookTypeDTO.id,
      name: this.runbookTypeDTO.name,
      description: this.runbookTypeDTO.description,
      availableApprovalFlows: this.runbookTypeDTO.available_approval_flows,
      approvalFlowId: this.runbookTypeDTO.approval_flow_id,
      default: this.runbookTypeDTO.default,
      archived: this.runbookTypeDTO.archived,
      visibilityType: this.runbookTypeDTO.account_id ? 'account' : 'global',
      accountId: this.runbookTypeDTO.account_id,
      dynamic: this.runbookTypeDTO.dynamic,
      incident: this.runbookTypeDTO.incident,
      disabled: this.runbookTypeDTO.disabled
    }
  }

  public saveRunbookType = async (): Promise<IBaseResponse> => {
    let runbookDTO: IRunbookTypeUpdateDTO = {
      runbook_type: {
        id: this.runbookTypeForEdit.id,
        name: this.runbookTypeForEdit.name,
        approval_flow_id: this.runbookTypeForEdit.approvalFlowId,
        description: this.runbookTypeForEdit.description,
        default: this.runbookTypeForEdit.default,
        archived: this.runbookTypeForEdit.archived,
        global: this.runbookTypeForEdit.visibilityType === 'global' ? true : false,
        // Retain the account id from initial DTO if converted from account to global
        account_id:
          this.runbookTypeForEdit.visibilityType === 'global'
            ? this.runbookTypeDTO.account_id
            : this.runbookTypeForEdit.accountId,
        visibility: this.runbookTypeForEdit.visibilityType === 'global' ? 'global' : 'select_accounts',
        dynamic: this.runbookTypeForEdit.dynamic,
        incident: this.runbookTypeForEdit.incident,
        disabled: this.runbookTypeForEdit.disabled
      }
    }

    const result = await this.serviceGateway.put('runbook_types/' + this.runbookTypeForEdit.id, runbookDTO)
    if (result.success) {
      this.postLoad(result as IRunbookTypeResponse)
    }
    return result
  }

  public refreshRunbookTypeForEdit = () => {
    this.runbookTypeForEdit = JSON.parse(JSON.stringify(this.runbookTypeForEdit))
  }

  public createRunbookType = async (runbookType: IRunbookTypeNewPM): Promise<IBaseResponse> => {
    let runbookTypeDto: IRunbookTypeCreateDTO = {
      runbook_type: {
        visibility: runbookType.visibilityType === 'global' ? 'global' : 'select_accounts',
        name: runbookType.name,
        description: runbookType.description,
        account_id: runbookType.accountId || null,
        dynamic: runbookType.dynamic,
        incident: runbookType.incident
      }
    }

    if (runbookType.visibilityType === 'global') runbookTypeDto.runbook_type.global = true

    return await this.serviceGateway.post('runbook_types', runbookTypeDto)
  }

  @action
  public clearRunbookTypeForEdit = () => {
    this.runbookTypeForEdit = null
  }

  @action
  public archive = async () => {
    this.archiving = true
    const responseDto = await this.serviceGateway.patch('runbook_types/' + this.runbookTypeForEdit.id + '/archive')
    this.resetRoleTypeToEdit()
    this.archiving = false
    return responseDto
  }

  @action
  public resetRoleTypeToEdit = () => {
    this.runbookTypeForEdit = null
  }
}
