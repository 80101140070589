import { useMemo, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { Button, Pill } from '@cutover/react-ui'
import { RunbookOperation, RunbookSettingsMenu } from './runbook-settings-menu'
import { ScheduledStartRunbookModal } from '../modals/scheduled-start-runbook/scheduled-start-runbook-modal'
import {
  csvImportModalStatus,
  useAccountProperty,
  useRunbookCurrentVersionProperty,
  useRunbookId,
  useRunbookPermission,
  useRunbookProperty,
  useRunbookRunbookType,
  useRunbookVersionId,
  useRunbookVersionProperty,
  useToggleRecoilFavorites
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { CsvImportModal } from '../modals/csv-import/csv-import-modal'
import { MakeTemplateModal } from '../modals/make-template/make-template-modal'
import { RunbookDuplicateModalWrapper } from '../modals/duplicate/runbook-duplicate-modal-wrapper'
import { FavoriteStar } from 'main/components/shared/favorite-star'
import { useRun } from 'main/recoil/runbook/models/runbook-version/run'

export const RunbookToolbar = () => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })

  const slug = useAccountProperty({ attribute: 'slug' })
  const setModalStatus = useSetRecoilState(csvImportModalStatus)
  const [openModal, setOpenModal] = useState<RunbookOperation | undefined>()

  const startScheduled = useRunbookCurrentVersionProperty({ attribute: 'start_scheduled' })
  const run = useRun()
  const hasScheduledStart = !!startScheduled

  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()
  const archived = useRunbookProperty({ attribute: 'archived' })
  const is_template = useRunbookProperty({ attribute: 'is_template' })
  const name = useRunbookProperty({ attribute: 'name' })
  const templateType = useRunbookProperty({ attribute: 'template_type' })

  const [favorite, setFavorite] = useToggleRecoilFavorites({ id: runbookId })
  const isFavorite = !!favorite

  const toggleFavorite = () => {
    if (runbookId) {
      setFavorite(
        favorite || {
          account_slug: slug,
          archived,
          is_template,
          name,
          template_type: templateType,
          id: runbookId
        }
      )
    }
  }

  const handleCloseModal = () => setOpenModal(undefined)
  const handleOpenModal = (type: RunbookOperation) => {
    switch (type) {
      case 'tasks:csv-import':
        setModalStatus('idle')
        return setOpenModal(type)
      default:
        return setOpenModal(type)
    }
  }

  return (
    <>
      <RunbookTitleLabel
        hasScheduledStart={hasScheduledStart}
        onClickSchedule={() => handleOpenModal('runbook:schedule')}
      />
      <RunbookSettingsMenu onClickOption={handleOpenModal} />
      <FavoriteStar
        isFavorite={isFavorite}
        toggleFavorite={toggleFavorite}
        tooltip={{
          addFavorite: t('addFavorite'),
          removeFavorite: t('removeFavorite'),
          placement: 'bottom'
        }}
      />

      {openModal === 'runbook:schedule' && (
        <ScheduledStartRunbookModal startScheduled={startScheduled} onClose={handleCloseModal} />
      )}
      {openModal === 'tasks:csv-import' && (
        <CsvImportModal
          open
          closeModal={handleCloseModal}
          planningMode={!['live', 'rehearsal'].includes(run?.run_type || '')}
          resourceType={templateType === 'snippet' ? 'Snippet' : 'Runbook'}
        />
      )}
      {openModal === 'runbook:make-template' && (
        <MakeTemplateModal onClose={handleCloseModal} runbookId={runbookId} runbookVersionId={runbookVersionId} />
      )}
      {openModal === 'runbook:duplicate' && <RunbookDuplicateModalWrapper closeModal={handleCloseModal} />}
    </>
  )
}

const RunbookTitleLabel = ({
  onClickSchedule,
  hasScheduledStart
}: {
  onClickSchedule: () => void
  hasScheduledStart: boolean
}) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const run = useRunbookVersionProperty({ attribute: 'run' })
  const templateVersion = useRunbookVersionProperty({ attribute: 'template_version' })

  const runType = run?.run_type
  const canUpdateRunbook = useRunbookPermission({ attribute: 'update' })
  const runbookType = useRunbookRunbookType()

  const templateType = useRunbookProperty({ attribute: 'template_type' })
  const templateStatus = useRunbookProperty({ attribute: 'template_status' })
  const sensitiveRunbook = useRunbookProperty({ attribute: 'restricted' })

  const pillProps = useMemo(() => {
    // incident runbook
    if (runbookType?.incident) {
      return sensitiveRunbook
        ? { label: t('sensitiveIncident'), color: 'primary' }
        : { label: t('incident'), color: 'primary' }
    }

    // has run type
    if (!!runType) {
      if (runType === 'live') {
        return runbookType.dynamic
          ? { label: t('liveRunDynamic'), color: 'primary' }
          : { label: t('liveRun'), color: 'primary' }
      } else if (runType === 'rehearsal') {
        return runbookType.dynamic
          ? { label: t('rehearsalDynamic'), color: 'secondary' }
          : { label: t('rehearsal'), color: 'secondary' }
      }
    }

    // template type
    if (templateType === 'snippet') {
      return { label: t('status.snippet'), color: 'secondary' }
    } else if (templateType === 'default') {
      return templateStatus === 'template_approved'
        ? { label: t('status.approved', { version: templateVersion }), color: 'success' }
        : templateStatus === 'template_rejected'
        ? { label: t('status.rejected', { version: templateVersion }), color: 'error' }
        : { label: t('status.draft', { version: templateVersion }), color: 'secondary' }
    }

    return null
  }, [templateStatus, templateType, runbookType, runType, sensitiveRunbook, t])

  return pillProps ? (
    <Pill
      {...pillProps}
      data-testid={'runbook-toolbar-pill-' + pillProps.label}
      css={`
        position: relative;
        top: 6px;
        min-width: fit-content;
        cursor: default;
      `}
    />
  ) : !hasScheduledStart && canUpdateRunbook ? (
    <Button
      primary
      label={t('schedule')}
      onClick={onClickSchedule}
      size="small"
      css={`
        margin-left: 4px;
        top: 1px;
      `}
    />
  ) : null
}
