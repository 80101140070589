import { memo, useCallback, useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilState } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import { keyBy, unescape } from 'lodash'

import { Box, IconButton, ListItem, Pill, Text, useNotify } from '@cutover/react-ui'
import {
  savedFilterState,
  sortFiltersDefaultFirst,
  useDefaultSavedFilter,
  useSavedFiltersActions
} from 'main/recoil/runbook/models/runbook/saved-filters'
import { getSavedViewQueryString } from 'main/components/shared/filter/filter-params'
import { parseSearchString } from '../shared/util'
import { useRunbookPermission } from 'main/recoil/runbook'
import { useCurrentUserId } from 'main/recoil/current-user'
import {
  useDeleteFilter,
  useSetFilterDefault as useUpdateFilterDefault,
  useToggleFilterGlobal as useUpdateFilterGlobal
} from 'main/services/queries/use-filters'
import { useLanguage } from 'main/services/hooks'
import { FormModal } from 'main/components/shared/form'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { buildRecoilSyncURI, encodeReservedCharacters } from 'main/recoil/shared/recoil-sync/recoil-sync-component'
import { SavedFilter } from 'main/services/api/data-providers/runbook-types'

export const SavedFiltersGroup = memo(() => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const [savedFilters, setSavedFilters] = useRecoilState(savedFilterState)
  const defaultFilter = useDefaultSavedFilter()

  useEffect(() => {
    setSavedFilters(sortFiltersDefaultFirst(savedFilters))
  }, [])

  return savedFilters?.length ? (
    <FilterAccordionPanel label={t('title')}>
      <SavedFilterGroupList filters={savedFilters} defaultId={defaultFilter?.id} />
    </FilterAccordionPanel>
  ) : null
})

/* Group content - saved filter list */

const SavedFilterGroupList = ({ filters, defaultId }: { filters: SavedFilter[]; defaultId?: number }) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const filterLookup = keyBy(filters, 'id')
  const orderedIds = filters.map(f => f.id)
  // @ts-ignore - location state isn't typed which makes this rename not work
  const { state: navigationState } = useLocation()
  const [replaceDefaultModalOpen, setReplaceDefaultModalOpen] = useState(false)
  const [changeToDefaultFilterId, setChangeToDefaultFilterId] = useState<number | null>(null)

  const initialActiveId = navigationState?.navDefault ? defaultId ?? null : null
  const stateActiveFilterId = navigationState?.activeFilterId ?? null
  const activeFilterId = navigationState?.navDefault ? initialActiveId : stateActiveFilterId

  const currentUserId = useCurrentUserId()
  const canUpdate = useRunbookPermission({ attribute: 'update' })

  const handleClickApplyFilter = useApplySavedFilter()
  const handleClickDelete = useRemoveSavedFilter()
  const handleClickToggleDefault = useToggleDefaultSavedFilter()
  const handleClickToggleGlobal = useToggleGlobalSavedFilter()

  const onClickDefault = useCallback(
    (filterId: number) => {
      if (defaultId && defaultId !== filterId) {
        setChangeToDefaultFilterId(filterId)
        setReplaceDefaultModalOpen(true)
      } else {
        handleClickToggleDefault(filterId)
      }
    },
    [defaultId, handleClickToggleDefault]
  )

  return (
    <Box role="list" a11yTitle={t('listName')}>
      {orderedIds.map(id => {
        const filter = filterLookup[id]

        return (
          <SavedFilterGroupListItem
            key={id}
            filterId={filter.id}
            name={unescape(filter.name)}
            isSelected={filter.id === activeFilterId}
            isDefault={filter.id === defaultId}
            isGlobal={canUpdate ? filter.global : null}
            onClickFilter={handleClickApplyFilter}
            onClickSetDefault={canUpdate ? onClickDefault : undefined}
            onClickSetGlobal={canUpdate ? handleClickToggleGlobal : undefined}
            onClickDelete={canUpdate || filter.user_id === currentUserId ? handleClickDelete : undefined}
          />
        )
      })}

      {replaceDefaultModalOpen && (
        <ReplaceSavedFilterDefaultModal
          closeModal={() => {
            setReplaceDefaultModalOpen(false)
            setChangeToDefaultFilterId(null)
          }}
          onClickSave={async () => {
            if (changeToDefaultFilterId) {
              await handleClickToggleDefault(changeToDefaultFilterId)
            }
            setReplaceDefaultModalOpen(false)
          }}
        />
      )}
    </Box>
  )
}

const ReplaceSavedFilterDefaultModal = ({
  closeModal,
  onClickSave
}: {
  closeModal: () => void
  onClickSave: () => Promise<void>
}) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters.confirmReplaceDefaultModal' })

  // Using form modal because it handles async
  return (
    <FormModal
      formElementWrapper={false}
      description={t('message')}
      title={t('title')}
      onClose={closeModal}
      focusConfirmButton
      open
      confirmText={t('confirm')}
      onSubmit={async () => {
        await onClickSave()
      }}
    />
  )
}

/* Individual saved filter item */

type SavedFilterGroupListItemProps = {
  filterId: number
  name: string
  isSelected: boolean | null
  isDefault: boolean | null
  isGlobal: boolean | null
  onClickFilter: (filterId: number) => void
  onClickDelete?: (filterId: number) => void
  onClickSetDefault?: (filterId: number) => void
  onClickSetGlobal?: (filterId: number) => void
}

const SavedFilterGroupListItem = memo(
  ({
    filterId,
    name,
    isSelected,
    isDefault,
    isGlobal,
    onClickFilter,
    onClickDelete,
    onClickSetDefault,
    onClickSetGlobal
  }: SavedFilterGroupListItemProps) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters.actionButtonLabels' })

    const isGlobalDefault = isGlobal && isDefault

    return (
      <ListItem
        role="listitem"
        a11yTitle={name}
        endComponents={
          <>
            {isDefault && <Pill label={t('defaultPill')} color="text-light" size="small" />}
            {onClickSetDefault && (
              <IconButton
                label={t('default', { context: isDefault ? 'unset' : 'set' })}
                tip={t('default', { context: isDefault ? 'unset' : 'set' })}
                tipPlacement="top"
                icon={isDefault ? 'pin-filled' : 'pin'}
                onClick={e => {
                  e.stopPropagation()
                  onClickSetDefault(filterId)
                }}
                size="small"
                color="text-light"
              />
            )}
            {onClickSetGlobal && (
              <IconButton
                css={`
                  &:hover {
                    background: ${isGlobalDefault && 'transparent'};
                    cursor: ${isGlobalDefault && 'default'};
                  }
                `}
                label={t('global', { context: isGlobalDefault ? 'default' : isGlobal ? 'private' : 'public' })}
                tip={t('global', { context: isGlobalDefault ? 'default' : isGlobal ? 'private' : 'public' })}
                tipPlacement="top"
                icon={isGlobal ? 'star-filled' : 'star'}
                onClick={e => {
                  e.stopPropagation()

                  if (!isGlobalDefault) {
                    onClickSetGlobal(filterId)
                  }
                }}
                size="small"
                color="text-light"
              />
            )}
          </>
        }
        onClick={() => onClickFilter(filterId)}
        size="small"
        prominence={isSelected ? 'high' : 'default'}
        onClickRemove={onClickDelete ? () => onClickDelete(filterId) : undefined}
        onClickRemoveTooltip={t('remove')}
      >
        <Box gap="xsmall" direction="row" align="center">
          <Text truncate="tip" color="text-light" tipPlacement="right">
            {name}
          </Text>
        </Box>
      </ListItem>
    )
  }
)

/*
  Hooks for list item event handlers.
  Each of these optimistically updates so the UI feels responsive, but will revert to the previous state if the mutation fails
 */

const useApplySavedFilter = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return useRecoilCallback(
    ({ snapshot }) =>
      async (filterId: number) => {
        const snapshotFilters = await snapshot.getPromise(savedFilterState)
        const filter = snapshotFilters.find(f => f.id === filterId)

        const parsedSearch = parseSearchString(filter?.query_string ?? '')
        if (parsedSearch.q) {
          parsedSearch.q = encodeReservedCharacters(parsedSearch.q as string)
        }
        const searchString = getSavedViewQueryString(parsedSearch)
        const search = buildRecoilSyncURI(searchString)

        navigate({ pathname, search }, { state: { activeFilterId: filter?.id, hasActiveFilter: true } })
      },
    [pathname, navigate]
  )
}

const useToggleDefaultSavedFilter = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const notify = useNotify()

  const updateToggleDefaultFilter = useUpdateFilterDefault().mutateAsync
  const { toggleDefaultFilter } = useSavedFiltersActions()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (filterId: number) => {
        const snapshotFilters = await snapshot.getPromise(savedFilterState)
        await toggleDefaultFilter(filterId)

        await updateToggleDefaultFilter(filterId, {
          onSuccess: () => {
            notify.success(t('updateMessage', { context: 'success' }))
          },
          onError: () => {
            notify.error(t('updateMessage', { context: 'error' }))
            set(savedFilterState, snapshotFilters)
          }
        })
      },
    []
  )
}

const useToggleGlobalSavedFilter = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const notify = useNotify()

  const updateToggleGlobalFilter = useUpdateFilterGlobal().mutateAsync
  const { toggleGlobalFilter } = useSavedFiltersActions()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (filterId: number) => {
        const snapshotFilters = await snapshot.getPromise(savedFilterState)
        await toggleGlobalFilter(filterId)

        await updateToggleGlobalFilter(filterId, {
          onSuccess: () => {
            notify.success(t('updateMessage', { context: 'success' }))
          },
          onError: () => {
            notify.error(t('updateMessage', { context: 'error' }))
            set(savedFilterState, snapshotFilters)
          }
        })
      },
    []
  )
}

const useRemoveSavedFilter = () => {
  const { t } = useLanguage('runbook', { keyPrefix: 'savedFilters' })
  const notify = useNotify()

  const deleteFilter = useDeleteFilter().mutateAsync
  const { removeSavedFilter } = useSavedFiltersActions()

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (filterId: number) => {
        const snapshotFilters = await snapshot.getPromise(savedFilterState)
        await removeSavedFilter(filterId)

        await deleteFilter(filterId, {
          onSuccess: () => {
            notify.success(t('deleteMessage', { context: 'success' }))
          },
          onError: () => {
            notify.error(t('deleteMessage', { context: 'error' }))
            set(savedFilterState, snapshotFilters)
          }
        })
      },
    []
  )
}
