import { Box, ListItem, Pill, TaskItemIcon, TaskItemIconProps, Text } from '@cutover/react-ui'
import { stageIconName, taskTypeIcon } from 'main/components/runbook/pages/task-list/task-item/task-list-item-props'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useStreamLoadable, useTaskListTask, useTaskListTask_TaskTypeLoadable } from 'main/recoil/runbook'

export const TaskLineItem = ({
  taskId,
  onClickRemove,
  active,
  hasClickEvent,
  role,
  disabled,
  errors = []
}: {
  errors?: string[]
  taskId: number
  onClickRemove?: () => void
  active?: boolean
  hasClickEvent?: boolean
  role?: string
  disabled?: boolean
}) => {
  const task = useTaskListTask(taskId)
  const { name, stream_id, internal_id, id } = task
  const { openRightPanel } = useSetActiveRightPanelState()
  const { contents: taskType } = useTaskListTask_TaskTypeLoadable(id)
  const { contents: stream } = useStreamLoadable(stream_id)

  const iconProps = {
    color: stream.color,
    icon: taskTypeIcon(taskType.icon, task.stage),
    inProgress: task.stage === 'in-progress',
    isOpaque: task.stage === 'complete',
    stageIcon: stageIconName({
      completionType: task.completion_type,
      stage: task.stage,
      startFixed: task.start_fixed
    })
  }

  return taskType && stream ? (
    <ListItem
      hasError={errors.length > 0}
      tabIndex={undefined}
      size="small"
      startComponent={<TaskItemIcon iconSize="xsmall" {...(iconProps as unknown as TaskItemIconProps)} />}
      endComponents={errors.map(e => (
        <Pill color="error" label={e} />
      ))}
      onClickRemove={onClickRemove}
      active={active}
      onClick={hasClickEvent ? () => openRightPanel({ type: 'task-edit', taskId: id }) : undefined}
      a11yTitle={name}
      role={role ?? 'listitem'}
      disabled={disabled}
    >
      <Box>
        <Text truncate="tip" tipPlacement="top">
          <Text color="text-light">#{internal_id}</Text> {name}
        </Text>
      </Box>
    </ListItem>
  ) : null
}
