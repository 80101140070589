import { ReactNode } from 'react'
import { DirectionType } from 'grommet/utils'

import {
  FieldBox,
  FieldInlineErrorText,
  FieldLabelSuffix,
  FieldLabelText,
  FormFieldBorder,
  FormFieldInlineErrorBox,
  FormFieldLabelBox,
  FormFieldLabelBoxProps,
  HelpText
} from './form-field-components'
import { Icon, IconName } from '../../../icon'
import { Box } from '../../../layout'
import { ColorProp } from '../../../theme'
import { FormFieldBaseProps } from '../../form'

type FormFieldProps = FormFieldBaseProps & {
  children?: ReactNode
  clickable?: boolean
  endComponent?: ReactNode
  labelItems?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  startIcon?: IconName
  truncate?: boolean
  labelProps?: FormFieldLabelBoxProps
  tabIndex?: number
  direction?: DirectionType // TODO: Doesn't make sense for this to live on FormField if it is only applicable to checkbox/radio
  tag?: string // TODO: doesn't make sense for this to live on FormField if it isn't applicable across form fields
  plain?: boolean
  labelSuffix?: string
  labelSuffixColor?: ColorProp
}

export const FormField = ({
  children,
  clickable,
  disabled,
  endComponent,
  hasError,
  inlineError,
  label,
  labelItems,
  onClick,
  required,
  startIcon,
  helpText,
  truncate,
  labelProps = {},
  tabIndex = -1,
  direction,
  tag,
  plain,
  labelSuffix,
  labelSuffixColor
}: FormFieldProps) => {
  const hasIcon = !!startIcon

  const content = (
    <FieldBox truncate={truncate} hasIcon={hasIcon} gap="0" direction={direction}>
      {startIcon && (
        <Box margin={{ left: 'xxsmall' }} flex={false}>
          <Icon icon={startIcon} color={hasError ? 'error' : disabled ? 'text-disabled' : 'text-light'} aria-hidden />
        </Box>
      )}
      <FieldLabelText
        size="small"
        hasError={hasError}
        disabled={disabled}
        hasIcon={hasIcon}
        required={required}
        truncate
      >
        {label}
      </FieldLabelText>
      {labelSuffix && (
        <FieldLabelSuffix color={labelSuffixColor} size="small" data-testid={`${label}-title-suffix`}>
          {labelSuffix}
        </FieldLabelSuffix>
      )}
      {children}
      {(endComponent || helpText) && (
        <Box gap="xxsmall" margin={{ right: 'xxsmall' }} flex={false} direction="row" align="center">
          {endComponent}
          {helpText && <HelpText text={helpText} />}
        </Box>
      )}
    </FieldBox>
  )

  return (
    <FormFieldLabelBox
      {...labelProps}
      clickable={clickable}
      onClick={onClick}
      hasIcon={hasIcon}
      hasError={hasError}
      disabled={disabled}
      width="100%"
      pad={{ top: '13px', bottom: inlineError ? '0' : '17.5px' }}
      tag={tag}
      flex={false}
      tabIndex={tabIndex}
      gap="0"
    >
      {labelItems}
      {plain ? (
        content
      ) : (
        <FormFieldBorder width="100%" disabled={disabled} hasError={hasError} flex={false}>
          {content}
        </FormFieldBorder>
      )}
      {inlineError && (
        <FormFieldInlineErrorBox height={{ min: '17.5px' }}>
          {/* NOTE: This would be 3px from the designs but it is too crowded with the adjacent field labels */}
          <FieldInlineErrorText css="position: relative; top: 1px;">{inlineError}</FieldInlineErrorText>
        </FormFieldInlineErrorBox>
      )}
    </FormFieldLabelBox>
  )
}
