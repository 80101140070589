// @ts-nocheck
import { computed, observable, action } from 'mobx'
import { GenericListPresenter } from 'Components/Templates'
import { ISortParams, ITableColumnTypes, TablePresenter } from 'Shared/Components/Organisms'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { IncidentSeveritiesViewPresenter } from 'app/Settings/IncidentSeverities/IncidentSeveritiesPage/IncidentSeveritiesViewPresenter'
import { IIncidentSeverityPM } from 'Shared/Entities/Types/Incident/IIncidentSeverityPM'

export class IncidentSeveritiesListPresenter implements GenericListPresenter {
  private incidentSeveritiesViewPresenter = container.get(IncidentSeveritiesViewPresenter)

  @observable
  public tableName: string = 'incident-severities-list'

  public context: string = 'incident severity'

  private language: ILanguageService = container.get(Types.ILanguageService)

  public columns: ITableColumnTypes[] = [
    {
      accessor: 'color',
      name: this.language.get('incidentSeverities:list:tableHeadings:color'),
      width: 40,
      dataType: 'string',
      sortable: true
    },
    {
      accessor: 'label',
      name: this.language.get('incidentSeverities:list:tableHeadings:label'),
      width: 180,
      dataType: 'string',
      sortable: true
    }
  ]

  @computed
  public get totalCount() {
    return this.incidentSeveritiesViewPresenter.taskTypesFilteredTotal
  }

  @computed
  public get selectedId(): number {
    return this.incidentSeveritiesViewPresenter.incidentSeverityVM
      ? this.incidentSeveritiesViewPresenter.incidentSeverityVM.id
      : null
  }

  @computed
  public get viewModel(): IIncidentSeverityPM[] {
    return this.incidentSeveritiesViewPresenter.listVM
  }

  @computed
  public get sortParams(): ISortParams {
    return this.incidentSeveritiesViewPresenter.sortParams
  }

  public loadSort = (sortParams: ISortParams) => {
    this.incidentSeveritiesViewPresenter.sortParams = sortParams
    this.incidentSeveritiesViewPresenter.loadIncidentSeverities()
  }

  @action
  public loadRowCallback = (id: number) => {
    if (
      this.incidentSeveritiesViewPresenter.incidentSeverityVM &&
      this.incidentSeveritiesViewPresenter.incidentSeverityVM.id === id
    ) {
      this.incidentSeveritiesViewPresenter.pageVM.editPanelOpen = false
    } else {
      this.incidentSeveritiesViewPresenter.loadIncidentSeverity(id)
    }
  }

  @computed
  public get loading(): boolean {
    return this.incidentSeveritiesViewPresenter.repositoryLoading
  }

  @computed
  public get reloadList() {
    return this.incidentSeveritiesViewPresenter.reloadList
  }

  @computed
  public get filtersCount(): number {
    return this.incidentSeveritiesViewPresenter.filtersCount
  }

  @observable
  public tablePresenter = new TablePresenter(this.viewModel, this.columns, this.tableName, this.viewModel.length)
    .withLoadSortCallback(this.loadSort)
    .withLoadRowCallback(this.loadRowCallback)
    .withVariableWidth()
}
