import { useCallback } from 'react'
import { eventManager } from 'event-manager'
import { useQueryClient } from 'react-query'
import { NavigateFunction, useLocation, useMatch, useNavigate } from 'react-router-dom'

import { useSidebarNavContext } from './nav-context'
import { browserQueryStringToServerQueryObject } from 'main/components/shared/filter/filter-params'
import { useCurrentUser } from 'main/recoil/current-user'
import { SavedView, SavedViewGroupsResponse, useSavedViewGroups } from 'main/services/queries/use-saved-view-groups'

export const useViewPermissions = () => {
  const currentUser = useCurrentUser()
  return currentUser.permissions.views.mycutover as Record<string, boolean>
}

export const useSidebarNavigate = (): ReturnType<typeof useNavigate> => {
  const { leftSidebarSize, toggleLeftSidebar } = useSidebarNavContext()

  const originalNavigate = useNavigate()

  // @ts-ignore fix typing being weird but this is correct
  const navigate: NavigateFunction = useCallback(
    (to, opts) => {
      if (leftSidebarSize === 'full') {
        toggleLeftSidebar(false)
      }
      eventManager.emit('close-angular-right-panel')
      originalNavigate(to, opts)
    },
    [leftSidebarSize]
  )

  return navigate
}

// TODO: fix so don't have to manually type
// TODO: update to only save ID in nav state since using it ony now to ensure fresh data
// when navigating with browser back/forward buttons
export const useActiveSavedView = (accountId?: string | number): SavedView | undefined => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const storedSavedView = (location.state as any)?.activeSavedView

  const savedViewId = storedSavedView?.id

  const existingSavedViewGroupsData = queryClient.getQueryData<SavedViewGroupsResponse>([
    'accounts',
    String(accountId),
    'saved_view_groups'
  ])

  if (!existingSavedViewGroupsData) {
    return storedSavedView
  }

  const savedViews = existingSavedViewGroupsData?.saved_view_groups.flatMap(group => group.saved_views) || []
  const activeSavedView = savedViews.find(savedView => savedView.id === savedViewId)
  return activeSavedView
}

type CanFn = (permission: 'create' | 'update' | 'destroy') => boolean

// Quick and dirty approach for this specific case (which is actually a weird one since it covers saves view and saved view groups?).
// One of the first concrete times we need permission data for the react UI. Need to architect a solution/api for this more globally.
export const useSavedViewPermissions = (accountId: string | number) => {
  const match = useMatch({ path: `/app/${accountId}`, end: false })
  const currentUser = useCurrentUser()
  const queryClient = useQueryClient()

  const storedData = queryClient.getQueryData<SavedViewGroupsResponse>([
    'accounts',
    String(accountId),
    'saved_view_groups'
  ])
  const { data } = useSavedViewGroups(accountId, { enabled: !!match && !storedData })

  const permissionsMeta = storedData?.meta.permissions || data?.meta.permissions

  const canFn: CanFn = useCallback(
    permission => {
      if (!permissionsMeta) return false

      return permissionsMeta[permission].includes(currentUser.id)
    },
    [permissionsMeta, currentUser]
  )

  return canFn
}

const DISPLAY_TYPE = ['list', 'table', 'timeline', 'dashboard'] as const
const WORKSPACE_TYPE = ['events', 'runbooks'] as const

export const useWorkspaceView = () => {
  const location = useLocation()
  const match = useMatch({ path: '/app/:accountSlug/:eventsOrRunbooks/:display', end: false })
  const isEventsOrRunbooksMatch =
    match?.params?.eventsOrRunbooks === 'events' || match?.params?.eventsOrRunbooks === 'runbooks'
  const isDisplayMatch = DISPLAY_TYPE.includes(match?.params?.display as (typeof DISPLAY_TYPE)[number])

  if (isEventsOrRunbooksMatch && isDisplayMatch) {
    return {
      display: match?.params?.display as (typeof DISPLAY_TYPE)[number],
      type: match?.params?.eventsOrRunbooks as (typeof WORKSPACE_TYPE)[number],
      filters: browserQueryStringToServerQueryObject({ query: location.search }),
      accountSlug: match?.params?.accountSlug
    }
  }
}
