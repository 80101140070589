import { DefaultValue, RecoilState, RecoilValueReadOnly, selector, useRecoilValue } from 'recoil'
import { produce } from 'immer'

import { Filter } from 'main/services/queries/use-filters'
import { runbookMetaState } from './runbook'

type SavedFilter = Filter & {
  account_id?: number
  created_at: string
  updated_at: string
  resource_id: number
  runbook_id?: number
  show_dashboard: boolean
}

type SavedFilerState = {
  addSavedFilter: (filter: SavedFilter) => Promise<void>
  removeSavedFilter: (id: number) => Promise<void>
  toggleDefaultFilter: (id: number) => Promise<void>
  toggleGlobalFilter: (id: number) => Promise<void>
}

export const savedFilterState: RecoilState<SavedFilter[]> = selector({
  key: 'saved-filter:list',
  get: ({ get }) => {
    const runbookMeta = get(runbookMetaState)
    return runbookMeta.filters
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) return newValue

    const prevRunbookMetaState = get(runbookMetaState)
    const nextRunbookMetaState = produce(prevRunbookMetaState, draft => {
      draft.filters = newValue
    })

    set(runbookMetaState, nextRunbookMetaState)
  }
})

const savedFiltersActions: RecoilValueReadOnly<SavedFilerState> = selector({
  key: 'saved-filters:actions',
  get: ({ getCallback }) => {
    /**
      Adds the newly created saved filter, updating the stored source list in the runbook meta
    */
    const addSavedFilter = getCallback(({ set, snapshot }) => async (filter: SavedFilter) => {
      const existingFilters = await snapshot.getPromise(savedFilterState)

      const updatedSavedFilters = produce(existingFilters, draft => {
        const existingFilter = draft.findIndex(f => f.id === filter.id)
        if (existingFilter >= 0) {
          draft[existingFilter] = filter
        } else {
          draft.push(filter)
        }
        return draft
      })

      set(savedFilterState, updatedSavedFilters)
    })

    /**
     Removes the deleted saved filter from the stored source list in the runbook meta
    */
    const removeSavedFilter = getCallback(({ set, snapshot }) => async (id: number) => {
      const existingFilters = await snapshot.getPromise(savedFilterState)
      const updatedSavedFilters = existingFilters.filter(f => f.id !== id)

      set(savedFilterState, updatedSavedFilters)
    })

    /**
     Toggles fiilter's default state. Sets default on all others to false if it is now default and makes it global if it is default
    */
    const toggleDefaultFilter = getCallback(({ set, snapshot }) => async (id: number) => {
      const existingFilters = await snapshot.getPromise(savedFilterState)
      const updatedSavedFilters = produce(existingFilters, draft => {
        const existingFilterIndex = draft.findIndex(f => f.id === id)
        const existingFilter = draft[existingFilterIndex]

        if (existingFilter && existingFilter.default) {
          draft[existingFilterIndex].default = false
        } else if (existingFilter) {
          draft.forEach(f => (f.default = false))
          draft[existingFilterIndex].default = true
          draft[existingFilterIndex].global = true
        }
      })

      set(savedFilterState, updatedSavedFilters)
    })

    /**
     Toggles a filter's global state
    */
    const toggleGlobalFilter = getCallback(({ set, snapshot }) => async (id: number) => {
      const existingFilters = await snapshot.getPromise(savedFilterState)
      const updatedSavedFilters = existingFilters.map(f => ({ ...f, global: f.id === id ? !f.global : f.global }))

      set(savedFilterState, updatedSavedFilters)
    })

    return {
      addSavedFilter,
      toggleDefaultFilter,
      toggleGlobalFilter,
      removeSavedFilter
    }
  }
})

export const defaultSavedFilterState = selector({
  key: 'saved-filters:default',
  get: ({ get }) => {
    return get(savedFilterState).find(filter => filter.default)
  }
})

export const useDefaultSavedFilter = () => {
  return useRecoilValue(defaultSavedFilterState)
}

export const useSavedFiltersActions = () => {
  return useRecoilValue(savedFiltersActions)
}

export const useHasSavedFilters = () => {
  return useRecoilValue(savedFilterState).length > 0
}

export const sortFiltersDefaultFirst = (f: SavedFilter[]) =>
  produce(f, draft => draft.sort((a, b) => (a.default && !b.default ? -1 : 0)))
