import { forwardRef, ReactNode } from 'react'

import { Text } from '../../typography/text'
import { Box } from '../../layout/box'

type ListItemInnerProps = {
  startComponent?: ReactNode
  endComponents?: ReactNode[] | ReactNode
  children?: ReactNode
  prominence?: 'high' | 'default' | 'low'
  title?: string
  subTitle?: string
  hasError?: boolean
}

export const ListItemInner = forwardRef<HTMLElement, ListItemInnerProps>(
  ({ startComponent, endComponents, children, prominence, title, subTitle, hasError }) => {
    return (
      <>
        {startComponent && <Box flex={false}>{startComponent}</Box>}
        <Box
          css={`
            flex: 1;
          `}
          alignSelf="center"
        >
          <Text truncate tag="div" weight={prominence === 'high' ? 600 : 400} color={hasError ? 'error' : 'text'}>
            {children || title}
          </Text>
          {subTitle && (
            <Text truncate tag="div" size="xsmall" color={hasError ? 'error' : 'text'}>
              {subTitle}
            </Text>
          )}
        </Box>
        {endComponents}
      </>
    )
  }
)
