// @ts-nocheck
import { action } from 'mobx'
import { container } from 'app/Config/IOC'
import { IncidentRepository } from 'app/Repositories/IncidentRepository'
import { injectable } from 'inversify'

@injectable()
export class WebSocketMessageProcessor {
  private incidentRepository: IncidentRepository = container.get(IncidentRepository)

  @action
  public processMessage(_channel: string, message: { [key: string]: any }) {
    // TODO: We might want to find a better mechanism to determine what path
    //       should be taken here. It should be fine in the short to mid-term
    //       as we only want to do repository updates for now.
    if (message.incident) {
      this.incidentRepository.updateData(message.incident)
    }
  }
}
