import { useMemo } from 'react'

import { useInstanceName } from './use-instance-name'
import { useCurrentUser } from '../../recoil/current-user'

export const useUserAnonymization = () => {
  const currentUser = useCurrentUser()
  const instanceName = useInstanceName()

  return useMemo(() => {
    const id = currentUser?.id || 'anonymous'
    const anonReferrerId = currentUser?.invited_by_id ? `${instanceName}-${currentUser.invited_by_id}` : ''
    const anonUserId = `${instanceName}-${id}`
    return { anonUserId, anonUserEmail: `${anonUserId}@cutover.com`, anonReferrerId }
  }, [instanceName, currentUser])
}
