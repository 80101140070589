import { useEffect, useRef } from 'react'
import { DefaultValue, selector, selectorFamily, useSetRecoilState } from 'recoil'
import { isEqual } from 'lodash'

import { filterSelector, useAppliedFilters } from 'main/recoil/shared/filters'

export const useCustomFieldFilterState = (id: number) => {
  const setCustomFieldProperty = useSetRecoilState(customFieldFilterSelector({ attribute: id }))
  const value = useCustomMemoizedFieldFilterValue(id) as any

  return [value, setCustomFieldProperty] as const
}

export const useCustomFieldAppliedFiltersState = () => {
  const { f: cfFilters } = useAppliedFilters()
  return cfFilters
}

const useCustomMemoizedFieldFilterValue = (id: number) => {
  const cfFilters = useCustomFieldAppliedFiltersState()
  const cfFiter = cfFilters?.[id]
  const memoizedVal = useRef<any>(cfFiter)

  useEffect(() => {
    if (!isEqual(cfFiter, memoizedVal.current)) {
      memoizedVal.current = cfFiter
    }
  }, [cfFiter])

  return !isEqual(memoizedVal.current, cfFiter) ? cfFiter : memoizedVal.current
}

// ************************************

const customFieldAppliedFilterLookup = selector({
  key: 'custom-fields:filter-lookup',
  get: ({ get }) => {
    const resp = get(filterSelector({ attribute: 'f' })) as unknown as Record<number, any>
    return resp
  }
})

const customFieldFilterSelector = selectorFamily({
  key: 'filters:custom-field',
  get:
    ({ attribute: id }: { attribute: number }) =>
    ({ get }) => {
      const customFieldsFilters = get(customFieldAppliedFilterLookup)
      return customFieldsFilters?.[id]
    },
  set:
    ({ attribute: id }: { attribute: number }) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) return

      set(filterSelector({ attribute: 'f' }), (prev: any) => {
        if (
          newValue === null ||
          newValue === undefined ||
          newValue instanceof DefaultValue ||
          (Array.isArray(newValue) && newValue.length === 0)
        ) {
          const { [id]: _, ...rest } = prev ?? {}
          return rest
        }

        return {
          ...prev,
          [id]: newValue
        }
      })
    }
})
