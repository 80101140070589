import { memo, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { MultiSelectControl } from '@cutover/react-ui'
import { FilterAccordionPanel } from '../shared/filter-accordion-panel'
import { accountTaskTypes } from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { useTaskTypeFilter } from 'main/recoil/shared/filters'

const INTEGRATIONS_APPS_KEY = 'integrationsapps'
const IAI_KEY = 'integration-action-item'

export const TaskTypeGroup = memo(() => {
  const { t } = useLanguage('common', { keyPrefix: 'filter' })
  const [value = [], setValue] = useTaskTypeFilter()
  const taskTypes = useRecoilValue(accountTaskTypes)

  const options = useMemo(() => {
    const filteredTaskTypes = taskTypes.filter(tt => !tt.key.includes(INTEGRATIONS_APPS_KEY))
    return filteredTaskTypes.map(tt => {
      const label = tt.key.includes(IAI_KEY) ? tt.integration_action_items[0]?.name : tt.name
      return {
        label: `${label} ${tt.archived ? t('disabledIndicator') : ''}`,
        value: tt.id
      }
    })
  }, [taskTypes])

  return (
    <FilterAccordionPanel label={t('taskType')} applied={value && !!value.length}>
      <MultiSelectControl
        a11yTitle={t('taskType')}
        value={(value as any[])?.filter(v => v?.value !== 0 && v?.value !== '*' && v?.value !== null)}
        onChange={val => {
          // need to preserve none checked if it was also selected
          if (!val || val.length === 0) {
            setValue(prevVal => (prevVal?.includes(0) ? [0] : undefined))
          } else {
            const nextVals = val?.map(v => v.value)
            setValue(prevVal => (prevVal?.includes(0) ? [0, ...nextVals] : nextVals))
          }
        }}
        options={options}
        plain
      />
    </FilterAccordionPanel>
  )
})
