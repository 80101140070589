import { memo, Suspense, useState } from 'react'

import { LoadingPanel } from '@cutover/react-ui'
import { PeoplePanel, PeoplePanelProps, PeoplePanelScreen } from './people-panel/people-panel'
import { ValidationRunbookTeam } from './people-panel/types'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import {
  useAccountId,
  useRunbookId,
  useRunbookPermission,
  useRunbookProperty,
  useRunbookVersionId,
  useRunbookVersionMetaProperty,
  useRunbookVersionPermission
} from 'main/recoil/runbook'
import { useAccountViewPermissions } from 'main/services/api/data-providers/account/account-data'

export const UserOrTeamDetailsPanel = memo(() => {
  const [{ userOrTeam, initialScreen }, { closeRightPanel }] = useRightPanelTypeState('user-team-details-edit')
  const runbookId = useRunbookId()
  const runbookVersionId = useRunbookVersionId()

  return (
    <>
      {(userOrTeam || initialScreen) && runbookId && runbookVersionId && (
        <Suspense fallback={<LoadingPanel />}>
          <EditUserOrTeamDetails userOrTeam={userOrTeam} onClose={closeRightPanel} initialScreen={initialScreen} />
        </Suspense>
      )}
    </>
  )
})

type EditUserOrTeamDetailsProp = {
  userOrTeam: any
  onClose: () => void
  initialScreen?: PeoplePanelScreen
}

// NOTE: connecting to existing people panel
const EditUserOrTeamDetails = memo(({ userOrTeam, onClose, initialScreen }: EditUserOrTeamDetailsProp) => {
  const accountId = useAccountId()
  const runbookVersionId = useRunbookVersionId()
  const panelScreen = initialScreen || (userOrTeam?.hasOwnProperty('team_id') ? 'team-details' : 'user-details')
  const streams = useRunbookVersionMetaProperty({ attribute: 'streams' })
  const runbookTeams = useRunbookVersionMetaProperty({ attribute: 'runbook_teams' })

  const id = useRunbookProperty({ attribute: 'id' })
  const name = useRunbookProperty({ attribute: 'name' })
  const templateType = useRunbookProperty({ attribute: 'template_type' })

  const runbook = { id, name, templateType } as PeoplePanelProps['runbook']

  // TODO: check if this is team create vs other create permissions
  const canCreateTeam = useRunbookVersionPermission({ attribute: 'create' })
  const canUpdateRunbook = useRunbookPermission({ attribute: 'update' })
  const { viewPermissions: accountViewPermissions } = useAccountViewPermissions() // TODO: recoil?
  const canUpdateCentralTeams = !!accountViewPermissions?.teams

  const [selectedTab, setSelectedTab] = useState<string>('users')

  return (
    <PeoplePanel
      accountId={accountId}
      runbook={runbook}
      runbookVersionId={runbookVersionId}
      permissions={{
        canCreateTeam,
        canUpdateRunbook,
        canUpdateCentralTeams
      }}
      onTabSelect={(tab: string) => setSelectedTab(tab)}
      runbookTeams={runbookTeams as ValidationRunbookTeam[]}
      screen={panelScreen}
      selectedUser={panelScreen === 'user-details' && userOrTeam}
      selectedTeam={panelScreen === 'team-details' && userOrTeam}
      streams={streams}
      onClose={onClose}
      selectedTab={selectedTab ?? 'users'}
    />
  )
})
