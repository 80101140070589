import { atom, useRecoilState } from 'recoil'

import { RunbookEditRunbook } from 'main/services/queries/types'

export const runbookEditUpdatedRunbook = atom<RunbookEditRunbook>({
  key: 'runbook-edit:updated-runbook',
  default: undefined
})

export const useRunbookEditUpdatedRunbook = (): [RunbookEditRunbook, (newValue: RunbookEditRunbook) => void] => {
  const [updatedRunbook, setUpdatedRunbook] = useRecoilState<RunbookEditRunbook>(runbookEditUpdatedRunbook)

  return [updatedRunbook, setUpdatedRunbook]
}
