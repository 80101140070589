// @ts-nocheck
import { injectable } from 'inversify'
import { observable, action } from 'mobx'
import { PermissableRepository } from 'app/Shared/Repositories/PermissableRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IIncidentSeverityNewPM } from 'Shared/Entities/Types/Incident/IIncidentSeverityNewPM'
import { IIncidentSeverityDTO } from 'Shared/Entities/Types/IIncidentSeverityDTO'
import { IIncidentSeverityVM } from 'Shared/Entities/Types/Incident/IIncidentSeverityVM'

interface IIncidentSeverityResponse {
  success: boolean
  body: {
    incident_severity: IIncidentSeverityDTO
  }
}

interface IIncidentSeverityCreateDTO {
  incident_severity: {
    label: string
    color?: string
  }
}

@injectable()
export class IncidentSeverityRepository extends PermissableRepository {
  @observable
  public incidentSeverityForCreation: IIncidentSeverityNewPM = {
    label: ''
  }

  private incidentSeverityDTO: IIncidentSeverityDTO

  @observable
  public selectedId: number = null

  @observable
  public incidentSeverityForEdit: IIncidentSeverityVM = null

  constructor() {
    super('incident_severity')
  }

  public preLoad = (args: { [key: string]: any }): string => {
    this.selectedId = args.id
    return 'incident_severities/' + args.id
  }

  public postLoad = (rawData: IIncidentSeverityResponse) => {
    const incidentSeverity = rawData.body.incident_severity
    this.incidentSeverityDTO = incidentSeverity
    this.setProgrammersModel()
  }

  public createIncidentSeverity = async (incidentSeverity: IIncidentSeverityNewPM): Promise<IBaseResponse> => {
    let incidentSeverityDto: IIncidentSeverityCreateDTO = {
      incident_severity: {
        label: incidentSeverity.label,
        color: incidentSeverity.color
      }
    }

    return await this.serviceGateway.post('incident_severities', incidentSeverityDto)
  }

  public setProgrammersModel = () => {
    this.incidentSeverityForEdit = {
      id: this.incidentSeverityDTO.id,
      key: this.incidentSeverityDTO.key,
      label: this.incidentSeverityDTO.label,
      color: this.incidentSeverityDTO.color,
      archived: this.incidentSeverityDTO.archived
    }
  }

  public refreshIncidentSeverityForEdit = () => {
    this.incidentSeverityForEdit = JSON.parse(JSON.stringify(this.incidentSeverityForEdit))
  }

  @action
  public clearIncidentSeverityForEdit = () => {
    this.incidentSeverityForEdit = null
  }

  public saveIncidentSeverity = async (): Promise<IBaseResponse> => {
    const { label, archived, color, id, key } = this.incidentSeverityForEdit
    const result = await this.serviceGateway.put('incident_severities/' + id, {
      label,
      archived,
      color,
      id,
      key
    })
    if (result.success) this.postLoad(result as IIncidentSeverityResponse)
    return result
  }
}
