// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { IncidentSeverityFormPresenter } from './IncidentSeverityFormPresenter'
import { TextInput, ErrorAlert, ColorPicker } from 'Components/Molecules'
import { px2rem } from 'app/Styles/Variables'

const FieldWrapper = styled.div`
  margin-bottom: ${px2rem(16)};
`

interface IIncidentSeverityForm {
  presenter: IncidentSeverityFormPresenter
  formType?: 'add' | 'edit'
}

export const IncidentSeverityFormComponent = observer<IIncidentSeverityForm>(({ presenter, formType }) => {
  const { errorAlertPresenter, submitted, formIsValid } = presenter

  return (
    <form>
      {submitted && !formIsValid && <ErrorAlert presenter={errorAlertPresenter} formAlert />}
      <FieldWrapper>
        <TextInput presenter={presenter.labelPresenter} parentIsFocused={formType === 'add'} formTopInputElement />
      </FieldWrapper>
      <FieldWrapper>
        <ColorPicker presenter={presenter.colorPickerPresenter} textInputStyle={{ marginTop: px2rem(12) }} />
      </FieldWrapper>
    </form>
  )
})
