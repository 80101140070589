// @ts-nocheck
import { observable, computed, action } from 'mobx'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { container } from 'app/Config/IOC'
import { Types } from 'Gateways/Service/Types'
import { GenericFormPresenter } from '@logicroom/validator'
import { ColorPickerPresenter, ErrorAlertPresenter, IColor, TextInputPresenter } from 'Components/Molecules'
import { AccountVisibilityPresenter } from 'Components/Organisms'

export interface IFormViewModel {
  label: string
  color?: IColor
}

export class IncidentSeverityFormPresenter {
  private language: ILanguageService = container.get(Types.ILanguageService)
  private initialColor = {
    r: 181,
    g: 181,
    b: 181,
    a: 1
  }

  @observable
  public visibilityPresenter: AccountVisibilityPresenter = new AccountVisibilityPresenter()

  @observable
  public viewModel: IFormViewModel = {
    label: '',
    color: this.initialColor
  }

  @observable
  public unavailableNames: string[] = []

  @observable
  public errorAlertPresenter: ErrorAlertPresenter = new ErrorAlertPresenter()

  @observable
  public submitted: boolean = false

  private dirtyCallback: () => void

  @action
  public withDirtyCallback = (dirtyCallback: () => void) => {
    this.dirtyCallback = dirtyCallback
    this.visibilityPresenter.withDirtyCallback(dirtyCallback)
    return this
  }

  @action
  private colorChangeMiddleware = (newValue: IColor): any => {
    this.viewModel.color = newValue
    this.colorPickerPresenter.togglePicker()
    if (this.dirtyCallback) {
      this.dirtyCallback()
    }

    return newValue
  }

  @observable
  public labelPresenter: TextInputPresenter = new TextInputPresenter()
    .withLabel(this.language.get('incidentSeverities:fields:name:label'))
    .isRequiredTrimWhiteSpace(this.language.get('incidentSeverities:fields:name:errors:required'))
    .withMiddleware((value: string) => {
      this.viewModel.label = value

      if (this.dirtyCallback) {
        this.dirtyCallback()
      }

      return value
    })

  @observable
  public colorPickerPresenter: ColorPickerPresenter = new ColorPickerPresenter(this.language, this.initialColor)
    .withLabel(this.language.get('incidentSeverities:fields:name:color'))
    .withMiddleware(this.colorChangeMiddleware)

  @observable
  public editForm: GenericFormPresenter = new GenericFormPresenter()
    .addFormInput(this.labelPresenter)
    .addFormInput(this.colorPickerPresenter)

  @action
  public processSubmission = (): { result: boolean; viewModel: IFormViewModel } => {
    this.submitted = true
    this.labelPresenter.value = this.viewModel.label

    if (!this.formIsValid) {
      this.labelPresenter.isDirty = true
      if (this.editForm.errorMessages.length > 0) {
        this.errorAlertPresenter.setErrorMessages([this.editForm.errorMessages[0]])
      }
      return {
        result: false,
        viewModel: this.viewModel
      }
    }

    return {
      result: true,
      viewModel: this.viewModel
    }
  }

  @computed
  public get formIsValid(): boolean {
    return this.editForm.isValid
  }

  @action
  private processViewModel = () => {
    this.labelPresenter.value = this.viewModel.label
    if (this.viewModel.color) {
      this.colorPickerPresenter.value = this.viewModel.color
    }
  }

  @action
  public withUnavailableNames = (unavailableNames: string[]) => {
    this.unavailableNames = unavailableNames
    this.labelPresenter.isUnique(
      this.unavailableNames,
      this.language.get('incidentSeverities:fields:name:errors:notUnique')
    )
    return this
  }

  @action
  public withViewModel = (viewModel: IFormViewModel) => {
    this.viewModel = viewModel
    this.processViewModel()
    return this
  }

  @action
  public refresh = () => {
    this.reset()
    this.processViewModel()
  }

  @action
  public reset = () => {
    this.submitted = false
    this.labelPresenter.reset()
    this.colorPickerPresenter.reset()
    this.editForm.reset()
  }
}
