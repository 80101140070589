import { memo, Ref, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { AvatarSubject, DependencyType, TaskItem, useNotify } from '@cutover/react-ui'
import {
  getAccountTaskType,
  useCanStartTaskWhenStartable,
  useGetTaskItemPermissions,
  useIsTaskActionModalTask,
  useLoadingIdValue,
  useMenu,
  userIdToTeamLookup,
  useRunbook,
  useRunbookCurrentVersion,
  useRunbookPermission,
  useRunbookVersion,
  useSelectedIdsValue,
  useSetMenuState,
  useSetModalActiveState,
  useSetSelectedIds,
  useStream,
  useTaskListTask,
  useTaskListTaskTeams,
  useTaskListTaskUsers,
  useTaskProgression,
  useTaskProgressionState,
  useToggleTaskCreateState
} from 'main/recoil/runbook'
import { useLanguage } from 'main/services/hooks'
import { useToggleRightPanel } from 'main/components/layout/right-panel'
import { runbookComponentsStateLookup } from 'main/recoil/runbook/models/runbook-version/runbook-components'
import { TaskItemCreateToggle } from './task-item-create'
import { useTaskListItemProps } from './task-list-item-props'
import { useBuildTaskActionMenuItems, useBuildTaskDependencyMenuItems } from './use-task-list-item-menu-builder'
import { useTaskListItemIntegrationStatus } from './use-task-list-item-integration-status'
import { useDropAssignment } from 'main/recoil/runbook/models/tasks/use-drop-assign'
import { useTaskCommentsCount } from 'main/recoil/runbook/models/runbook/runbook-comments'

type TaskContentProps = {
  id: number
  critical?: boolean
  float?: number
  previousTaskId?: number
  nextTaskId?: number
  isEditing?: boolean
}

// TODO: remove when transaction updates have been made to recoil data updating
export const TaskListItem = memo(({ id, ...props }: TaskContentProps) => {
  const task = useTaskListTask(id)
  if (!task) return null
  return <TaskListItemInner id={id} {...props} />
})

const MIN_DEPENDENCY_MENU_WIDTH = 200
const MAX_DEPENDENCY_MENU_WIDTH = 320
const MAX_DEPENDENCY_MENU_HEIGHT = 280

const TaskListItemInner = (props: TaskContentProps) => {
  const { isEditing, id, critical, float, previousTaskId, nextTaskId } = props
  const first = !previousTaskId

  const [isOptionsMenuOpen, setOptionsMenuOpen] = useState<boolean>(false)
  const { setMenu, clearMenu, setMenuOpenState } = useSetMenuState()
  const { menu, isMenuOpen } = useMenu()
  const { taskId, type } = menu
  const buildTaskActionMenuItems = useBuildTaskActionMenuItems()
  const buildTaskDependencyMenuItems = useBuildTaskDependencyMenuItems()
  const notify = useNotify()
  const isActioning = useIsTaskActionModalTask(id)
  const isUpdating = useLoadingIdValue(id)
  const taskProgressionState = useTaskProgressionState(id)
  const togglePeoplePanel = useToggleRightPanel(
    'user-team-details-edit',
    (openPanel, openingPanel) => openPanel.userOrTeam?.id === openingPanel.userOrTeam?.id
  )
  const toggleCommentsPanel = useToggleRightPanel('runbook-comments')
  const toggleTaskEditPanel = useToggleRightPanel('task-edit')
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const { modalOpen } = useSetModalActiveState()
  const [_, toggleTaskCreate] = useToggleTaskCreateState()

  const { selectedIdToggle, selectedIdsRemoveAll } = useSetSelectedIds()
  const selected = useSelectedIdsValue().includes(id)
  const runbookVersion = useRunbookVersion()
  const task = useTaskListTask(id)
  const stream = useStream(task.stream_id)
  const runbook = useRunbook()
  const runbookCurrentVersion = useRunbookCurrentVersion()
  const commentsCountState = useTaskCommentsCount(task.internal_id)
  const parentStream = useStream(stream.parent_id || 0)
  const previousTask = useTaskListTask(previousTaskId || id)
  const previousTaskType = useRecoilValue(getAccountTaskType(previousTask?.task_type_id))
  const runbookComponentLookup = useRecoilValue(runbookComponentsStateLookup)
  const runbookUserTeamLookup = useRecoilValue(userIdToTeamLookup)
  const taskType = useRecoilValue(getAccountTaskType(task.task_type_id))
  const teams = useTaskListTaskTeams(id)
  const userCanUpdate = useRunbookPermission({ attribute: 'update' })
  const users = useTaskListTaskUsers(id)
  const {
    getCanDisplayCreateAfterFromIcon: getTaskShowCreateAfterFromIcon,
    getCanProgressTask: getTaskCanProgress,
    getCanUpdateTask,
    getTaskHasStartedSuccessors
  } = useGetTaskItemPermissions(id)
  const canProgressTask = getTaskCanProgress()
  const canUpdateTask = getCanUpdateTask()
  const canStartWhenStartable = useCanStartTaskWhenStartable(id)

  const taskItemProps = useTaskListItemProps({
    showCreateAfter: getTaskShowCreateAfterFromIcon(),
    canUpdate: userCanUpdate,
    critical,
    commentsCountState,
    float,
    first,
    iconDisabled: !canStartWhenStartable,
    nextTaskId,
    parentStream,
    previousTask,
    previousTaskType,
    run: runbookVersion.run,
    runbookComponent: task.runbook_component_id ? runbookComponentLookup[task.runbook_component_id] : undefined,
    runbookCurrentVersion,
    runbookUserTeamLookup,
    runbookVersion,
    runbook,
    stream,
    task,
    taskType,
    teams,
    users
  })
  const { resolveProgressionModalRecoilCallback, startOrFinishTask, onSkipTasks } = useTaskProgression()
  const { handleDropAssign, isLoadingAvatar, isDropPermitted } = useDropAssignment({ canUpdateTask, teams, users })

  const { integrationActionItem, integrationOptions, integrationStatusProps } = useTaskListItemIntegrationStatus(
    task
  ) ?? {
    integrationActionItem: undefined,
    integrationOptions: {},
    integrationStatusProps: undefined
  }

  const handleOptionsClick = useRecoilCallback(() => async (triggerRef: Ref<HTMLElement>) => {
    const menuItems = await buildTaskActionMenuItems({ task, integrationActionItem, integrationOptions })
    if (taskId === id && type === 'options') {
      clearMenu()
    } else {
      setMenu({
        taskId: id,
        triggerRef,
        type: 'options',
        keyPrefix: 'task-opts-menu',
        items: menuItems
      })
      setMenuOpenState(true)
    }
  })

  const handleDependenciesClick = useRecoilCallback(
    () => async (triggerRef: Ref<HTMLElement>, dependencyType: DependencyType) => {
      const menuItems = await buildTaskDependencyMenuItems({ task, type: dependencyType })
      if (taskId === id && type === dependencyType) {
        clearMenu()
      } else {
        setMenu({
          taskId: id,
          triggerRef,
          type: dependencyType,
          items: menuItems,
          minWidth: MIN_DEPENDENCY_MENU_WIDTH,
          maxWidth: MAX_DEPENDENCY_MENU_WIDTH,
          maxHeight: MAX_DEPENDENCY_MENU_HEIGHT
        })
        setMenuOpenState(true)
      }
    }
  )
  useEffect(() => {
    setOptionsMenuOpen(taskId === id && isMenuOpen && type === 'options')
  }, [taskId, isMenuOpen])

  const handleClickTaskItem = useCallback(() => {
    toggleTaskEditPanel({ taskId: id })
  }, [id])

  const handleBulkTaskSelection = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      const nativeEvent = e.nativeEvent as MouseEvent
      selectedIdToggle(id, nativeEvent.shiftKey)
    },
    [selectedIdToggle, id]
  )

  const onAdd = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleTaskCreate({ fromPredecessorId: id })
    },
    [toggleTaskCreate, id]
  )

  const hasHoverAddIcon = taskItemProps.iconProps.hoverIcon === 'add'
  const clickable = hasHoverAddIcon || canProgressTask || taskProgressionState?.override === 'fixed-start'

  const handleClickTaskIcon = useCallback(
    async (e: SyntheticEvent) => {
      e.stopPropagation()
      // We need to do this check here because we still want to show the add icon if all other checks to show it pass
      // so we can show specific messaging for this limitation vs just disabling click.
      if (hasHoverAddIcon) {
        const hasStartedSuccessors = getTaskHasStartedSuccessors()
        if (hasStartedSuccessors) {
          notify.warning(t('startedSuccessorWarning.message'), { title: t('startedSuccessorWarning.title') })
        } else onAdd(e)
        return
      }
      const nextModal = await resolveProgressionModalRecoilCallback(id)
      return nextModal ? modalOpen(nextModal) : startOrFinishTask(id)
    },
    [resolveProgressionModalRecoilCallback, id, hasHoverAddIcon, getTaskHasStartedSuccessors]
  )

  const handleCommentsClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation()
    toggleCommentsPanel({ taskId: id, taskInternalId: task.internal_id })
  }, [])

  const handleSkipClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      selectedIdsRemoveAll()
      onSkipTasks([id])
    },
    [selectedIdsRemoveAll, id]
  )

  const handleClickAvatar = useCallback(
    (e: SyntheticEvent, subject?: AvatarSubject) => {
      e.stopPropagation()
      togglePeoplePanel({ userOrTeam: subject })
    },
    [togglePeoplePanel]
  )

  const handleErrorClick = useCallback(() => {}, [])

  const handleDrop = (e: React.DragEvent) => {
    if (!isDropPermitted(e)) {
      return false
    }

    handleDropAssign({ taskId: task.id, data: JSON.parse(e.dataTransfer.getData('text')) })
  }

  return (
    <>
      <TaskItem
        {...taskItemProps}
        isOptionsMenuOpen={isOptionsMenuOpen}
        draggable={false}
        highlight={isEditing}
        isLoadingTeams={isLoadingAvatar}
        onChangeSelect={handleBulkTaskSelection}
        onClick={handleClickTaskItem}
        onClickAvatar={handleClickAvatar}
        onClickComments={handleCommentsClick}
        onClickError={handleErrorClick}
        onClickOptions={handleOptionsClick}
        onClickDependencies={handleDependenciesClick}
        onClickSkip={handleSkipClick}
        onDrop={handleDrop}
        selected={selected}
        editable={canUpdateTask}
        updating={isUpdating}
        iconProps={{
          ...taskItemProps.iconProps,
          isLoading: isUpdating || isActioning,
          onClick: clickable ? handleClickTaskIcon : undefined
        }}
        labelCommentsButton={t('comments', { count: commentsCountState || task.comments_count })}
        labelEarly={t('taskStartDiffText.early')}
        labelLate={t('taskStartDiffText.late')}
        labelMenuButton={t('moreOptions')}
        labelSelectCheckbox={t('selectTask')}
        labelSkipButton={t('skipTaskButtonLabel')}
        labelStarted={taskItemProps.started ? t('taskStartDiffText.forecastToStart') : t('taskStartDiffText.started')}
        integrationStatusProps={integrationStatusProps}
      />
      <TaskItemCreateToggle predecessorId={id} />
    </>
  )
}
