// @ts-nocheck
import { IIncidentDTO, IIncidentPM } from 'app/Repositories/IncidentRepository'

export const mapRawIncidentToPM = (rawIncident: IIncidentDTO): IIncidentPM => {
  return {
    id: rawIncident.id,
    name: rawIncident.name,
    startedAt: rawIncident.started_at,
    closedAt: rawIncident.closed_at,
    conferenceUrl: rawIncident.conference_url,
    description: rawIncident.description,
    severity: rawIncident.incident_severity
      ? {
          color: rawIncident.incident_severity.color,
          id: rawIncident.incident_severity.id,
          key: rawIncident.incident_severity.key,
          label: rawIncident.incident_severity.label
        }
      : undefined,
    status: rawIncident.incident_status
      ? {
          id: rawIncident.incident_status.id,
          key: rawIncident.incident_status.key,
          label: rawIncident.incident_status.label,
          type: rawIncident.incident_status.status_type
        }
      : undefined,
    type: rawIncident.incident_type
      ? {
          id: rawIncident.incident_type.id,
          key: rawIncident.incident_type.key,
          label: rawIncident.incident_type.label,
          iconName: rawIncident.incident_type.icon_name,
          iconColor: rawIncident.incident_type.icon_color
        }
      : undefined
  }
}
