import { memo, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { eventManager } from 'event-manager'

import { Box, Button, LoadingPanel, useInitialMount } from '@cutover/react-ui'
import { CamelToSnakeCaseNested } from '@cutover/utility-types'
import { getAvailableIntegrations, usePostAppEvents } from './apps-api'
import { AppsComponent } from './apps-component'
import { AppsComponentParser } from './apps-component-parser'
import { AppsManagementModal } from './apps-management-modal'
import { AppsModal } from './apps-modal'
import { AppModalViewState, AppView, AppViews, ContentNode, SelectedApp } from './apps-types'
import { PanelNode as Panel } from './nodes'
import { appHeaderView, appModal, appViews } from 'main/recoil/apps/apps-atoms'
import { appViewWithContext, runbookAppViewsWithOrder } from 'main/recoil/apps/selectors'
import { useLanguage } from 'main/services/hooks'
import { RunbookIntegration } from 'main/services/queries/types'

const APP_INTEGRATION_ACTION = 'Integrations::Apps::MountPoint'

type IntegrationLink = CamelToSnakeCaseNested<RunbookIntegration & { order: number }>

// 'preloadApps' is used for testing purposes, bypassing app initialization which
// prevents intializeState to be properly resolved in RTL tests.
export const AppsContainer = ({ appPosition = 'panel' }: { appPosition?: string }) => {
  const { t } = useLanguage('apps')
  const { runbookId } = useParams()
  const postAppEvents = usePostAppEvents()
  const [availableApps, setAvailableApps] = useState<SelectedApp[] | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const [isTemplate, setIsTemplate] = useState<boolean | undefined>(false)
  const setAppViews = useSetRecoilState<AppViews>(appViews)
  const [modal, setModal] = useRecoilState<AppModalViewState>(appModal)
  const orderedAppViews = useRecoilValue(runbookAppViewsWithOrder(runbookId as string))
  const headerAppView = useRecoilValue(appHeaderView)
  const isInitialMount = useInitialMount()

  useEffect(() => {
    const handleRunbookData = ({
      runbook
    }: {
      permissions: { [x: string]: number[] }
      runbook: { is_template: boolean }
    }) => {
      setIsTemplate(runbook.is_template)
    }
    eventManager.on('runbook-data', handleRunbookData)

    return () => {
      eventManager.off('runbook-data', handleRunbookData)
    }
  }, [])

  useEffect(() => {
    // fresh load of page, remove any previous modals
    if (isInitialMount) {
      setModal({ view: undefined, appId: '', resourceId: '', open: false })
    }

    if (runbookId && !isTemplate && appPosition === 'panel') {
      const getAvailableApps = async () => {
        const apps: SelectedApp[] = []
        const { integration_links } = await getAvailableIntegrations(runbookId)
        integration_links.map((link: Partial<IntegrationLink>) => {
          if (link.id && link.integration_action_item?.integration_action === APP_INTEGRATION_ACTION) {
            if (link.integration_action_item.settings.location === 'Panel') {
              const app: SelectedApp = {
                appId: link.integration_action_item.key,
                resourceId: runbookId,
                order: link.order || 1, // Defaults to first in list of apps if no order is given
                name: link.integration_action_item.name,
                visible: true
              }
              apps.push(app)
            } else if (link.integration_action_item.settings.location === 'Header') {
              postAppEvents({
                runbook_id: runbookId,
                app_id: link.integration_action_item.key
              })
            }
          }
        })

        // Avoid unnecessary update to recoil state
        if (apps.length > 0) {
          setAvailableApps(apps)
        }
      }
      getAvailableApps()
    }
  }, [runbookId, isTemplate])

  useEffect(() => {
    if (availableApps) {
      handleAvailableAppsChange()
    }
  }, [availableApps])

  const handleAvailableAppsChange = () => {
    setAppViews(prevAppViews => {
      const updatedAppViews: AppViews = {}
      availableApps?.forEach(selectedApp => {
        const context = `${runbookId}-${selectedApp.appId}`
        const prevAppView = prevAppViews[context]

        // Previously loaded so just update visibility
        if (prevAppView) {
          const updatedAppView = { ...prevAppView, visible: selectedApp.visible }
          updatedAppViews[context] = updatedAppView
          // Init this app
        } else if (selectedApp.visible) {
          updatedAppViews[context] = {
            appId: selectedApp.appId,
            resourceId: selectedApp.resourceId,
            content: [],
            visible: selectedApp.visible,
            order: selectedApp.order
          }

          postAppEvents({
            runbook_id: runbookId,
            app_id: selectedApp.appId
          })
        }
      })
      return { ...prevAppViews, ...updatedAppViews }
    })
  }

  return (
    <>
      {appPosition === 'panel' && (
        <Box
          fill
          flex={false}
          background="bg-1"
          css={`
            position: absolute;
            top: 0;
            left: 0;
            height: 'calc(100vh - 72px)';
            overflow: auto;
            padding: 16px 0;
          `}
        >
          <Box
            css={`
              height: 100%;
            `}
          >
            <Box
              flex={false}
              gap="medium"
              css={`
                margin-bottom: 16px;
              `}
            >
              {orderedAppViews.map(
                app =>
                  app.visible && (
                    <AppsContainerInner key={`app-id-${app.appId}`} appId={app.appId} resourceId={app.resourceId} />
                  )
              )}
            </Box>
            <AppsModal modal={modal} setModal={setModal} />
            <AppsManagementModal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onClickConfirm={(availableApps: SelectedApp[] | undefined) => {
                setAvailableApps(availableApps)
              }}
              availableApps={availableApps}
            />
            <Box
              align="center"
              justify="center"
              pad="small"
              css={`
                margin-top: auto;
              `}
            >
              <Button
                icon="add"
                label={t('manageApps')}
                secondary
                onClick={() => {
                  setIsOpen(true)
                }}
                css={`
                  width: 100%;
                `}
              />
            </Box>
          </Box>
        </Box>
      )}
      {appPosition === 'header' && (
        <Box style={{ height: '100%' }} flex={false} gap="medium" data-apps-view-id={headerAppView.view?.id}>
          <AppsComponentParser
            content={headerAppView.view?.content as ContentNode[]}
            appId={headerAppView.appId}
            resourceId={runbookId}
          />
        </Box>
      )}
    </>
  )
}

type AppsContainerInnerProps = {
  appId: string
  resourceId?: string
}

export const AppsContainerInner = memo(({ appId, resourceId }: AppsContainerInnerProps) => {
  const context = `${resourceId}-${appId}`
  const view = useRecoilValue(appViewWithContext(context))
  if (!view) {
    return null
  }
  const appLoading = isEmpty(view.content)
  const viewContent = view.content && (
    <AppsComponentParser content={view.content as ContentNode[]} appId={appId} resourceId={resourceId} />
  )

  return (
    <Box
      css={`
        display: ${view?.visible ? 'block' : 'none'};
      `}
    >
      {appLoading ? (
        <Panel header={{ title: '' }} appId={appId} resourceId={resourceId}>
          <LoadingPanel />
        </Panel>
      ) : (
        <Box data-apps-view-id={view.id}>
          <AppsComponent node={view as AppView} appId={appId} resourceId={resourceId}>
            {viewContent}
          </AppsComponent>
        </Box>
      )}
    </Box>
  )
})
