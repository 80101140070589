import { CSSProperties, HTMLProps, isValidElement, memo, MutableRefObject } from 'react'
import { FloatingContext, FloatingFocusManager, FloatingPortal } from '@floating-ui/react'

import { ListItem, OverlayBox } from '@cutover/react-ui'
import { CSSDimensionConstraints } from '@cutover/react-ui/src/theme'
import { useLanguage } from 'main/services/hooks'
import { TaskShortcutMenuItem } from './use-task-list-item-menu-builder'

type TaskItemCreateMenuProps = {
  isMenuOpen: boolean
  menuItems?: TaskShortcutMenuItem[]
  getFloatingProps: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>
  getItemProps: (userProps?: Omit<HTMLProps<HTMLElement>, 'selected' | 'active'> | undefined) => Record<string, unknown>
  context: FloatingContext
  setFloating: (node: HTMLElement | null) => void
  floatingStyles: CSSProperties
  listRef: MutableRefObject<(HTMLElement | null)[]>
  activeIndex: number | null
  onSelectMenu: ({ activeIndex, item }: { activeIndex?: number; item?: TaskShortcutMenuItem }) => void
  constraints?: CSSDimensionConstraints
}

export const TaskItemCreateMenu = memo(
  ({
    isMenuOpen,
    menuItems,
    getFloatingProps,
    getItemProps,
    context,
    setFloating,
    floatingStyles,
    listRef,
    activeIndex,
    onSelectMenu,
    constraints
  }: TaskItemCreateMenuProps) => {
    const { t } = useLanguage('runbook', { keyPrefix: 'taskList' })

    if (!menuItems) return null

    return (
      <>
        {isMenuOpen && (
          <FloatingPortal>
            <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
              <OverlayBox
                {...getFloatingProps({
                  ref: setFloating,
                  style: {
                    ...floatingStyles
                  }
                })}
                constraints={constraints}
                css={`
                  z-index: 1;
                  border: 1px solid rgba(0, 0, 0, 0.05);
                  overflow: auto;
                `}
              >
                {menuItems && menuItems.length > 0 ? (
                  <>
                    {menuItems.map((item, index) => {
                      const { label, icon, props } = item
                      return (
                        <ListItem
                          {...getItemProps({
                            key: `task-create-menu-${index}`,
                            ref(node) {
                              listRef.current[index] = node
                            }
                          })}
                          onMouseDown={e => {
                            e.preventDefault()
                            onSelectMenu?.({ item })
                          }}
                          aria-selected={activeIndex === index}
                          active={activeIndex === index}
                          size="small"
                          title={label}
                          level={props?.stream?.parent_id ? 2 : 1}
                          startComponent={isValidElement(icon) && icon}
                        ></ListItem>
                      )
                    })}
                  </>
                ) : (
                  <ListItem size="small">{t('noItemsFound')}</ListItem>
                )}
              </OverlayBox>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </>
    )
  }
)
