// @ts-nocheck
import ActionCable, { Cable, Channel } from 'actioncable'
import { container } from 'app/Config/IOC'
import { injectable } from 'inversify'
import { WebSocketMessageProcessor } from './WebSocketMessageProcessor'

/**
 * Gateway class to manage WebSocket connections using ActionCable.
 *
 * A WebSocket connection is initiated on-demand, if there isn't one active
 * already, limiting the necessity to manage connections outside of
 * subscriptions.
 */

interface ISubscriptionOptions {
  received?: (data: any) => void
  disconnected?: () => void
  connected?: () => void
}
@injectable()
export class ActionCableGateway {
  private consumer: Cable = null
  private subscriptions: { [key: string]: Channel } = {}

  private webSocketMessageProcessor: WebSocketMessageProcessor = container.get(WebSocketMessageProcessor)

  public get connected(): boolean {
    return this.consumer !== null
  }

  public get disconnected(): boolean {
    return !this.connected
  }

  public connect() {
    // We only want one connection and not invalidate any existing subscriptions
    if (this.connected) {
      return
    }

    this.consumer = ActionCable.createConsumer(this.webSocketUrl)
  }

  public disconnect() {
    if (this.consumer) {
      this.consumer.disconnect()
    }
  }

  public subscribe(channel: string, id?: any, options: ISubscriptionOptions = {}) {
    const channelName = [channel, id].join('')

    // Connect on-demand
    if (this.disconnected) {
      this.connect()
    }

    // Don't resubscribe to an already subscribed channel
    if (this.subscriptions[channelName] !== undefined) {
      return
    }

    // data: { id } is for the older websockets e.g. runbook
    this.subscriptions[channelName] = this.consumer.subscriptions.create(
      { channel, id, data: { id } },
      {
        received: data => {
          this.processData(channel, data)
        },
        ...options
      }
    )
  }

  public unsubscribe(channel: string, id?: any) {
    const channelName = [channel, id].join('')

    if (this.consumer && this.subscriptions[channelName]) {
      this.subscriptions[channelName].unsubscribe()

      delete this.subscriptions[channelName]
    }
  }

  /**
   * Pre-process the data and send it off to the {WebSocketMessageProcessor} for
   * actioning.
   *
   * @param channel Channel on which the data was received on
   * @param data Payload data
   */
  private processData(channel: string, data: any) {
    try {
      if (typeof data === 'string') {
        data = JSON.parse(data)
      }

      this.webSocketMessageProcessor.processMessage(channel, data)
    } catch (e) {
      console.error(`Couldn't parse payload from WebSocket message (${channel}):`, e)
    }
  }

  private get webSocketUrl(): string {
    const hostname = window.location.hostname
    let protocol = 'wss'
    let port = ''

    if (hostname === 'localhost') {
      protocol = 'ws'
      port = ':8080'
    }

    return `${protocol}://${hostname}${port}/cable`
  }
}
