// @ts-nocheck
import { injectable } from 'inversify'
import { GenericRecordRepository } from 'app/Repositories/GenericRecordRepository'
import { action, observable, when } from 'mobx'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { mapRawIncidentToPM } from 'Shared/Helpers/IncidentMapper'

export type TStatusType = 'open' | 'closed'

interface ISeverityDTO {
  color: string
  id: number
  key: string
  label: string
}

interface IStatusDTO {
  id: number
  key: string
  label: string
  status_type: TStatusType
}

interface IIncidentTypeDTO {
  id: number
  key: string
  label: string
  icon_color: string
  icon_name: string
}
export interface IIncidentDTO {
  account_id: number
  conference_url: string
  description: string
  id: number
  incident_severity: ISeverityDTO
  incident_status: IStatusDTO
  incident_type: IIncidentTypeDTO
  name: string
  started_at: string
  closed_at: string
}

// TODO: Very verbose. Zach has some good ideas about automating this but in the meantime ...
export interface ISeverityPM {
  color: string
  id: number
  key: string
  label: string
}

export interface IStatusPM {
  id: number
  key: string
  label: string
  type: TStatusType
}

interface IIncidentTypePM {
  id: number
  key: string
  label: string
  iconName: string
  iconColor: string
}

export interface IIncidentPM {
  id: number
  name: string
  startedAt: string
  closedAt: string
  conferenceUrl: string
  description: string
  severity?: ISeverityPM
  status?: IStatusPM
  type?: IIncidentTypePM
}

@injectable()
export class IncidentRepository extends GenericRecordRepository {
  protected url: string = 'incidents'

  public closedType: string = 'closed'

  @observable
  public incidentPM: IIncidentPM = null

  @observable
  public loadedIncidentId: number = null

  public safeLoad = async (incidentId: number) => {
    if (incidentId === this.loadedIncidentId) return

    if (this.loading) {
      await when(() => !this.loading)
      return this.safeLoad(incidentId)
    } else {
      this.loadData({ id: incidentId })
    }
  }

  public preLoad = (args: { [key: string]: any }) => {
    return this.url + '/' + args.id
  }

  public createRecord = async programmersModel => {
    return await this.serviceGateway.post(this.url, {
      // TODO: Implement
      incident: {}
    })
  }

  public saveAttributes = async (programmersModel, attributes: { [key: string]: any }): Promise<boolean> => {
    const result = await this.serviceGateway.put(this.url + '/' + programmersModel.id, {
      incident: attributes
    })

    if (result.success) {
      this.postLoad(result)
    }

    return result.success
  }

  public saveRecord = async programmersModel => {
    const result = await this.serviceGateway.put(this.url + '/' + programmersModel.id, {
      // TODO: Implement
      incident: {}
    })
    if (result.success) {
      this.postLoad(result)
    }
    return result
  }

  public postLoad = (rawData: IBaseResponse) => {
    if (!rawData.success) {
      return
    }

    this.setProgrammersModel(rawData.body.incident)
  }

  public updateData(data) {
    this.setProgrammersModel(data)
  }

  @action
  private setProgrammersModel = (incidentDto: IIncidentDTO) => {
    this.loadedIncidentId = incidentDto.id
    this.incidentPM = mapRawIncidentToPM(incidentDto)
  }
}
