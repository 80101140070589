import { QueryCache, useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { QueryKeys } from './query-keys'
import {
  FieldValue,
  FieldValuesAttributes,
  RagStatus,
  Role,
  RunbookEditRunbook,
  RunbookListRunbook,
  RunbookTemplateStatus,
  RunbookTimingMode
} from './types'
import { RunbookPermittedResource } from './use-permitted-resources'
import { apiClient, ApiError } from 'main/services/api/api-client'
import { RunbookUpdateResponse } from '../api/data-providers/runbook-types'

export type RunbookUpdatePayload = {
  runbook: RunbookUpdateRunbook
  shift_time: boolean
  timezone: string | null
  ai_generated_description: boolean
}

type RunbookUpdateRunbook = {
  id: number
  account_id: number | null
  auto_start?: boolean
  description: string
  field_values_attributes?: Omit<Partial<FieldValuesAttributes>, 'id'>[]
  field_values?: FieldValue[]
  is_template: boolean
  name: string
  project_id: number
  roles: Role[]
  runbook_type_id: number
  runbook_versions_attributes: RunbookVersionsAttribute[]
  settings_substreams_inherit_color: boolean
  settings_task_description_on_task_start: boolean
  settings_team_in_task_list: boolean
  status_message: string | null
  status: RagStatus
  template_next_review: number | null
  template_status: RunbookTemplateStatus | null
  timezone: string | null
  timing_mode: RunbookTimingMode
}

type RunbookVersionsAttribute = {
  id: number
  title: string
  start_scheduled: string | null
  end_scheduled: string | null
  start_planned: string | null
  end_planned: string | null
  auto_start: boolean
}

// used for typing below
const queryCacheStub = new QueryCache()

export const useUpdateRunbook = (
  options: UseMutationOptions<RunbookUpdateResponse, ApiError, RunbookUpdatePayload> = {}
) => {
  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const queryClient = useQueryClient()
  const existingEditRunbookKey = (runbookId: number) => [
    QueryKeys.Accounts,
    accountSlug,
    QueryKeys.Runbooks,
    String(runbookId),
    'edit'
  ]

  return useMutation<RunbookUpdateResponse, ApiError, RunbookUpdatePayload>(
    async payload => {
      const response = await apiClient.put<RunbookUpdatePayload, RunbookUpdateResponse>({
        url: `runbooks/${payload.runbook.id}`,
        data: payload
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    },
    {
      ...options,
      onMutate: (payload: RunbookUpdatePayload) => {
        options.onMutate?.(payload)
        const permittedResources = queryClient.getQueryData<RunbookPermittedResource>([
          QueryKeys.Accounts,
          payload.runbook.account_id,
          'permitted_resources',
          { resource: 'runbook' }
        ])

        // @ts-ignore not sure how else to get the queryCache in order to get the query key for the infinite query
        const cache = queryClient.queryCache as typeof queryCacheStub
        const existingListRunbooksQuery = cache.find<{
          pages: { runbooks: RunbookListRunbook[] }[]
          pageParams: any[]
        }>([accountSlug, QueryKeys.Runbooks], {
          stale: true,
          exact: false
        })
        const existingListRunbooks = existingListRunbooksQuery?.state.data

        // NOTE: if paginated runbooks are sorted by updated at, while the content of the list item will update immediately,
        // its position in the list will only update after the mutation resolves and the list query is invalidated.
        if (existingListRunbooks) {
          const pages = existingListRunbooks.pages.map(page => {
            const runbook = page.runbooks?.find(rb => rb.id === Number(payload.runbook.id))
            if (runbook) {
              // TODO: Here update whatever attributes possible from form data (like name, description, etc. -- anything non-computed)
              // NOTE: partially dups the logic below for updating runbook but they are different runbook types
              runbook.project_id = payload.runbook.project_id
              runbook.name = payload.runbook.name

              const projectName = permittedResources?.projects?.find(p => p.id === payload.runbook.project_id)?.name
              if (projectName) {
                runbook.project_name = projectName
              }
            }
            return page
          })

          queryClient.setQueryData(existingListRunbooksQuery.queryKey, {
            pages,
            pageParams: existingListRunbooks.pageParams
          })
        }

        const existingEditRunbook = queryClient.getQueryData<RunbookEditRunbook>(
          existingEditRunbookKey(payload.runbook.id)
        )

        if (existingEditRunbook) {
          // TODO: Here update whatever attributes possible from form data (like name, description, etc. -- anything non-computed)
          // NOTE: partially dups the logic above for updating runbook but they are different runbook types
          const updatedRunbook = {
            ...existingEditRunbook,
            name: payload.runbook.name,
            project_id: payload.runbook.project_id
          }
          queryClient.setQueryData(existingEditRunbookKey(payload.runbook.id), updatedRunbook)
        }
      },
      onSettled: (data, error, variables, context) => {
        options?.onSettled?.(data, error, variables, context)

        queryClient.invalidateQueries([accountSlug, QueryKeys.Runbooks])
        if (data) {
          queryClient.invalidateQueries(existingEditRunbookKey(data.runbook.id))
        }
        // options.onSettled?.(data, error, variables, context)
      }
    }
  )
}
