// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { IncidentSeveritiesViewPresenter } from '../IncidentSeveritiesViewPresenter'
import { Types } from 'Gateways/Service/Types'
import { observable, computed, action } from 'mobx'
import { IncidentSeverityFormPresenter } from '../IncidentSeverityForm/IncidentSeverityFormPresenter'
import { ToasterService } from 'Components/Molecules'
import { TMode } from 'Components/Templates'
import { ModalPresenter } from 'Shared/Components/Atoms'
import tinycolor2 from 'tinycolor2'

@injectable()
export class IncidentSeverityEditPresenter {
  private incidentSeveritiesViewPresenter: IncidentSeveritiesViewPresenter = container.get(
    IncidentSeveritiesViewPresenter
  )

  private language: ILanguageService = container.get(Types.ILanguageService)

  public title: string = this.language.get('incidentSeverities:edit:title')

  @observable
  public incidentSeverityFormPresenter: IncidentSeverityFormPresenter

  @observable
  public isClosing: boolean = false

  @observable
  public isSaving: boolean = false

  @observable
  public isClearing = false

  public confirmModalPresenter: ModalPresenter = new ModalPresenter()

  private toasterService: ToasterService = container.get(ToasterService)

  @computed
  public get mode(): TMode {
    const {
      loading,
      incidentSeverityVM,
      pageVM: { editPanelDirty }
    } = this.incidentSeveritiesViewPresenter
    if (loading) return 'loading'
    if (this.isClearing) return 'panel-closed'
    if (incidentSeverityVM !== null) {
      return editPanelDirty ? 'panel-open-confirm' : 'panel-open'
    }

    return 'panel-closed'
  }

  @computed
  public get canArchive(): boolean {
    return false
  }

  @action
  public withIncidentSeverityFormPresenter = (incidentSeverityFormPresenter: IncidentSeverityFormPresenter) => {
    this.incidentSeverityFormPresenter = incidentSeverityFormPresenter
    return this
  }

  @action
  public save = async () => {
    this.isSaving = true
    const result = this.incidentSeverityFormPresenter.processSubmission()
    if (result.result) {
      const { viewModel } = result
      this.incidentSeveritiesViewPresenter.updateIncidentSeverityPM('label', viewModel.label)
      this.incidentSeveritiesViewPresenter.updateIncidentSeverityPM('color', `#${tinycolor2(viewModel.color).toHex()}`)

      const response = await this.incidentSeveritiesViewPresenter.saveIncidentSeverity()
      if (!response.success) {
        if (response.errorMessages) {
          this.incidentSeverityFormPresenter.editForm.serverErrors = response.errorMessages
          this.incidentSeverityFormPresenter.errorAlertPresenter.setErrorMessages([...response.errorMessages])
        }
        this.incidentSeveritiesViewPresenter.submitted = false
      } else {
        this.toasterService.pop(
          this.language.get('incidentSeverities:edit:notification:title'),
          this.language.get('incidentSeverities:edit:notification:notification'),
          'success'
        )
        this.incidentSeveritiesViewPresenter.loadIncidentSeverities()
        this.incidentSeveritiesViewPresenter.pageVM.editPanelDirty = false
        this.incidentSeverityFormPresenter.refresh()
      }
    } else {
      this.incidentSeveritiesViewPresenter.submitted = false
    }
    this.isSaving = false
  }

  @action
  public onClose = () => {
    this.incidentSeveritiesViewPresenter.pageVM.editPanelOpen = false
  }

  @action
  public reset = () => {
    this.incidentSeveritiesViewPresenter.pageVM.editPanelDirty = false
    this.incidentSeverityFormPresenter.reset()
    this.incidentSeveritiesViewPresenter.resetIncidentSeverityForEdit()
  }
}
