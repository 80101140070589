import { useMutation, useQuery } from 'react-query'

import { FieldValuesAttributes, RunbookIntegration } from './types'
import { apiClient, ApiError } from 'main/services/api/api-client'
import { QueryKeys } from './query-keys'
import { RunbookEditResponse } from '../api/data-providers/runbook-types'

type RunbookIntegrationRequest = {
  linkableId: number
  linkableType: 'RunbookVersion'
  integrationActionItemId: number | null
  integrationOrder: number
}

export type RunbookIntegrationsResponse = {
  integrationLinks: RunbookIntegration[]
  meta: {
    headers: {
      request_method: string
      request_user_id: number
      request_user_name: string
      request_hash: string
      request_origin: string
      request_class: string
    }
    permissions: {
      [key: string]: number[]
    }
  }
}

export type RunbookIntegrationsQuery = {
  runbookId: number
  runbookVersionId: number
}

export type EditRunbookIntegrationPayload = {
  runbook: {
    field_values_attributes: FieldValuesAttributes[]
  }
}

type UserAction = 'fire' | 'refire'

export function useRunbookIntegrationsQuery(query: RunbookIntegrationsQuery) {
  const { runbookId, runbookVersionId } = query

  return useQuery<RunbookIntegrationsResponse, Error, RunbookIntegrationsResponse>(
    [QueryKeys.RunbookIntegrations, runbookId],
    async () => {
      const { data } = await apiClient.get<RunbookIntegrationsResponse>({
        url: `runbooks/${runbookId}/runbook_versions/${runbookVersionId}/integrations`,
        convertCase: true
      })

      return data ?? []
    }
  )
}

export function useRunbookIntegrationsCreate() {
  return useMutation<any, ApiError, RunbookIntegrationRequest>(
    ['runbook-integrations-create'],
    async (runbookIntegrationPayload: RunbookIntegrationRequest) => {
      const { data } = await apiClient.post<RunbookIntegrationRequest, any>({
        url: 'integration_links',
        requestProperty: 'integration_link',
        responseProperty: 'integration_link',
        data: runbookIntegrationPayload,
        convertCase: true
      })

      return data
    }
  )
}

export function useRunbookIntegrationsDestroy() {
  return useMutation<any, ApiError, any>(['runbook-integrations-destroy'], async (id: number) => {
    const { data } = await apiClient.delete({
      url: `integration_links/${id}`,
      responseProperty: 'integration_link'
    })

    const responseData = data as RunbookIntegration
    responseData.id = null
    return responseData
  })
}

export function useRunbookIntegrationsFireEndpoint() {
  return useMutation<any, ApiError, { id: number; action: UserAction }>(
    ['runbook-integrations-fire-endpoint'],
    async ({ id, action }) => {
      const { data } = await apiClient.patch({
        url: `integration_links/${id}/fire_endpoint`,
        data: { integration_link: { action: action } }
      })

      return data
    }
  )
}

export function useRunbookIntegrationsCancelEndpoint() {
  return useMutation<any, ApiError, any>(['runbook-integrations-cancel-endpoint'], async (id: number) => {
    const { data } = await apiClient.patch({
      url: `integration_links/${id}/cancel_endpoint`
    })

    return data
  })
}

export const useUpdateRunbookFieldValues = ({ runbookId }: { runbookId: string | number }) => {
  return useMutation<RunbookEditResponse, ApiError, EditRunbookIntegrationPayload>(
    ['runbook-field-values-update'],
    async payload => {
      const response = await apiClient.patch<EditRunbookIntegrationPayload, RunbookEditResponse>({
        url: `runbooks/${runbookId}`,
        data: payload
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    }
  )
}
