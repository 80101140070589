import { useState } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { useRecoilValue } from 'recoil'

import { Box, Text, themeColor } from '@cutover/react-ui'
import { incidentTimezone } from 'main/recoil/apps/apps-atoms'
import { formatActivityDetailsValue, renderMessageMarkup } from './activity-helper'
import { ActivityVM } from 'main/services/queries/use-activities'

type ActivityDetailsProps = {
  activity: ActivityVM
  index: number
  scrollToActivity: (index: number) => void
}

const DEFAULT_CHANGES_LINES: number = 1
const MIN_CHANGES_LINES: number = 2
export const DEFAULT_MESSAGE_CHARACTERS: number = 800

export const ActivityDetails = ({ activity, index, scrollToActivity }: ActivityDetailsProps) => {
  const { changes, display } = activity
  const timezone = useRecoilValue<string>(incidentTimezone)
  const [showMore, setShowMore] = useState(true)
  const isChangesMinLines = changes && changes?.length > MIN_CHANGES_LINES
  const commentIsDeleted = !!activity.display?.reason
  const changesList = showMore && isChangesMinLines ? changes?.slice(0, DEFAULT_CHANGES_LINES) : changes

  const message = showMore ? display?.message?.substring(0, DEFAULT_MESSAGE_CHARACTERS) : display?.message
  const showToggle = isChangesMinLines || (display?.message && display?.message?.length > DEFAULT_MESSAGE_CHARACTERS)

  const renderColumn = (columnValue: string, textWeight: number, columnWidth?: string, truncate: boolean = true) => {
    const formattedValue = formatActivityDetailsValue(columnValue, timezone)

    return (
      <Box alignSelf="start" width={columnWidth} direction="column" gap="xsmall" pad="xxsmall">
        <Text truncate={truncate ? 'tip' : undefined} color="text-light" weight={textWeight} size="15px">
          {parse(formattedValue)}
        </Text>
      </Box>
    )
  }

  return (
    <ActivityDetailsWrapper flex={false} align="start" justify="start" featured={activity.featured}>
      {message && (
        <CommentWrapper>
          <Text
            color={commentIsDeleted ? 'text-disabled' : 'text-light'}
            weight={400}
            size="15px"
            css={`
              white-space: pre-wrap;
              margin-top: 10px;
              font-style: ${commentIsDeleted ? 'italic' : ''};
            `}
          >
            {commentIsDeleted
              ? `Comment deleted (Reason: ${activity.display?.reason})`
              : parse(renderMessageMarkup(message) as string)}
          </Text>
        </CommentWrapper>
      )}
      {changesList?.map((param, key) => (
        <ChangesListWrapper
          alignContent="start"
          key={key}
          direction="row"
          data-testid={`changes-list-activity-${activity.id}`}
          gap="small"
        >
          {param.field && renderColumn(param.field, 600, '150px')}
          {param.value && renderColumn(param.value, 400, 'large', false)}
        </ChangesListWrapper>
      ))}
      {showToggle && (
        <Box
          gap="xsmall"
          onClick={() => {
            setShowMore(!showMore)
            scrollToActivity(index)
          }}
          pad={{ bottom: 'xxsmall' }}
        >
          <Text color="primary" weight={400} size="15px">
            {showMore ? '+ Show more' : '- Show less'}
          </Text>
        </Box>
      )}
    </ActivityDetailsWrapper>
  )
}

const ActivityDetailsWrapper = styled(Box)<{ featured: boolean }>`
  border-left: solid 2px ${props => (props.featured ? themeColor('star') : themeColor('bg-2'))};
  margin-left: 57px;
  padding-left: 20px;
  padding-right: 22px;
`
const CommentWrapper = styled(Box)`
  min-height: 34px;
  padding-left: 4px;
`

const ChangesListWrapper = styled(Box)`
  p {
    margin: 0;
  }
`
