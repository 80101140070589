// @ts-nocheck
import { action, observable } from 'mobx'
import { GenericListRepository } from '../GenericListRepository'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { IIncidentSeverityPM } from 'Shared/Entities/Types/Incident/IIncidentSeverityPM'
import { injectable } from 'inversify'

export interface IIncidentSeverityDTO {
  id: number
  color: string
  key: string
  label: string
  archived: boolean
}

@injectable()
export class IncidentSeveritiesRepository extends GenericListRepository {
  protected url = 'incident_severities'

  @observable
  public incidentSeverities: IIncidentSeverityPM[] = []

  @observable
  public useTimeCache = true

  @observable
  public incidentSeveritiesTotal: number

  constructor() {
    super('meta')
  }

  @action
  public postLoad = (rawData: IBaseResponse) => {
    if (rawData.success) {
      this.incidentSeveritiesTotal = rawData.body.incident_severities.length
      this.incidentSeverities = this.getIncidentsFromAPIData(rawData)
    } else {
      this.incidentSeveritiesTotal = 0
      this.incidentSeverities = []
    }
  }

  private getIncidentsFromAPIData = (rawData: IBaseResponse): IIncidentSeverityPM[] => {
    return rawData.body.incident_severities.map((dto: IIncidentSeverityDTO) => {
      return {
        id: dto.id,
        label: dto.label,
        key: dto.key,
        color: dto.color,
        archived: dto.archived
      }
    })
  }

  @action
  public clear = () => {
    this.incidentSeverities = []
  }
}
