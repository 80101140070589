import { memo, useCallback, useMemo, useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { useRecoilValue } from 'recoil'
import { useWindowSize } from 'react-use'

import { Menu, NoResourceFound, useUpdateEffect } from '@cutover/react-ui'
import {
  filteredTaskListDataState,
  useCanCreateRootTask,
  useMenu,
  useRunbookVersionId,
  useSetMenuState
} from 'main/recoil/runbook'
import { TaskListHeader } from './task-list-header'
import { useRootTaskCreateForm } from './task-item/task-item-create'
import { useTaskListHotkeys } from './use-task-list-hotkeys'
import { TaskListItem } from './task-item/task-list-item'
import { TaskListModals } from './task-list-modals'
import { useClearAllFilterState } from 'main/recoil/shared/filters'
import { useFilterPanelOpenState, useRightPanelLayoutOpenState } from 'main/components/layout/layout-hooks'
import { useRightPanelTypeValue } from 'main/components/layout/right-panel'

const TASK_LIST_HEADERS_HEIGHT = 200

// TODO: pull this out for suspense to not render the component content until tasks exist
export const TaskList = () => {
  useTaskListHotkeys()

  const runbookVersionId = useRunbookVersionId()
  const clearAllFilters = useClearAllFilterState()
  const [taskIds, context] = useRecoilValue(filteredTaskListDataState)
  const { criticalIds, floatLookup } = context
  const canCreateRootTask = useCanCreateRootTask()
  const rootTaskCreateForm = useRootTaskCreateForm({ taskCount: taskIds.length })

  return (
    <>
      {!canCreateRootTask && taskIds.length === 0 ? (
        <NoResourceFound context="task" clearAllFilters={clearAllFilters} />
      ) : (
        <>
          <TaskListHeader canCreateRootTask={canCreateRootTask} />
          {rootTaskCreateForm}
          <TaskListContent key={runbookVersionId} ids={taskIds} criticalIds={criticalIds} floatLookup={floatLookup} />
        </>
      )}
      <TaskListModals />
    </>
  )
}

export const TaskListContent = memo(
  ({
    ids,
    criticalIds,
    floatLookup
  }: {
    ids: number[]
    criticalIds?: number[]
    floatLookup?: Record<number, number>
  }) => {
    const { height } = useWindowSize()
    const scrollSize = height - TASK_LIST_HEADERS_HEIGHT
    const { taskId: editingTaskId } = useRightPanelTypeValue('task-edit')
    const { taskId: commentsViewingTaskId } = useRightPanelTypeValue('runbook-comments')

    return (
      <>
        <TaskItemMenu />
        <Virtuoso
          style={{ height: `${scrollSize}px` }}
          totalCount={ids.length}
          increaseViewportBy={scrollSize}
          itemContent={index => {
            const id = ids[index]
            const previousTaskId = ids[index - 1]
            const nextTaskId = ids[index + 1]
            return (
              <TaskListItem
                isEditing={editingTaskId === id || commentsViewingTaskId === id}
                critical={criticalIds?.includes(id)}
                float={floatLookup?.[id]}
                id={id}
                nextTaskId={nextTaskId}
                previousTaskId={previousTaskId}
              />
            )
          }}
        />
      </>
    )
  }
)

const TaskItemMenu = memo(() => {
  const defaultRef = useRef<HTMLElement>(null)
  const { isMenuOpen, menu } = useMenu()
  const { triggerRef, items, type, minWidth, maxWidth, maxHeight } = menu
  const { clearMenu, setMenuOpenState } = useSetMenuState()

  const isRightPanelOpen = useRightPanelLayoutOpenState()
  const isFilterPanelOpen = useFilterPanelOpenState()

  const menuAlignment = useMemo(() => {
    if (type === 'options') {
      return 'end'
    } else {
      return 'start'
    }
  }, [type])

  useUpdateEffect(() => {
    if (isMenuOpen) {
      clearMenu()
      setMenuOpenState(false)
    }
  }, [isRightPanelOpen, isFilterPanelOpen])

  const handleClose = useCallback((e: any) => {
    setMenuOpenState(false)
    e?.reason !== 'blur' && clearMenu()
  }, [])

  return (
    <Menu
      isOpen={isMenuOpen}
      triggerRef={triggerRef ?? defaultRef}
      align={menuAlignment}
      items={items}
      //@ts-ignore
      onClose={handleClose}
      minWidth={minWidth}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      arrow
    />
  )
})
