import { Controller, FieldValues, useFormContext } from 'react-hook-form'

import { Box, ListItem, ListItemText, MultiSelect } from '@cutover/react-ui'
import { FieldProps } from './form-fields'
import { getSnippets, SnippetListSnippet } from 'main/services/queries/use-snippets'

type SnippetSelectProps = {
  name: string
  isSubmitting: boolean
  accountSlug: string
  label: string
  a11yTitle: string
}

export type SnippetSelectFieldProps<TFieldValues extends FieldValues> = FieldProps<SnippetSelectProps, TFieldValues>

export const SnippetSelectField = <TFieldValues extends FieldValues>({
  ...props
}: SnippetSelectFieldProps<TFieldValues>) => {
  const formContext = useFormContext<TFieldValues>()
  const { control } = formContext

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <MultiSelect<SnippetListSnippet>
            label={props.label}
            a11yTitle={props.a11yTitle}
            icon="snippet"
            valueKey="id"
            value={value}
            onChange={onChange}
            minChars={0}
            disabled={props.isSubmitting}
            loadOptions={async input => {
              const resp = await getSnippets({ accountSlug: props.accountSlug, params: { q: input } })
              return resp.snippets
            }}
            renderOption={(option, { onDeselect, highlighted }) => (
              <ListItem
                size="small"
                onClickRemove={onDeselect ? () => onDeselect(option) : undefined}
                disabled={props.isSubmitting}
                active={highlighted}
              >
                <Box tabIndex={0}>
                  <ListItemText color="text-light" tipPlacement="top">
                    {option.name}
                  </ListItemText>
                </Box>
              </ListItem>
            )}
          />
        )
      }}
    />
  )
}
