// @ts-nocheck
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { RunbookTypeFormPresenter } from './RunbookTypeFormPresenter'
import { AccountVisibility } from 'Components/Organisms'

import { TextInput, ErrorAlert, Checkbox, Select } from 'Components/Molecules'
import { container } from 'app/Config/IOC'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { Types } from 'Gateways/Service/Types'
import { px2rem, colors } from 'app/Styles/Variables'
import { FeatureFlagService } from 'Shared/Helpers/FeatureFlagService'
import { AuthenticationRepository } from 'app/Authentication/AuthenticationRepository'

const FieldWrapper = styled.div`
  margin-bottom: ${px2rem(16)};
`
const SettingsHeader = styled.label`
  font-size: ${px2rem(13)};
  line-height: ${px2rem(12)};
  color: ${colors.textLight};
  font-weight: 500;
`

interface IRunbookTypeForm {
  presenter: RunbookTypeFormPresenter
  formType?: 'add' | 'edit'
}

export const RunbookTypeFormComponent = observer<IRunbookTypeForm>(({ presenter, formType }) => {
  const { errorAlertPresenter, submitted, formIsValid } = presenter
  const language: ILanguageService = container.get(Types.ILanguageService)
  const authenticationRepository: AuthenticationRepository = container.get(AuthenticationRepository)
  const featureFlagService: FeatureFlagService = container.get(FeatureFlagService)
  const isCutoverEmployee = authenticationRepository?.user?.cutoverEmployee

  return (
    <form>
      {submitted && !formIsValid && <ErrorAlert presenter={errorAlertPresenter} formAlert />}
      <FieldWrapper>
        <TextInput presenter={presenter.namePresenter} parentIsFocused={formType === 'add'} formTopInputElement />
      </FieldWrapper>
      <FieldWrapper>
        <TextInput presenter={presenter.descriptionPresenter} />
      </FieldWrapper>
      <FieldWrapper>
        <AccountVisibility presenter={presenter.visibilityPresenter} />
      </FieldWrapper>
      <FieldWrapper>
        <Select notClearable={false} presenter={presenter.approvalFlowPresenter} maxMenuHeight={160} />
      </FieldWrapper>

      <FieldWrapper>
        {(isCutoverEmployee || formType === 'edit') && (
          <SettingsHeader>{language.get('runbookTypes:fields:additionalSettings:label')}</SettingsHeader>
        )}
        {isCutoverEmployee && <Checkbox presenter={presenter.incidentCheckboxPresenter} />}
        {isCutoverEmployee && <Checkbox presenter={presenter.dynamicCheckboxPresenter} />}
        {formType === 'edit' && <Checkbox presenter={presenter.disabledCheckboxPresenter} />}
      </FieldWrapper>
    </form>
  )
})
