const LOGGED_IN_ROUTE_MATCHER = /\/app\//gi

// Paths where the new react app is mounted
const REACT_ROUTES = {
  paths: [
    /\/app\/my-tasks/gi, // My Tasks
    /\/app\/integration_action_item\/.+/gi, // Integration page apps
    /\/app\/settings\/(instancesettings|integration_connections|integration_list|data-sources|cutover-connect|saml|role_mappings)/gi // Global settings
  ],
  // React will only be mounted here, so modals/portals etc can be safely added
  mountingOnly: [LOGGED_IN_ROUTE_MATCHER]
}

// Paths where the (authenticated) legacy react app is mounted
const REACT_ROUTES_LEGACY = {
  paths: [
    /\/app\/access/gi, // Access Management
    /\/app\/settings\/(accounts|runbooktypes|tasktypes|customfields|customfieldgroups|incidentseverities)/gi // Global settings
  ],
  mountingOnly: [
    // Runbook users page, should be removed once CFE-1212 is tackled
    /\/app\/.+\/runbooks\/.+\/.+\/users/gi,
    // Workspace, required for runbook create modals, can be removed after CFE-412 is tackled
    // @see https://cutover.atlassian.net/browse/CFE-412
    /\/app\/.+\/runbooks\/(list|timeline|table|dashboard)/gi
  ]
}

export function isMountingOnlyMatch(hash: string) {
  const pageMatches = [...REACT_ROUTES_LEGACY.paths, ...REACT_ROUTES.paths].filter(regex => hash.match(regex) !== null)
  const mountingOnlyMatches = REACT_ROUTES.mountingOnly.filter(regex => hash.match(regex) !== null)
  return mountingOnlyMatches.length > 0 && pageMatches.length === 0
}

export function isLegacyReactPage({ hash }: { hash: string }) {
  return REACT_ROUTES_LEGACY.paths.filter(regex => hash.match(regex) !== null).length > 0
}

export function isMatchLegacyReact({ hash }: { hash: string }) {
  const matches = [...REACT_ROUTES_LEGACY.paths, ...REACT_ROUTES_LEGACY.mountingOnly].filter(
    regex => hash.match(regex) !== null
  )
  return matches.length > 0
}

export function isAngularDisabled(): boolean {
  if (localStorage.getItem('disableAngular') !== null) {
    return window.location.hostname.includes('cutover') || window.location.hostname === 'localhost'
  } else {
    return false
  }
}
