export const routes = {
  toRunbook: ({
    accountSlug,
    runbookId,
    versionId,
    params = '',
    reactRunbook
  }: {
    accountSlug: string
    runbookId: number
    versionId?: number
    reactRunbook?: boolean
    params?: string
  }) =>
    `/app/${accountSlug}/runbooks/${runbookId}/${versionId ? versionId : 'current_version'}/tasks/${
      reactRunbook ? 'react-list' : 'list'
    }${params}`
}
