import { FocusEventHandler, memo, Ref, useCallback, useEffect, useRef, useState } from 'react'
import { formatDistance } from 'date-fns'
import { debounce } from 'lodash'
import { useMergeRefs } from 'use-callback-ref'
import styled from 'styled-components/macro'

import {
  Box,
  Button,
  ListItem,
  ListItemText,
  MultiSelect,
  resolveColor,
  Text,
  themeEdgeSize,
  useNotify,
  useTheme
} from '@cutover/react-ui'
import {
  SearchableCustomFieldOption,
  SearchableCustomFieldResponse
} from 'main/services/api/data-providers/user/user-channel-response-types'
import { useLanguage, useUserWebsocket } from 'main/services/hooks'
import { CustomField } from 'main/services/queries/types'
import {
  DataSourceRefreshRequestType,
  DataSourceSearchFormType,
  useRefreshDataSourceMutation,
  useSearchDataSourceMutation
} from 'main/services/queries/use-data-sources'

const DEBOUNCE_TIME_MILIS = 300
const MIN_CHARS = 1

// TODO: check ms graph handling in searchable_custom_field_display_directive.js line 219

type SearchableCustomFieldProps = {
  customField: CustomField
  value?: SearchableCustomFieldOption[]
  multiSelect?: boolean
  onChange: (value?: SearchableCustomFieldOption[]) => void
  hasError?: boolean
  inlineError?: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  inputRef?: Ref<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const SearchableCustomField = ({ value, onChange, multiSelect, ...props }: SearchableCustomFieldProps) => {
  const [selectValue, setSelectValue] = useState<SearchableCustomFieldOption[] | undefined>(value ?? [])

  useEffect(() => {
    setSelectValue(value?.filter(item => !item.destroy) ?? [])
  }, [value])

  // TODO: kept as it was for now but we shouldn't be mutating these items
  const updateItemsForDestroy = (selectedItems?: SearchableCustomFieldOption[]) => {
    const selectedItemPrimaryKeys = selectedItems?.map(item => item.primaryKey) ?? []
    const items = selectedItems ?? []

    value?.forEach(item => {
      const shouldDestroy = !selectedItemPrimaryKeys.includes(item.primaryKey) && item.id

      if (shouldDestroy) {
        item.destroy = true
        items.push(item)
      }
    })

    return items
  }

  return (
    <CustomSelect
      {...props}
      value={selectValue}
      onChange={val => {
        const nextVal = multiSelect ? val : !val?.length ? [] : [val[val.length - 1]]
        onChange?.(updateItemsForDestroy(nextVal))
      }}
      multiSelect={multiSelect}
    />
  )
}

const CustomSelect = memo(
  ({
    customField,
    value = [],
    multiSelect = false,
    onChange,
    hasError,
    inputRef: forwardedRef,
    inlineError,
    disabled,
    readOnly,
    required,
    onBlur
  }: SearchableCustomFieldProps) => {
    const { t } = useLanguage('customFields')
    const theme = useTheme()
    const localRef = useRef<HTMLInputElement>(null)
    const inputRef = useMergeRefs(forwardedRef ? [localRef, forwardedRef] : [localRef])
    const [loading, setLoading] = useState(false)
    const [canShowNoResults, setCanShowNoResults] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [refreshedAtString, setRefreshedAtString] = useState('')
    const [options, setOptions] = useState<SearchableCustomFieldOption[]>([])
    const search = useSearchDataSourceMutation()
    const refresh = useRefreshDataSourceMutation()
    const { listen } = useUserWebsocket()
    const notify = useNotify()
    const numSelectedOptions = value.length
    const showRefreshMessage = numSelectedOptions > 0 && value[0].updatedAt

    const updateData = async (query: string) => {
      const isEmpty = query?.trim()?.length === 0

      if (isEmpty) {
        setOptions([])
        return
      }

      const data: DataSourceSearchFormType = {
        customFieldId: customField.id,
        query: query,
        taskId: null
      }

      search.mutate(data, {
        onError: () => notify.error(t('edit.notification.refreshError'))
      })
    }

    const setFormattedRefreshedAt = useCallback(
      (date: Date) => {
        setRefreshedAtString(
          t('edit.searchableCustomField.dateDistance', { distance: formatDistance(date, new Date()) })
        )
      },
      [t]
    )

    const handleInputChange = debounce((input: string) => {
      if (input.length < MIN_CHARS) {
        setCanShowNoResults(false)
      } else {
        setLoading(true)
      }
      setInputValue(input)
      updateData(input)
    }, DEBOUNCE_TIME_MILIS)

    const handleRefresh = () => {
      const data: DataSourceRefreshRequestType = {
        customFieldId: customField.id,
        values: []
      }
      value.map(option => {
        if (option.id) {
          data.values.push({
            query: option.primaryKey,
            field_value_id: option.id
          })
        }
      })

      refresh.mutate(data, {
        onSuccess: () => {
          setFormattedRefreshedAt(new Date())
          notify.success(t('edit.notification.refreshSuccess'))
        },
        onError: () => notify.error(t('edit.notification.refreshError'))
      })
    }

    const unsetLoading = debounce(
      () => {
        setLoading(false)
        setCanShowNoResults(true)
      },
      DEBOUNCE_TIME_MILIS / 2,
      { leading: true }
    )

    useEffect(() => {
      const handleUserChannelUpdate = (data: SearchableCustomFieldResponse) => {
        const results = data?.results?.map(d => {
          return {
            primaryKey: d.values[customField.name],
            dataSourceValueId: d.id,
            values: d.values
          }
        })
        const searchableFieldId = data.meta.headers.searchable_field_id
        if (Number(searchableFieldId) === customField.id) {
          setOptions(results ?? [])
        } else {
          setOptions([])
        }
        unsetLoading()
      }

      setFormattedRefreshedAt(value[0]?.updatedAt ? new Date(value[0]?.updatedAt) : new Date())

      listen(data => {
        handleUserChannelUpdate(data as SearchableCustomFieldResponse)
      })
    }, [])

    return (
      <Box
        css={`
          [aria-label='close'] {
            display: none;
          }
        `}
      >
        <MultiSelect
          label={customField.display_name || customField.name}
          inlineError={inlineError}
          hasError={hasError}
          required={required}
          onInputChange={value => {
            setCanShowNoResults(false)
            handleInputChange(value)
          }}
          options={options}
          value={value}
          single={!multiSelect}
          onChange={onChange}
          onBlur={onBlur}
          resetInputOnBlur
          inputRef={inputRef}
          loading={loading}
          disabled={disabled}
          minChars={MIN_CHARS}
          emptyMessage={canShowNoResults ? t('edit.searchableCustomField.noResults') : null}
          valueKey="primaryKey"
          itemPadding="0px"
          labelItems={
            showRefreshMessage ? (
              <RefreshMessage refreshedAt={refreshedAtString} onRefresh={handleRefresh} />
            ) : value?.length ? (
              <Box />
            ) : undefined
          }
          readOnly={readOnly}
          placeholder={
            !inputValue && (value?.length ?? 0 > 0)
              ? `Start typing ${customField.display_name || customField.name}…`
              : undefined
          }
          renderOption={(opt, { selected, highlighted, renderLocation, onDeselect }) => {
            const dependentValues = (key: string, { [key]: _, ...rest }) => rest
            const itemData = Object.entries(dependentValues(customField.name ?? '', opt.values)).sort((a, b) =>
              a[0].localeCompare(b[0])
            )
            const handleRemove = onDeselect ? () => onDeselect(opt) : undefined
            const selectedListItem = renderLocation === 'above'

            return (
              <ListItem
                onClickRemove={handleRemove}
                background={selected || highlighted ? 'bg-1' : 'bg'}
                size="small"
                alignCloseButton="start"
                css={`
                  width: 100%;
                  border-radius: ${selectedListItem ? themeEdgeSize('xxsmall') : '0'};
                  margin-bottom: ${selectedListItem ? themeEdgeSize('xsmall') : '0'};
                  border-bottom: 1px ${selectedListItem ? 'solid' : 'dotted'} ${resolveColor('bg-4', theme)};
                  height: unset;
                  padding: ${selectedListItem ? '8px 4px 8px 10px' : '8px'};
                `}
              >
                <ListItemText
                  heading={`${customField.name}: ${opt.values[customField.name]}`}
                  css="white-space: normal;"
                />
                {itemData.map(([key, value]: [key: string, value: string]) => (
                  <Box key={key} direction="column" css="padding: 4px 0;">
                    <OptionText weight="bold" size="small">
                      {key}
                    </OptionText>
                    <OptionText size="small">{value}</OptionText>
                  </Box>
                ))}
              </ListItem>
            )
          }}
        />
      </Box>
    )
  }
)

const OptionText = styled(Text)`
  white-space: normal;
  word-break: break-word;
  overflow: visible !important;
`

type RefreshMessageProps = {
  refreshedAt: string
  onRefresh: () => void
}

const RefreshMessage = ({ refreshedAt, onRefresh }: RefreshMessageProps) => {
  return (
    <Box direction="row" align="center" gap="xxsmall">
      <Text color="text-light" size="small">
        {refreshedAt}.{' '}
      </Text>
      <Button tertiary size="small" onClick={onRefresh} label="Refresh" />
    </Box>
  )
}
